import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import { getUserCompanies } from "./http/HttpClientHelper";
import { formatCompanyName } from "../../../../config/formatUtils";

const NavCompanies = ({ userDetail, isMobile }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const companies = await getUserCompanies(userDetail?.id);
      companies?.sort((a, b) => a.companyName.localeCompare(b.companyName));

      const companyDataDetails = companies.map(company => ({ company }));
      setCompanyDetails(companyDataDetails);

      setCompanyDetails(companyDataDetails);
      
    };

    fetchData().finally(setLoading(false));
  }, [userDetail]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderCompanyDetails = () => {
    if (isMobile) {
      return (
        <div className="accordion" id="companyAccordion">
          {companyDetails.map((detail, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={activeIndex === index}
                  aria-controls={`collapse${index}`}
                >
                  {formatCompanyName(detail.company.companyName)}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-bs-parent="#companyAccordion"
              >
                <div className="accordion-body">
                  <LegalPersonInfoHeader
                    legalPersonId={detail.company.legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Company}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {companyDetails.map((detail, index) => (
            <div key={index}>
              <LegalPersonInfoHeader
                legalPersonId={detail.company.legalPersonId}
                userDetail={userDetail}
                legalPersonType={LegalPersonTypeEnum.Company}
                isMobile={isMobile}
              />
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <div className="container" id="main">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {renderCompanyDetails()}
      {companyDetails.length === 0 && !loading && (
        <div className='info-text'>
          <span dangerouslySetInnerHTML={{ __html: t('CompanyGeneralData.noCompany') }}></span>
        </div>
      )}
    </div>
  );
};

export default NavCompanies;
