import { useTranslation } from "react-i18next";

const QualificationTableCell = ({ item, isMobile }) => {
    const { t } = useTranslation();

    return (
        <>
            {isMobile ? (
                <div className="pill-badge-primary">
                {
                    item.itaaQualifications && item.itaaQualifications.length > 0
                        ? item.itaaQualifications
                            .map(qual => t(`qualifications.${qual.name}`))
                            .join(' | ')
                        : '-'
                }
                </div>
            ) : (
                <td>
                    <div>
                    {
                        item.itaaQualifications && item.itaaQualifications.length > 0
                            ? item.itaaQualifications
                                .map(qual => t(`qualifications.${qual.name}`))
                                .join(' | ')
                            : '-'
                    }
                    </div>
                </td>
            )}
        </>
    );
};

export default QualificationTableCell;