import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { formatDateOfBirth, formatItaaNumber, formatShortDateAsLocalDate } from "../../../../config/formatUtils";
import NaturalPersonInfoHeader from "../../NaturalPersonInfoHeader/NaturalPersonInfoHeader";
import { useState, useEffect } from "react";
import axiosClient from "../../../../axiosClient";
import { OfficialType } from "../../../../Enums/OfficialType.Enum";

const ItaaData = () => {
    const { t } = useTranslation();
    const outletContext = useOutletContext();
    const [userDetail, setUserDetail] = useState(outletContext.userDetail);
    const [isIntern, setIsIntern] = useState(true)

    useEffect(() => {
        if (userDetail?.officialTypes?.some(type => type.name?.toLowerCase().includes('intern'))) {
            setIsIntern(true);
        } else {
            setIsIntern(false);
        }

    }, []);

    return (
        <div id="main">
            <NaturalPersonInfoHeader userDetail={userDetail} />
            <div className="form-row row">
                <div className="form-col col-md-12">
                    <div className="form-group">
                        <span dangerouslySetInnerHTML={{ __html: t('itaadata.no_edit_text') }}></span>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="form-col col-xl-6 col-lg-6 col-md-6 col-xs-12">
                    <div className="form-group">
                        <div className="text-h1 nomargin">{t("itaadata.title_general")}</div>
                        <p className="text-sub">{t("itaadata.salutation")}</p>
                        <p>{t(`itaadata.salutations.${userDetail?.gender ?? "-"}`)}</p>
                        <p className="text-sub">{t("itaadata.firstName")}</p>
                        <p>{userDetail?.firstName ?? "-"}</p>
                        <p className="text-sub">{t("itaadata.lastName")}</p>
                        <p>{userDetail?.lastName ?? "-"}</p>
                        <p className="text-sub">{t("itaadata.gender")}</p>
                        <p>{t(`itaadata.genders.${userDetail?.gender ?? "-"}`)}</p>
                        <p className="text-sub">{t("itaadata.itaaNumber")}</p>
                        <p>{formatItaaNumber(userDetail?.itaaNumber) ?? "-"}</p>
                        <p className="text-sub">{t("itaadata.language")}</p>
                        <p>{t(`itaadata.languages.${userDetail?.language ?? "-"}`)}</p>
                        <p className="text-sub">{t("itaadata.bithDate")}</p>
                        <p>{formatDateOfBirth(userDetail?.dateOfBirth)}</p>

                        <p className="text-sub">{t("itaadata.birthPlace")}</p>
                        <p>{userDetail?.birthPlace ?? "-"}</p>
                        <p className="text-sub">{t("itaadata.nationality")}</p>
                        <p>
                            {userDetail?.nationality ? t(`itaadata.nationalities.${userDetail.nationality}`).toUpperCase() : "-"}
                        </p>
                        <p className="text-sub">{t("itaadata.type")}</p>
                        <p>{userDetail?.officialTypes && userDetail.officialTypes.length > 0 ? (
                            userDetail.officialTypes
                                .map(type => t(`trackphasetypes.${type.name}`))
                                .join(' | ')
                        ) : (
                            '-'
                        )}</p>
                        <p className="text-sub">
                            {t("itaadata.nationalRegistrationNumber")}
                        </p>
                        <p>{userDetail?.nationalRegistrationNumber ?? "-"}</p>
                        <p className="text-sub">{t("itaadata.taxNumber")}</p>
                        <p>{userDetail?.vatNumber ?? "-"} </p>
                    </div>
                </div>
                <div className="form-col col-xl-6 col-lg-6 col-md-6 col-xs-12">


                    {(userDetail?.qualifications?.length > 0 || userDetail?.tracks?.length > 0) && (
                        <div className="form-group">
                            {userDetail?.qualifications?.map((qual, index) => (
                                <div key={`qual-${index}`} className="mb-4">
                                    <p className="text-h1 nomargin">{t(`qualifications.${qual?.qualification.name}`)}</p>
                                    <p className="text-sub">{t("itaadata.since")}</p>
                                    <p>{formatShortDateAsLocalDate(qual?.qualificationAchivedOn)}</p>
                                </div>
                            ))}

                            {userDetail.officialTypes.some(type => 
                                [OfficialType.CandidateIntern].includes(type.id)
                            ) && (
                                <p className="text-h1 nomargin">
                                    {t(`qualifications.${userDetail.officialTypes.find(type => 
                                        type.id === OfficialType.CandidateIntern)?.name}`)}
                                </p>
                            )}
                            {userDetail?.tracks?.map((track, index) => (
                                <div key={`track-${index}`} className="mb-4">
                                    {track.inaugurationDate && (
                                        <>
                                            <p className="text-sub">{t("itaadata.inaugurationDate")}</p>
                                            <p>{formatShortDateAsLocalDate(track.inaugurationDate)}</p>
                                        </>
                                    )}
                                    {isIntern && track.currentPhase?.currentSituation?.startDate && (
                                        <>
                                        

                                            <p className="text-sub">{t("itaadata.startDate")}</p>
                                            <p>{formatShortDateAsLocalDate(track.currentPhase?.currentSituation?.startDate)}</p>
                                        </>
                                    )}
                                </div>
                            ))}



                        </div>
                    )}
                    
                </div>
            </div>
        </div>
    );
};

export default ItaaData;