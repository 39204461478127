import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getOffice } from "../../Company/BasicData/http/HttpClientHelper";
import {
  getUserCompany,
  getCompaniesOwnClientele,
} from "./http/HttpClientHelper";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";

const NavCompaniesDetail = ({ userDetail }) => {
  const [userCompany, setUserCompany] = useState(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { legalPersonId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const company = await getUserCompany(userDetail.id, legalPersonId);
      const clienteleInfo = await getCompaniesOwnClientele(company.legalPersonId);
      const office = await getOffice(company.legalPersonId);

      company.isManagementCompany = clienteleInfo.isManagementCompany;

      setUserCompany({
        ...company,
        office,
      });
      setLoading(false);
    };

    fetchData();
  }, [legalPersonId, userDetail]);

  const companiesData = [
    {
      url: "/eloket/company/basicdata/contact/",
      title: t("sidebar.basicdata"),
      text: t("basicdata.text"),
    },
    {
      url: "/eloket/company/itaadata/general/",
      title: t("sidebar.dataAtItaa"),
      text: t("itaadata.textCompany"),
    },
    {
      url: "/eloket/company/itaadata/directorAndPartner/",
      title: t("sidebar.directorAndPartner"),
      text: t("directorAndPartner.text"),
    },
    {
      url: "/eloket/company/itaadata/network/",
      title: t("sidebar.network"),
      text: t("network.subtitle"),
    },
  ];

  const managementCompanyData = [
    {
      url: "/eloket/company/itaadata/mainContact/",
      title: t("navCompaniesDetail.mainContact"),
      text: t("navCompaniesDetail.mainContactDescription"),
    },
    {
      url: "/eloket/company/itaadata/coadministrator/",
      title: t("navCompaniesDetail.coadministrator"),
      text: t("navCompaniesDetail.coadministratorDescription"),
    },
  ];

  if (loading || !userCompany) {
    return (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="container" id="main">
      <LegalPersonInfoHeader
        legalPersonId={userCompany.legalPersonId}
        userDetail={userDetail}
        legalPersonType={LegalPersonTypeEnum.Company}
      />

      <div>
        <Row className="row-m-1">
          {userCompany.isManagementCompany &&
            managementCompanyData.map((data, index) => (
              <Col key={index} md={4}>
                <Link to={`${data.url}${userCompany.legalPersonId}`}>
                  <Card lang={i18n.language} className="h-100 custom-card">
                    <Card.Body>
                      <Card.Title className="card-title">
                        {data.title}
                      </Card.Title>
                      <Card.Text>{data.text}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          {companiesData.map((data, index) => (
            <Col key={index} md={4}>
              <Link to={`${data.url}${userCompany.legalPersonId}`}>
                <Card lang={i18n.language} className="h-100 custom-card">
                  <Card.Body>
                    <Card.Title className="card-title">{data.title}</Card.Title>
                    <Card.Text>{data.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default NavCompaniesDetail;
