export default {
  nl: {
    LegalPerson: "Rechtspersoon",
    NaturalPerson: "Natuurlijk persoon",
  },
  fr: {
    LegalPerson: "Personne morale",
    NaturalPerson: "Personne physique",
  },
};
