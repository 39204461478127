import React, { useState, useEffect } from "react";
import { getMyDetails, patchMyDetails } from "./http/HttpClientHelper";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import InsuranceFormList from "./InsuranceFormList";
import showToastMessage from "../../../Toast/ToastMessage";
import NaturalPersonInfoHeader from "../../NaturalPersonInfoHeader/NaturalPersonInfoHeader";

const Insurance = () => {
    const { userDetail } = useOutletContext();
    const { t } = useTranslation();
    const [myDetails, setMyDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const details = await getMyDetails(userDetail.id);
            const updatedDetails = {
                ...details,
                insurances: details.insurances || [],
            };
            setMyDetails(updatedDetails);
            setLoading(false);
        })();
    }, [isSuccess, userDetail]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsConfirmModalVisible(true);
    };

    const handlePatch = async () => {
        setLoading(true);
        const isValid = validateForms();
        if (!isValid) {
            showToastMessage(t("errors.fillInAllFields"));
            setLoading(false);
            return;
        }
        await patchMyDetails(userDetail.id, myDetails);
        setIsSuccess(true);

        setLoading(false);
        setIsSubmitted(false);
    };


    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await handlePatch().finally(() => {
            setLoading(false);
        });
    };

    const validateForms = () => {
        const isValid = myDetails.insurances.every(
            (insurance) => insurance.insurer && insurance.policeNumber
        );
        return isValid;
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    const handleInputChange = (e, index) => {
        setIsSubmitted(true);
        const { name, value } = e.target;
        const newMyDetails = { ...myDetails };
        newMyDetails.insurances[index] = {
            ...newMyDetails.insurances[index],
            [name]: value,
        };
        setMyDetails(newMyDetails);
    };

    return (
        <div id="main">
            {loading && (
                <>
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                </>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isSuccess} onHide={handleCloseSuccess}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("mandate_application.modal_success_title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small>{t("popup.successUpdate")}</small>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button
                        variant="secondary"
                        className="btn-eloket"
                        onClick={handleCloseSuccess}
                    >
                        {t("mandate_application.modal_success_close")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="form-grid">
                <div className="form-row row">
                    <div className="col-md-12 col-xs-12">
                        <NaturalPersonInfoHeader userDetail={userDetail} />
                        <div className="text-h1">{t('insurance.title')} </div>
                        <div className="form-group">
                            <form
                                action="#"
                                className="form-overview form-detect-unsaved"
                                id="form"
                                method="post"
                                onSubmit={handleSubmit}
                            >

                                {myDetails.hasActiveMembership === false ? (
                                    <div className='alert alert-danger mb-0 mt-2'>
                                        <span> {t('insurance.membershipWarning')}</span>
                                    </div>
                                ) : (
                                    <InsuranceFormList
                                        insurances={myDetails.insurances || []}
                                        handleInputChange={handleInputChange}
                                        hasActiveMembership={myDetails.hasActiveMembership}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <footer>
                    <span>
                        <div className="button-save-warning-container">
                            <button
                                className="btn btn-eloket"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={!isSubmitted}
                            >
                                <i className="fa fa-check mr-2"></i>
                                {t("insurance.save")}
                            </button>
                            {isSubmitted && <div className="save-warning"> {t("insurance.notSaved")} </div>}
                        </div>
                    </span>
                </footer>
            </div>
        </div>
    );
};

export default Insurance;
