import axiosClient from "./../../../../axiosClient";

export const getUserCompanies = async (userId) => {
  try {
    const response = await axiosClient.get(`/api/Profiles/${userId}/companies`);
    for (let company of response.data) {
        const clienteleInfo = await getCompaniesOwnClientele(company.legalPersonId);
        company.isManagementCompany = clienteleInfo.isManagementCompany;
    }
    localStorage.setItem("companies", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    localStorage.setItem("companies", "[]");
    throw new Error(`HTTP error! ${error}`);
  }
};

export const getUserOffices = async (userId) => {
  try {
    const response = await axiosClient.get(`/api/Profiles/${userId}/offices`);
    localStorage.setItem("offices", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    localStorage.setItem("offices", "[]");
    throw new Error(`HTTP error! ${error}`);
  }
};

export const getCompaniesOwnClientele = (companyId) => {
  return axiosClient
    .get(`/api/companies/${companyId}/ownclientele`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const getUserMissions = async (userId) => {
  try {
    const response = await axiosClient.get(`/api/Profiles/${userId}/missions`);
    return response.data;
  } catch (error) {
    throw new Error(`HTTP error! ${error}`);
  }
};

export const putUserVisibilities = async (userId) => {
  try {
    const response = await axiosClient.put(`/api/Users/${userId}/visibilities`);
    return response.data;
  } catch (error) {
    throw new Error(`HTTP error! ${error}`);
  }
};
