import axiosClient from "../../../axiosClient";

export const searchNaturalPersonData = (search) => {
  return axiosClient
    .get(`/api/certificates/naturalperson/search/${search}`)
    .then((response) => {
      return response.data;
    });
};

export const searchLegalPersonData = (search) => {
  return axiosClient
    .get(`/api/certificates/legalperson/search/${search}`)
    .then((response) => {
      return response.data;
    });
};

export const getUserDetails = async (naturalPersonId) => {
  return axiosClient
    .get(`/api/Profiles/${naturalPersonId}/itaadetails`)
    .then((response) => {
      return response.data;
    });
}

export const getNaturalPersonCertificateData = (naturalPersonId, trackId) => {
  return axiosClient
    .get(`/api/profiles/${naturalPersonId}/certificate/${trackId}`)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const getLegalPersonCertificateData = (legalPersonId) => {
  return axiosClient
    .get(`/api/profiles/${legalPersonId}/legalpersoncertificate`)
    .then((response) => {
      return response.data;
    });
};