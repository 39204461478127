class NewUserToolAccess {
    constructor({
        legalPersonId,
        selectedToolIds,
        firstName,
        lastName,
        email,
        gender,
        language,
        nationality,
        dateOfBirth,
        nationalNumber,
    }) {
        this.legalPersonId = legalPersonId;
        this.selectedToolIds = selectedToolIds;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.gender = gender;
        this.language = language;
        this.nationality = nationality;
        this.dateOfBirth = dateOfBirth;
        this.nationalNumber = nationalNumber;
    }
}

export default NewUserToolAccess;