export default {
    nl: {
        title: "Mijn kantoorrollen binnen ITAA",
        myOfficeRoles: "Mijn kantoorrollen",
        notfound: "U heeft geen kantoorrollen om weer te geven.",
    },
    fr: {
        title: "Mes rôles au sein du cabinet au sein de l'ITAA",
        myOfficeRoles:"Mes rôles au bureau",
        notfound: "Vous n'avez aucun rôle au sein du cabinet à afficher.",
    },
};