import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getMyDetails, patchMyDetails } from './http/HttpClientHelper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Clientele = () => {
    const { t } = useTranslation();
    const [myDetails, setMyDetails] = useState([]);
    const location = useLocation();
    var pathParts = location.pathname.split('/');
    const legalPersonId = pathParts[pathParts.length - 1];
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            const clienteleDetail = await getMyDetails(legalPersonId).finally(() => {
                setLoading(false);
            });
            setMyDetails(clienteleDetail);
        })();
    }, [legalPersonId]);

    const handleCheckboxChange = (value) => {
        setMyDetails(prevDetails => ({ ...prevDetails, isManagementCompany: value }));
    };
    const handleCloseSuccess = () => {
        setIsSuccess(false); // Sluit de modal
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        const legalPersonId = pathParts[pathParts.length - 1];
        await patchMyDetails(legalPersonId, myDetails).finally(() => {
            setLoading(false);
        });;
        setIsSuccess(true);
    };

    return (

        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <form action="#" className="form-overview form-detect-unsaved" id="form" method="post">
                    <div className="text-h1">
                        {t('clientele.title')}
                    </div>
                    <div className="form-group">
                        {t('clientele.text1')}
                        <br />
                        <i>{t('clientele.text2')}</i>
                        <div className="radio">
                            <input
                                id="IsManagementCompanyYes"
                                name="IsManagementCompany"
                                type="radio"
                                value="True"
                                checked={myDetails?.isManagementCompany === true}
                                onChange={() => handleCheckboxChange(true)}
                            />
                            <label htmlFor="IsManagementCompanyYes">{t('clientele.yes')}</label>
                        </div>
                        <div className="radio">
                            <input
                                id="IsManagementCompanyNo"
                                name="IsManagementCompany"
                                type="radio"
                                value="False"
                                checked={myDetails?.isManagementCompany === false}
                                onChange={() => handleCheckboxChange(false)}
                            />
                            <label htmlFor="IsManagementCompanyNo">{t('clientele.no')}</label>
                        </div>

                        <div className="form-button-row">
                            <Button className="btn-eloket" variant="secondary" onClick={handleSubmit}>
                                {t('clientele.confirm')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Clientele;