export default {
  nl: {
    title: "Antiwitwaswetgeving",
    title_information:
      "Het is voor alle kantoren verplicht om de functies van verantwoordelijke persoon op het hoogste niveau (VPHN) en AMLCO aan te stellen. Elk kantoor zal een verantwoordelijke op het hoogste niveau en een (of meer) AMLCO's moeten aanduiden. "+
      "In het geval dat een kantoor minder dan 10 beroepsbeoefenaars telt, kunnen de functies van verantwoordelijke op het hoogste niveau en van AMLCO door dezelfde persoon worden uitgevoerd. "+
      "In het geval dat een kantoor 10 of meer beroepsbeoefenaars telt, moeten de functies van de verantwoordelijke op het hoogste niveau en van de AMLCO door twee afzonderlijke personen worden uitgevoerd. "+
      "Let op: u mag in geen geval een stagiair aanduiden als VPHN of AMLCO.",
    size_office: "Grootte van uw kantoor",
    size_office_information:
      "Gelieve de volgende vragen te beantwoorden om de namen van de als VPHN en AMLCO aangewezen personen in te vullen.",
    option_one_explanation:
      "Telt uw kantoor 10 of meer beroepsbeoefenaars die een activiteit uitoefenen in het kantoor en/of een deelname hebben en/of lid zijn van het wettelijk bestuursorgaan? Gelieve op bevestigen te klikken.",
    yes: "Ja",
    no: "Neen",
    option_two_explanation:
      "Worden de functies van AMLCO en VPHN uitgeoefend door dezelfde persoon? VPHN = een lid van het wettelijk bestuursorgaan of, in voorkomend geval, van de effectieve leiding van het kantoor. Gelieve op bevestigen te klikken.",
    header_name: "Naam",
    header_qualification: "ITAA Hoedanigheid",
    header_type: "Officieel Type",
    header_function: "AWW-Functie",
    text: "Aanstelling Verantwoordelijke Persoon op het Hoogste Niveau (VPHN) en Anti-Money Laundering Compliance Officer (AMLCO).",
    save_button: "Bevestigen",
    modal_title: "Voeg toe",
    modal_input_description: "Naam persoon",
    modal_cancel: "Annuleren",
    modal_success_title: "Succes",
    modal_success_explanation: "Deze persoon is toegevoegd",
    modal_success_close: "Sluiten",
    button_delete: "Verwijderen",
    modal_role_description: "Functie",
    add: "Toevoegen",
    AML_VPHN: 'VPHN',
    AML_AMLCO: 'AMLCO',
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen...",
    select_function: "Selecteer een functie",
    select_employee: 'De AMLCO kan om het even welke gekwalificeerde persoon zijn met uitzondering van een stagiair.',
    new_employee:
      "U kan enkel de personen selecteren die gekend zijn in de databank van het ITAA. Indien de persoon niet in de lijst staat, " +
      "kan u een aanvraag doen via <b><a href='#' id='add-employee-link'>deze link</a></b>. Pas dan kan u de persoon selecteren. " +
      "Voor uw bijkomende vragen kan u steeds bij de service desk terecht: <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
    new_employee_link: "<a href='#' id='add-employee-link'>deze link</a>",
    new_employee_link_text: 'deze link',
    error_person_role_assignment:"Er is al iemand met deze rol, gelieve de vorige persoon met dezelfde rol eerst te verwijderen en dan pas iemand nieuw toe te kennen"
  },
  fr: {
    title: "Législation anti-blanchiment",
    title_information:
      "Il est obligatoire pour tous les cabinets de nommer les fonctions de personne responsable au plus haut niveau (PRHN) et d'AMLCO. Chaque cabinet devra désigner un responsable au plus haut niveau et un (ou plusieurs) AMLCO. "+
      "Dans le cas où un cabinet compte moins de 10 professionnels, les fonctions de responsable au plus haut niveau et d'AMLCO peuvent être exercées par la même personne."+
      "Dans le cas où un cabinet compte 10 professionnels ou plus, les fonctions de responsable au plus haut niveau et d'AMLCO doivent être exercées par deux personnes distinctes. "+
      "Attention : vous ne pouvez en aucun cas désigner un stagiaire comme PRHN ou AMLCO.",
    size_office: "Taille de votre cabinet",
    size_office_information:
      "Veuillez répondre aux questions suivantes pour compléter les noms des personnes désignées comme PRHN et AMLCO.",
    option_one_explanation:
      "Votre cabinet compte-t-il 10 professionnels ou plus qui exercent une activité dans le cabinet et/ou ont une participation et/ou sont membres de l'organe de gestion légal ? Veuillez cliquer sur confirmer.",
    yes: "Oui",
    no: "Non",
    option_two_explanation:
      "Les fonctions d'AMLCO et de PRHN sont-elles exercées par la même personne? PRHN = un membre de l’organe légal d'administration ou, le cas échéant, de la direction effective du cabinet. Veuillez cliquer sur confirmer.",
    header_name: "Nom",
    header_qualification: "Qualité ITAA",
    header_type: "Type officiel",
    header_function: "Fonction LAB",
    text: "Désignation personne responsable au plus haut niveau (PRHN) et anti-money laundering compliance officer (AMLCO).",
    save_button: "Confirmer",
    modal_success_close: "Fermer",
    modal_success_title: "Succès",
    modal_cancel: "Annuler",
    modal_input_description: "Nom de la personne",
    modal_role_description: "Fonction",
    modal_success_explanation: "Cette personne a été ajouté",
    modal_title: "Ajouter",
    button_delete: "Supprimer",
    modal_role_description: "Fonction",
    add: "Ajouter",
    AML_VPHN: 'PRHN',
    AML_AMLCO: 'AMLCO',
    notSaved: "Vos modifications n’ont pas encore été enregistrées...",
    select_function: "Sélectionnez une fonction",
    select_employee: 'L’AMLCO peut être toute personne qualifiée à l’exception d’un stagiaire.',
    new_employee:
      "Vous ne pouvez sélectionner que des personnes figurant dans la banque de données de l'ITAA. Si la personne n’apparaît pas dans la liste, " +
      "vous pouvez faire une demande d’accès via <b><a href='#' id='add-employee-link'>ce lien</a></b>. Seulement à ce moment-là vous pourrez sélectionner la personne. " +
      "Pour toute question supplémentaire, vous pouvez contacter le service desk : <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
      new_employee_link: "<a href='#' id='add-employee-link'>ce lien</a>",
      new_employee_link_text: 'ce lien',
      error_person_role_assignment: "Une personne occupe déjà ce rôle. Veuillez d'abord supprimer la personne précédente avec le même rôle avant d'en attribuer un nouveau."

  },
};
