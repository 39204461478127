import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getCompanyDetail } from "./http/HttpClientHelper";
import { formatDateTimeAsLocalDateTime, formatItaaNumber, formatShortDateAsLocalDate } from "../../../../config/formatUtils";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import { EntityType } from "../../../../Enums/EntityType";
const DataAtItaa = ({ userDetail }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const legalPersonId = pathParts[pathParts.length - 1];

  const [loading, setLoading] = useState(true);
  const [myCompanyDetail, setMyCompanyDetail] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [companyDetail] =
        await Promise.all([
          getCompanyDetail(legalPersonId),
        ]).finally(() => {
          setLoading(false);
        });
      setMyCompanyDetail(companyDetail);
    };

    fetchData();
  }, [legalPersonId]);


  return (
    <div id="main">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div id="content">
        <LegalPersonInfoHeader
          legalPersonId={legalPersonId}
          userDetail={userDetail}
          legalPersonType={LegalPersonTypeEnum.Company}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <span dangerouslySetInnerHTML={{ __html: t('itaadata.no_edit_text') }}></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <div className="text-h1 nomargin">{t("sidebar.general")}</div>
              <p className="text-sub">{t("companyitaadata.itaaNumber")}</p>
              {myCompanyDetail?.entityType?.id === EntityType.EPP ? (
                <p>{formatItaaNumber(userDetail.itaaNumber)}</p>
              ) : (
                <p>{formatItaaNumber(myCompanyDetail?.itaaNumber)}</p>
              )}
              <p className="text-sub">{t("companyitaadata.companyNumber")}</p>
              <p>{myCompanyDetail?.companyNumber}</p>
              <p className="text-sub">{t("companyitaadata.legalForm")}</p>
              <p>
                {myCompanyDetail?.legalForm
                  ? t(`companyitaadata.legalforms.${myCompanyDetail.legalForm}`)
                  : '-'}
              </p>
              <p className="text-sub">{t("companyitaadata.language")}</p>
              <p>
                {myCompanyDetail?.language
                  ? t(`itaadata.languages.${myCompanyDetail?.language}`)
                  : '-'}
              </p>
              <p className="text-sub">{t("companyitaadata.dateOfIncorporation")}</p>
              <p>{formatShortDateAsLocalDate(myCompanyDetail?.incorportationDate)}</p>
              <p className="text-sub">{t("companyitaadata.totalShares")}</p>
              <p>{myCompanyDetail?.totalShares}</p>
            </div>
          </div>
          {myCompanyDetail?.qualification && (
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <div className="text-h1 nomargin">{t(`companyitaadata.qualifications.${myCompanyDetail.qualification.qualificationName}`)}</div>
                <p className="text-sub">{t(`companyitaadata.qualificationSince`)}</p>
                <p>{formatShortDateAsLocalDate(myCompanyDetail.qualification.qualificationSince)}</p>
                {myCompanyDetail.mention ? (
                  <>
                    <div className="text-h1 nomargin">{t(`qualifications.${myCompanyDetail.mention.mentionName}`)}</div>
                    <p className="text-sub">{t("companyitaadata.mentionSince")}</p>
                    <p>{formatShortDateAsLocalDate(myCompanyDetail.mention.mentionSince)}</p>
                  </>
                ) : (
                  <p>{t("companyitaadata.no_mention")}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataAtItaa;
