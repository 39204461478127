import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { FunctionsEnum } from '../../../../../Enums/FunctionsEnum';

const { Option } = components;

const CustomSelectOption = ({ children, ...props }) => {
    return (
        <Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                style={{ marginRight: 10 }}
            />
            {children}
        </Option>
    );
};

const customStyles = {
    option: (provided) => ({
        ...provided,
        backgroundColor: 'var(--option-background-color)',
        color: 'var(--option-text-color)',
    }),
};

const AccessToolSelect = ({ selectedTools, setSelectedTools }) => {
    const { t } = useTranslation();
    const [selectAll, setSelectAll] = useState(false);

    const handleToolChange = (selected) => {
        setSelectedTools(selected || []);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedTools([]);
        } else {
            setSelectedTools(FunctionsEnum.getAccessToolFunctions(t));
        }
        setSelectAll(!selectAll);
    };

    return (
        <>
            <Select 
                options={FunctionsEnum.getAccessToolFunctions(t)}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CustomSelectOption }}
                onChange={handleToolChange}
                value={selectedTools}
                placeholder={t('access_tools.placeholder_tools')}
                isClearable={false}
                styles={customStyles}
            />
            <input className='select-access-tools'
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
            />
            <label id='selectAllTools' style={{ marginLeft: 8 }}>{t('access_tools.select_all')}</label>
        </>
    );
};

export default AccessToolSelect;