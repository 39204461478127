const ExamTypeEnum = {
    ENTRANCE: 'ENTRANCE',
    INTERMEDIATE: 'INTERMEDIATE',
    INTERMEDIATE2: 'INTERMEDIATE2',
    BACKPACK: 'BACKPACK',
    FINAL: 'FINAL',
    NONCERTIFIED: 'NONCERTIFIED',
    TRANSFER: 'TRANSFER'
};

export { ExamTypeEnum };
