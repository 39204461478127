export default {
  nl: {
    login: "Aanmelden",
    logout: "Afmelden",
    impersonation: "Impersonate",
    impersonation_stop: "Stop impersonate",
    signOut: "Afmelden",
    french: "Frans",
    dutch: "Nederlands",
    search: "Zoeken",
    close: "Sluiten",
    open: "Openen",
    changePassword: "Wachtwoord wijzigen",
    backToMenu: "Terug naar menu"
  },
  fr: {
    login: "Connexion",
    logout: "Déconnexion",
    impersonation: "Usurpation d'identité",
    impersonation_stop: "Arrêter l'usurpation",
    signOut: "Se déconnecter",
    french: "Français",
    dutch: "Néerlandais",
    search: "Rechercher",
    close: "Fermer",
    open: "Ouvrir",
    changePassword: "Modifier le mot de passe",
    backToMenu: "Retour au menu"
  },
};