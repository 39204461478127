import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postPasswordReset } from './http/HttpClientHelper';
import { Button, Modal } from 'react-bootstrap';

const ResetPassword = ({ userDetail }) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await postPasswordReset(userDetail.id, password);
            setError(false);
            setShowSuccessMessage(true);
        } catch (error) {
            setError(true);
        }
    };

    const handleCloseSuccess = () => {
        setShowSuccessMessage(false);
        navigate('/');
    };

    return (
        <div className="container">
            {showSuccessMessage && (
                <Modal show={showSuccessMessage} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('data.modal_success_text')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <h1>{t('resetPassword.title')}</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <label htmlFor="email">{t('resetPassword.email')}</label>
                            <input
                                type="email"
                                id="email"
                                className="form-control disabled"
                                value={userDetail.email}
                                readOnly
                            />
                            <label htmlFor="password">{t('resetPassword.newPassword')}</label>
                            <div className="input-group">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Button
                                    variant="btn btn-primary"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <i class="fa fa-eye-slash"></i> : <i class="fa fa-eye"></i>}
                                </Button>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <span dangerouslySetInnerHTML={{ __html: t('resetPassword.requirements') }} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">{t('resetPassword.submit')}</button>
                    {error && (
                        <p style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: t('resetPassword.error') }}></p>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
