import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteNaturalPersonResults from './Autocomplete/AutocompleteNaturalPersonResults';
import AutocompleteLegalPersonResults from './Autocomplete/AutocompleteLegalPersonResults';
import { getLegalPersonCertificateData, getNaturalPersonCertificateData, getUserDetails } from './http/HttpClientHelper';
import { saveAs } from 'file-saver';

const Certificate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedNaturalPerson, setSelectedNaturalPerson] = useState(null);
    const [selectedNaturalPersonName, setSelectedNaturalPersonName] = useState('');
    const [naturalPersonTracks, setNaturalPersonTracks] = useState([]);
    const [selectedNaturalPersonTrackId, setSelectedNaturalPersonTrackId] = useState(null);
    const [selectedLegalPerson, setSelectedLegalPerson] = useState(null);
    const [selectedLegalPersonName, setSelectedLegalPersonName] = useState('');
    const [isNaturalPersonClickedResult, setIsNaturalPersonClickedResult] = useState(false)
    const [isLegalPersonClickedResult, setIsLegalPersonClickedResult] = useState(false)

    const handleNaturalPersonChange = (e) => {
        setSelectedNaturalPersonName(e.target.value);
        setIsNaturalPersonClickedResult(false)
    };

    const handleLegalPersonChange = (e) => {
        setSelectedLegalPersonName(e.target.value);
        setIsLegalPersonClickedResult(false)
    };

    const handleAutocompleteNaturalPersonSelection = async (selectedResult) => {
        setSelectedNaturalPerson(selectedResult);
        setSelectedNaturalPersonName(selectedResult.fullName);

        setSelectedNaturalPersonTrackId(null);
        const userDetails = await getUserDetails(selectedResult.naturalPersonId);
        setNaturalPersonTracks(userDetails.tracks);

        setIsNaturalPersonClickedResult(true);
    }

    const handleAutocompleteLegalPersonSelection = (selectedResult) => {
        setSelectedLegalPerson(selectedResult);
        setSelectedLegalPersonName(selectedResult.name);
        setIsLegalPersonClickedResult(true);
    }

    const handleNaturalPersonTrackClick = async (trackId) => {
        setSelectedNaturalPersonTrackId(trackId);
    }

    const handleNaturalPersonCertificateDownloadClick = async (naturalPersonId) => {
        setLoading(true);
        const data = await getNaturalPersonCertificateData(naturalPersonId, selectedNaturalPersonTrackId);
        var binaryString = window.atob(data.pdf_certificate);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        const file = new Blob([bytes], { type: "application/pdf" });
        saveAs(file, t('personaldata.certificateDownloadName'));
        setLoading(false);
    }

    const handleLegalPersonCertificateDownloadClick = async (legalPersonId) => {
        setLoading(true);
        const data = await getLegalPersonCertificateData(legalPersonId);
        var binaryString = window.atob(data.pdf_certificate);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        const file = new Blob([bytes], { type: "application/pdf" });
        saveAs(file, t('personaldata.certificateDownloadName'));
        setLoading(false);
    }

    return (
        <div className="container mt-4">
             {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <h3>{t('landingpage.generateCertificate')}</h3>
            <div className="form-group">
                <label htmlFor="naturalPerson">{t('itaatypes.NaturalPerson')}</label>
                <input
                    type="text"
                    id="naturalPerson"
                    className="form-control"
                    value={selectedNaturalPersonName}
                    onChange={handleNaturalPersonChange}
                />
                {selectedNaturalPersonName.trim().length >= 2 && !isNaturalPersonClickedResult && (
                    <AutocompleteNaturalPersonResults
                        inputValue={selectedNaturalPersonName}
                        handleSelection={handleAutocompleteNaturalPersonSelection}
                    />
                )}

                {isNaturalPersonClickedResult && (
                    <>
                        <label>{t('itaadata.selectTrack.text')}</label>
                        <ul className="list-group">
                            {naturalPersonTracks?.map((track) => (
                                <li
                                    key={track.trackId}
                                    className={`list-group-item list-group-item-action ${selectedNaturalPersonTrackId === track.trackId ? 'selected' : ''}`}
                                    onClick={() => handleNaturalPersonTrackClick(track.trackId)}
                                >
                                    {t(`qualifications.${track.trackType.name}`)}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {selectedNaturalPersonTrackId && (
                    <button className="btn btn-eloket" onClick={() => handleNaturalPersonCertificateDownloadClick(selectedNaturalPerson.naturalPersonId, selectedNaturalPersonTrackId)}>{t('personaldata.certificateDownload')}</button>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="legalPerson">{t('itaatypes.LegalPerson')}</label>
                <input
                    type="text"
                    id="legalPerson"
                    className="form-control"
                    value={selectedLegalPersonName}
                    onChange={handleLegalPersonChange}
                />
                {selectedLegalPersonName.trim().length >= 2 && !isLegalPersonClickedResult && (
                    <AutocompleteLegalPersonResults
                        inputValue={selectedLegalPersonName}
                        handleSelection={handleAutocompleteLegalPersonSelection}
                    />
                )}

                {isLegalPersonClickedResult && (
                    <button className="btn btn-eloket" onClick={() => handleLegalPersonCertificateDownloadClick(selectedLegalPerson.legalPersonId)}>{t('personaldata.certificateDownload')}</button>
                )}
            </div>
        </div>
    );
}

export default Certificate;