import { useTranslation } from 'react-i18next';

const Quality = () => {
    const { t } = useTranslation();

    return (
        <div className="container" id="main">
            <div className="row">
                <div className="col-md-4">
                    <div className="panel panel-default fixed-height">
                        <div className="panel-heading">
                            <a href={t('quality.DashboardLink')} target="_blank" rel="noopener noreferrer">{t('quality.OngoingAssessments')}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="panel panel-default fixed-height">
                        <div className="panel-heading">
                            <a href={t('quality.DashboardArchiveLink')} target="_blank" rel="noopener noreferrer">{t('quality.ArchivedAssessments')}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="panel panel-default fixed-height">
                        <div className="panel-heading">
                            <a href={t('quality.DocumentationLink')} target="_blank" rel="noopener noreferrer">{t('quality.Documentation')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default Quality;