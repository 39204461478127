export default {
  nl: {
    title: "Verzekering burgerlijke aansprakelijkheid",
    subText: "Verzekering burgerlijke aansprakelijkheid.",
    titleProfile: "Verzekering",
    insurer: "Verzekeraar",
    policyNumber: "Polisnummer",
    membershipWarning: "Verzekering burgerlijke aansprakelijkheid enkel beschikbaar voor leden.",
    save: "Opslaan",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen...",
    noInsurance:'Staat uw verzekering niet in de lijst? Neem dan contact op met het ITAA  (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>).'
  },
  fr: {
    title: "Assurance responsabilité civile professionnelle",
    subText: "Assurance responsabilité civile professionnelle.",
    titleProfile: "Assurance",
    insurer: "Assureur",
    policyNumber: "Numéro de police",
    membershipWarning: "Assurance responsabilité civile professionnelle uniquement disponible pour les membres.",
    save: "Sauvegarder",
    notSaved: "Vos modifications n’ont pas encore été enregistrées...",
    noInsurance:'Si votre assurance ne se trouve pas dans la liste, prenez contact avec l’ITAA  (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>).'
  },
};
