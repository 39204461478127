import axiosClient from "../../../../../../axiosClient.js";
import { apiConfig } from "../../../../../../config/apiConfig.jsx";

export const getNetworkDetail = (natPersonId) => {
    return axiosClient
      .get(`/api/profiles/${natPersonId}/networks`)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
  };
  

export const deleteNetworkDetail = (networkId) => {
  return axiosClient
    .delete(`/api/profiles/networks/${networkId}`)
    .then((response) => {
      if (!response.status === 200 && !response.status === 204) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const patchNetworkDetail = (legalPersonId, detailsToAdd) => {
    return axiosClient
      .post(`/api/profiles/${legalPersonId}/networks`, detailsToAdd)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
  };
  

  export const searchNetworks = (naturalPersonId, search) => {
    return axiosClient
        .get(`/api/profiles/${naturalPersonId}/networks/search?search=${search}`)
        .then(response => {
            if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
            return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
  };