import { ExamTypeEnum } from '../../../../Enums/ExamEnumTypes';
import i18n from '../../../../i18n';

const OverviewData = [
    {
        id: 1,
        title: i18n.t('landingpage.eloket.title'),
        subtitle: i18n.t('landingpage.eloket.subtitle'),
        link: i18n.t('landingpage.eloket.link'),
        key: 'isVisibleEloket',
        openInNewTab: false,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.eloket.faqLink'),
    },
    {
        id: 3,
        title: i18n.t('landingpage.generalAssembly.title'),
        subtitle: i18n.t('landingpage.generalAssembly.subtitle'),
        link: '#', // Empty
        key: 'isVisibleGeneralAssembly',
        openInNewTab: false,
        openInModal: true,
        isITAABlock: false,
    },
    {
        id: 4,
        title: i18n.t('landingpage.forms.title'),
        subtitle: i18n.t('landingpage.forms.subtitle'),
        link: i18n.t('landingpage.forms.link'),
        key: 'isVisibleForms',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.forms.faqLink'),
    },
    {
        id: 5,
        title: i18n.t('landingpage.internshipBook.title'),
        subtitle: i18n.t('landingpage.internshipBook.subtitle'),
        link: i18n.t('landingpage.internshipBook.link'),
        key: 'isVisibleInternshipBook',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.internshipBook.faqLink'),
    },
    {
        id: 6,
        title: i18n.t('landingpage.entranceExam.title'),
        subtitle: i18n.t('landingpage.entranceExam.subtitle'),
        link: i18n.t('landingpage.entranceExam.link'),
        key: 'isVisibleEntranceExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.entranceExam.faqLink'),
        examType: ExamTypeEnum.ENTRANCE,
    },
    {
        id: 7,
        title: i18n.t('landingpage.intermediateExam.title'),
        subtitle: i18n.t('landingpage.intermediateExam.subtitle'),
        link: i18n.t('landingpage.intermediateExam.link'),
        key: 'isVisibleIntermediateExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.intermediateExam.faqLink'),
        examType: ExamTypeEnum.INTERMEDIATE,
    },
    {
        id: 8,
        title: i18n.t('landingpage.intermediateExam2.title'),
        subtitle: i18n.t('landingpage.intermediateExam2.subtitle'),
        link: i18n.t('landingpage.intermediateExam2.link'),
        key: 'isVisibleIntermediateExam2',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.intermediateExam2.faqLink'),
        examType: ExamTypeEnum.INTERMEDIATE2,
    },
    {
        id: 9,
        title: i18n.t('landingpage.transferExam.title'),
        subtitle: i18n.t('landingpage.transferExam.subtitle'),
        link: i18n.t('landingpage.transferExam.link'),
        key: 'isVisibleTransferExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.transferExam.faqLink'),
        examType: ExamTypeEnum.TRANSFER,
    },
    {
        id: 10,
        title: i18n.t('landingpage.backpackExam.title'),
        subtitle: i18n.t('landingpage.backpackExam.subtitle'),
        link: i18n.t('landingpage.backpackExam.link'),
        key: 'isVisibleBackpackExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.backpackExam.faqLink'),
        examType: ExamTypeEnum.BACKPACK,
    },
    {
        id: 11,
        title: i18n.t('landingpage.finalExam.title'),
        subtitle: i18n.t('landingpage.finalExam.subtitle'),
        link: i18n.t('landingpage.finalExam.link'),
        key: 'isVisibleFinalExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.finalExam.faqLink'),
        examType: ExamTypeEnum.FINAL,
    },
    {
        id: 12,
        title: i18n.t('landingpage.nonCertifiedExam.title'),
        subtitle: i18n.t('landingpage.nonCertifiedExam.subtitle'),
        link: i18n.t('landingpage.nonCertifiedExam.link'),
        key: 'isVisibleCertifiedExam',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.nonCertifiedExam.faqLink'),
        examType: ExamTypeEnum.NONCERTIFIED,
    },
    {
        id: 13,
        title: i18n.t('landingpage.trainingProgram.title'),
        subtitle: i18n.t('landingpage.trainingProgram.subtitle'),
        link: i18n.t('landingpage.trainingProgram.link'),
        key: 'isVisibleTrainingProgram',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.trainingProgram.faqLink'),
    },
    {
        id: 14,
        title: i18n.t('landingpage.inisSystemFacturation.title'),
        subtitle: i18n.t('landingpage.inisSystemFacturation.subtitle'),
        link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
        key: 'isVisibleInisSystemFacturation',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.inisSystemFacturation.faqLink'),
    },
    {
        id: 15,
        title: i18n.t('landingpage.inisSystemEducation.title'),
        subtitle: i18n.t('landingpage.inisSystemEducation.subtitle'),
        link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
        key: 'isVisibleInisSystemEducation',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.inisSystemEducation.faqLink'),
    },
    {
        id: 16,
        title: i18n.t('landingpage.professionalDocumentation.title'),
        subtitle: i18n.t('landingpage.professionalDocumentation.subtitle'),
        link: i18n.t('landingpage.professionalDocumentation.link'),
        key: 'isVisibleProfessionalDocumentation',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
    },
    {
        id: 17,
        title: i18n.t('landingpage.qualityOverview.title'),
        subtitle: i18n.t('landingpage.qualityOverview.subtitle'),
        link: i18n.t('landingpage.qualityOverview.link'),
        key: 'isVisibleQualityOverview',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.qualityOverview.faqLink'),
    },
    {
        id: 18,
        title: i18n.t('landingpage.community.title'),
        subtitle: i18n.t('landingpage.community.subtitle'),
        link: i18n.t('landingpage.community.link'),
        key: 'isVisibleCommunity',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
    },
    {
        id: 19,
        title: i18n.t('landingpage.estox.title'),
        subtitle: i18n.t('landingpage.estox.subtitle'),
        //subtitle: i18n.t('landingpage.eloket.subtitle'),
        link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
        key: 'isVisibleEstox',
        openInNewTab: false,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.estox.faqLink'),
    },
    {
        id: 20,
        title: i18n.t('landingpage.mandateManagement.title'),
        subtitle: i18n.t('landingpage.mandateManagement.subtitle'),
        link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
        key: 'isVisibleMandateManagement',
        openInNewTab: false,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.mandateManagement.faqLink'),
    },
    {
        id: 21,
        title: i18n.t('landingpage.app4Acc.title'),
        subtitle: i18n.t('landingpage.app4Acc.subtitle'),
        link: i18n.t('landingpage.app4Acc.link'),
        key: 'isVisibleApp4Acc',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.app4Acc.faqLink'),
    },
    {
        id: 22,
        title: i18n.t('landingpage.beExcellent.title'),
        subtitle: i18n.t('landingpage.beExcellent.subtitle'),
        link: i18n.t('landingpage.beExcellent.link'), 
        key: 'isVisibleBeExcellent',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
    },
    {
        id: 23,
        title: i18n.t('landingpage.companyWeb.title'),
        subtitle: i18n.t('landingpage.companyWeb.subtitle'),
        link: i18n.t('landingpage.companyWeb.link'),
        key: 'isVisibleCompanyWeb',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.companyWeb.faqLink'),
    },
    {
        id: 24,
        title: i18n.t('landingpage.excelForAccountancy.title'),
        subtitle: i18n.t('landingpage.excelForAccountancy.subtitle'),
        link: '#',
        key: 'isVisibleExcelForAccountancy',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
        faqLink: i18n.t('landingpage.excelForAccountancy.faqLink'),
    },
    {
        id: 25,
        title: i18n.t('landingpage.serviceDesk.title'),
        subtitle: i18n.t('landingpage.serviceDesk.subtitle'),
        link: i18n.t('landingpage.serviceDesk.link'),
        key: 'isVisibleServiceDesk',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: false,
    },
    {
        id: 26,
        title: i18n.t('landingpage.estoxAdmin.title'),
        subtitle: i18n.t('landingpage.estoxAdmin.subtitle'),
        link: "#", // https://admin.estox.be/api/auth/?idp=iab&organizationName={token}
        key: 'isVisibleEstoxAdmin',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: true,
    },
    {
        id: 27,
        title: i18n.t('landingpage.crm.title'),
        subtitle: i18n.t('landingpage.crm.subtitle'),
        link: i18n.t('landingpage.crm.link'),
        key: 'isVisibleCrm',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: true,
    },
    {
        id: 28,
        title: i18n.t('landingpage.crm2.title'),
        subtitle: i18n.t('landingpage.crm2.subtitle'),
        link: i18n.t('landingpage.crm2.link'),
        key: 'isVisibleCrm',
        openInNewTab: true,
        openInModal: false,
        isITAABlock: true,
    },
    {
        id: 29,
        title: i18n.t('landingpage.certificateGeneration.title'),
        subtitle: i18n.t('landingpage.certificateGeneration.subtitle'),
        link: i18n.t('landingpage.certificaat.link'), 
        key: 'isVisibleCertificateGeneration',
        openInNewTab: false,
        openInModal: false,
        isITAABlock: true,
    },
    {
        id: 30,
        title: i18n.t('landingpage.infoMessages.title'),
        subtitle: i18n.t('landingpage.infoMessages.subtitle'),
        link: i18n.t('landingpage.infoMessages.link'), 
        key: 'isVisibleInfoMessage',
        openInNewTab: false,
        openInModal: false,
        isITAABlock: true,
    },
];

export default OverviewData;
