import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import { getQualityData, deletePersonQualityResponsible } from './http/HttpClientHelper';
import { formatShortDateAsLocalDate, formatItaaNumber } from '../../../../config/formatUtils';
import { getMainContactOrCoAdmin } from '../MainContact/http/HttpClientHelper';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import Add from './Add/Add';
import QualificationTableCell from '../../MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../MembershipInformation/OfficialTypeTableCell';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const QualityResponsible = ({ userDetail, isMobile }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [officeDetails, setOfficeDetails] = useState(null);
    const [qualityData, setQualityData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [canAddQualityResponsible, setCanAddQualityResponsible] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        const fetchOfficeDetails = async () => {
            const data = await getOffice(legalPersonId);
            setOfficeDetails(data);
        };
        fetchOfficeDetails().finally(() => {
            setLoading(false);
        });
    }, [legalPersonId]);

    useEffect(() => {
        const fetchQualityData = async () => {
            setLoading(true);
            const data = await getQualityData(legalPersonId);
            setCanAddQualityResponsible(data.length === 0);
            setQualityData(data);
            var currentUserIsMainContactOrCoAdmin = await getMainContactOrCoAdmin(legalPersonId, userDetail.id);
            setShowWarning(!currentUserIsMainContactOrCoAdmin.isMainContactOrCoAdmin);
            setLoading(false);
            setMounted(true);
        };
        fetchQualityData().finally(() => {
            setLoading(false);
        });
    }, [legalPersonId]);


    if (!officeDetails) {
        return null;
    }

    if (qualityData == null) {
        return (
            <div id="main">
                <div id="content">
                    <div className="text-h1">
                        {t('quality_responsible.title')}
                    </div>
                    <div id="content">
                        <LegalPersonInfoHeader
                            legalPersonId={legalPersonId}
                            userDetail={userDetail}
                            legalPersonType={LegalPersonTypeEnum}
                        />
                    </div>
                    <div className="form-group">
                        {showWarning && (
                            <div className='form-row'>
                                <div className='alert alert-danger mb-0'>
                                    <span>{t('office_composition.warningMainContact')}</span>
                                </div>
                            </div>
                        )}
                        <div className="form-row">
                            <h5>{t('quality_responsible.quality_assessment')}</h5>
                        </div>
                        <div className="form-row">
                            <p className="text-description">{t('quality_responsible.explanation')}</p>
                        </div>
                        <div className="form-row">
                            <h5>{t('quality_responsible.responsible_assessment')}</h5>
                        </div>
                        <div className="form-row">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('quality_responsible.header_name')}</th>
                                        <th>{t('quality_responsible.header_qualification')}</th>
                                        <th>{t('quality_responsible.header_type')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div>
                            <div className="form-row">
                                <h5>{t('quality_responsible.disclaimer')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const delteAction = async (selectedPersonId) => {

        await deletePersonQualityResponsible(legalPersonId, selectedPersonId);
        const data = await getQualityData(legalPersonId);
        setQualityData(data);
    };
    const handleDelete = async (selectedPersonId) => {
        setLoading(true);
        await delteAction(selectedPersonId).finally(() => {
            setLoading(false);
            window.location.reload();
        });
    };

    return (
        <div id="main">
            <div id="content">
                <div id="content">
                    <LegalPersonInfoHeader
                        legalPersonId={legalPersonId}
                        userDetail={userDetail}
                        legalPersonType={LegalPersonTypeEnum}
                    />
                </div>
                <div className="text-h1">
                    {t('quality_responsible.title')}
                </div>
                <div className="form-group">
                    {showWarning && (
                        <div className='form-row'>
                            <div className='alert alert-danger mb-0'>
                                <span>{t('office_composition.warningMainContact')}</span>
                            </div>
                        </div>
                    )}
                    <div className="form-row">
                        <h5>{t('quality_responsible.quality_assessment')}</h5>
                    </div>
                    <div className="form-row">
                        <p className="text-description">{t('quality_responsible.explanation')}</p>
                    </div>
                    <div className="form-row">
                        <h5>{t('quality_responsible.responsible_assessment')}</h5>
                    </div>
                    <div className="form-row">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('quality_responsible.header_name')}</th>
                                    {!isMobile && (
                                        <>
                                            <th>{t('quality_responsible.header_qualification')}</th>
                                            <th>{t('quality_responsible.header_type')}</th>
                                            <th></th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {qualityData.map((quality, index) => (
                                    <tr key={index}>
                                        <td>{quality.name}</td>
                                        {isMobile ? (
                                            <td className="badges-office-composition">
                                                <QualificationTableCell item={quality} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={quality} isMobile={isMobile} />
                                            </td>
                                        ) : (
                                            <>
                                                <QualificationTableCell item={quality} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={quality} isMobile={isMobile} />
                                            </>
                                        )}
                                        <td className='lastColumn'>
                                            <button
                                                className="btn btn-eloket"
                                                type="button"
                                                onClick={() => handleDelete(quality.naturalPersonId)}
                                            >
                                                {!isMobile ? (
                                                    <>
                                                        {t('access_tools.button_delete')}
                                                    </>
                                                ) : (
                                                    <i className='fa fa-trash'></i>
                                                )}

                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {!canAddQualityResponsible && (
                            <div className='alert alert-danger mb-0 mt-2'>
                                <span> {t('quality_responsible.add_not_available')}</span>
                            </div>
                        )}

                        <div className="form-row">
                            <h5>{t('quality_responsible.disclaimer')}</h5>
                        </div>

                        <div className="form-row">
                            {isMobile ? (
                                <a
                                    className="btn btn-eloket"
                                    href={`/assets/${t('be_excellent.confidentialityBeExcellent')}.pdf`}
                                    download
                                >
                                    {t('quality_responsible.download_pdf')}
                                </a>
                            ) : (
                                <embed
                                    className='pdf-viewer-embeded'
                                    title="PDF Viewer"
                                    src={`/assets/${t('be_excellent.confidentialityBeExcellent')}.pdf`}
                                />
                            )}

                        </div>
                    </div>
                </div>
                {canAddQualityResponsible && (
                    <footer>
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="button" onClick={() => setShowAddModal(true)}> {t('quality_responsible.add')} </button>
                        </div>
                    </footer>
                )}
            </div>
            {showAddModal && (
                <Add
                    show={showAddModal}
                    handleClose={() => setShowAddModal(false)}
                    legalPersonId={legalPersonId}
                />
            )}
        </div>
    )
};

export default QualityResponsible;
