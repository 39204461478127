export default {
  nl: {
    CandidateIntern: "Kandidaat stagiaire",
    InternalIntern: "Interne stagiaire",
    ExternalIntern: "Externe stagiaire",
    _7Year: "Jaar 7",
    _7YearArt56: "Jaar 7 (art. 56)",
    InternalMember: "Intern lid",
    ExternalMember: "Extern lid",
    HonoraryMember: "Erelid",
    Bridge: "Overstap",
    BridgeArt21: "Overstap (art. 21-22)",
    Employee: "Medewerker",
    MainContactPerson: "Hoofdcontactpersoon",
    PermanentRepresentative: "Représentant permanent",
    Manager: "Bestuurder",
    QualityResponsible: "Kwaliteitsverantwoordelijke",

      Accountant: "Extern lid",
      TaxAccountant: "Extern lid",
      CertifiedAccountant: "Extern lid",
      CertifiedTaxAdvisor: "Extern lid",
      InternalAccountant: "Intern lid",
      InternalTaxAccountant: "Intern lid",
      InternalCertifiedAccountant: "Intern lid",
      InternalCertifiedTaxAdvisor: "Intern lid",
      TraineeInternalAccountant: "Intern stagiair",
      TraineeInternalTaxAccountant: "Intern stagiair",
      TraineeInternalCertifiedAccountant: "Intern stagiair",
      TraineeInternalCertifiedTaxAdvisor: "Intern stagiair",
      TraineeAccountant: "Extern stagiair",
      TraineeTaxAccountant: "Extern stagiair",
      TraineeCertifiedAccountant: "Extern stagiair",
      TraineeCertifiedTaxAdvisor: "Extern stagiair",

          
      CandidateIntern: "Kandidaat stagiair",
      InternalIntern: "Intern stagiair",
      ExternalIntern: "Extern stagiair",
      _7Year: "7 jaar",
      _7YearArt56: "Artikel 56",
      InternalMember: "Intern lid",
      ExternalMember: "Extern lid",
      HonoraryMember: "Erelid",
      Bridge: "Overstap",
      BridgeArt21: "Overstap art21-22"

  },
  fr: {
    CandidateIntern: "Candidat stagiaire",
    InternalIntern: "Stagiaire interne",
    ExternalIntern: "Stagiaire externe",
    _7Year: "Année 7",
    _7YearArt56: "Année 7 (art. 56)",
    InternalMember: "Membre interne",
    ExternalMember: "Membre externe",
    HonoraryMember: "Membre honoraire",
    Bridge: "Passerelle",
    BridgeArt21: "Passerelle (art. 21-22)",
    Employee: "Collaborateur",
    MainContactPerson: "Personne de contact principale",
    PermanentRepresentative: "Représentant permanent",
    Manager: "Gérant",
    QualityResponsible: "Responsable qualité",

      Accountant: "Membre externe",
      TaxAccountant: "Membre externe",
      CertifiedAccountant: "Membre externe",
      CertifiedTaxAdvisor: "Membre externe",
      InternalAccountant: "Membre interne",
      InternalTaxAccountant: "Membre interne",
      InternalCertifiedAccountant: "Membre interne",
      InternalCertifiedTaxAdvisor: "Membre interne",
      TraineeInternalAccountant: "Stagiair(e) interne",
      TraineeInternalTaxAccountant: "Stagiair(e) interne",
      TraineeInternalCertifiedAccountant: "Stagiair(e) interne",
      TraineeInternalCertifiedTaxAdvisor: "Stagiair(e) interne",
      TraineeAccountant: "Stagiair(e) externe",
      TraineeTaxAccountant: "Stagiair(e) externe",
      TraineeCertifiedAccountant: "Stagiair(e) externe",
      TraineeCertifiedTaxAdvisor: "Stagiair(e) externe",

        CandidateIntern: "Candidat stagiair(e)",
        InternalIntern: "Stagiair(e) interne",
        ExternalIntern: "Stagiair(e) externe",
        _7Year: "7 ans",
        _7YearArt56: "Article 56",
        InternalMember: "Membre interne",
        ExternalMember: "Membre externe",
        HonoraryMember: "Membre honoraire",
        Bridge: "Passerelle",
  },
};
