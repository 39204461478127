import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'; // Import Bootstrap Form for Dropdowns
import { getUserImpersonations, getNaturalPersonTrackTypes, getNaturalPersonQualifications } from './http/HttpClientHelper';
import { useOutletContext, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { formatItaaNumber } from '../../../config/formatUtils';
import { useUserProvider } from '../../Auth/UserProvider';
import QualificationTableCell from '../../ELoket/MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../ELoket/MembershipInformation/OfficialTypeTableCell';

const ITEMS_PER_PAGE = 18;

const Impersonation = () => {
    const [impersonations, setImpersonations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [response, setResponse] = useState({});
    const { t } = useTranslation();
    const { searchQuery, userDetail, isMobile, setSearchQuery } = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [ismImpersonating, setIsmImpersonating] = useState(false);
    const { handleLoginFromImpersonate } = useUserProvider();
    const navigate = useNavigate();
    const [trackTypesFilter, setTrackTypesFilter] = useState([]);
    const [qualificationsFilter, setQualificationsFilter] = useState([]);
    const [selectedQualification, setSelectedQualification] = useState(null);
    const [selectedTrackType, setSelectedTrackType] = useState(null);
    const [showFilters, setShowFilters] = useState(false); 
    const [hasFilter, setHasFilter] = useState(false)

    useEffect(() => {
        if (!userDetail.isItaaEmployee) {
            navigate('/');
        }
    }, [userDetail, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            try {
                const responseData = await getUserImpersonations(
                    ITEMS_PER_PAGE,
                    currentPage,
                    searchQuery,
                    selectedQualification,
                    selectedTrackType,
                    hasFilter
                );

                if (responseData && Array.isArray(responseData.currentPage)) {
                    setImpersonations(responseData.currentPage); 
                } else {
                    setImpersonations([]);
                }
    
                setResponse(responseData); 
    
                const trackTypes = await getNaturalPersonTrackTypes();
                setTrackTypesFilter(trackTypes);
    
                const qualifications = await getNaturalPersonQualifications();

                setQualificationsFilter(qualifications);

                setHasFilter(showFilters);

            } catch (error) {
                setImpersonations([]); 
            } finally {
                setLoading(false); 
            }
        };
    
        fetchData();
    }, [currentPage, searchQuery, selectedQualification, selectedTrackType, userDetail]);
    
    

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleImpersonate = async (selectedUserId) => {
        setIsmImpersonating(true);
        await handleLoginFromImpersonate(selectedUserId, userDetail.id);
        setIsmImpersonating(false);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };
    const handleQualificationChange = (e) => {
        const selectedQualName = e.target.value; 
        const selectedQual = qualificationsFilter.find(qualification => qualification.name === selectedQualName);
        setSelectedQualification(selectedQual); 
    };
    
    const handleTrackTypeChange = (e) => {

        const selectedTrackName = e.target.value; 
        const selectedTrack = trackTypesFilter.find(track => track.name === selectedTrackName);
        setSelectedTrackType(selectedTrack);
    };

    return (
        <div id="table" className="container">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {ismImpersonating && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p>{t('estoxModal.ExternalProviderShareRes.Redirect')}</p>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='row'>
                {isMobile && (
                    <div className='search-bar'>
                        <input
                            className="form-control"
                            placeholder={t('navbar.search')}
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                )}
            </div>

            {/* Filter Toggling Button */}
            <div className="d-flex justify-content-end mb-3 filter-button-wrapper">
                <Button 
                    className="btn-eloket"
                    variant="secondary" onClick={() => setShowFilters(!showFilters)}>
                    {t('impersonation.filterButton')}
                </Button>
            </div>

            {/* Filter Section */}
            {showFilters && (
                <div className="row my-3 filter-section">
                    <div className="col-md-6 filter-item">
                        <Form.Group controlId="qualificationFilter">
                            <Form.Label>{t('impersonation.filterQualification')}</Form.Label>
                            <Form.Control 
                            as="select" 
                            value={selectedQualification ? selectedQualification.name : ''} 
                            onChange={handleQualificationChange} 
                        >
                            <option value="">{t('impersonation.select')}</option>
                            {qualificationsFilter?.map((qualification) => (
                                <option key={qualification.id} value={qualification.name}> 
                                    {t(`qualifications.${qualification.name}`)}
                                </option>
                            ))}
                        </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 filter-item">
                        <Form.Group controlId="trackTypeFilter">
                            <Form.Label>{t('impersonation.filterTrackType')}</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={selectedTrackType ? selectedTrackType.name : ''} 
                                onChange={handleTrackTypeChange} 
                            >
                                <option value="">{t('impersonation.select')}</option>
                                {trackTypesFilter?.map((trackType) => (
                                    <option key={trackType?.id} value={trackType.name}>
                                        {t(`trackphasetypes.${trackType.name}`)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
            )}

            <Table responsive>
                <thead>
                    <tr>
                        <th>{t('impersonation.itaaNumber')}</th>
                        <th>{t('impersonation.name')}</th>
                        {!isMobile && (
                            <>
                                <th>{t('impersonation.accessPortal')}</th>
                                <th>{t('impersonation.isEmployee')}</th>
                                <th>{t('impersonation.itaaQualification')}</th>
                                <th>{t('impersonation.officialType')}</th>
                            </>
                        )}
                        <th className='lastColumn'>{t('impersonation.action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {impersonations.map((item, index) => (
                        <tr key={item.id}>
                            <td>{formatItaaNumber(item.itaaNumber)}</td>
                            <td>{item.name}</td>
                            <td>{item.hasPortalAccess ? t('impersonation.yes') : t('impersonation.no')}</td>
                            <td>{item.isITAAEmployee || item.officialTypes.length === 0 ? t('impersonation.yes') : t('impersonation.no')}</td>
                            {!isMobile && (
                                <>
                                    <QualificationTableCell item={item} />
                                    <OfficialTypeTableCell item={item} />
                                </>
                            )}
                            <td className='lastColumn'>
                                <Button className="btn btn-home" variant="primary" onClick={() => handleImpersonate(item.id)}>
                                    {t('impersonation.detail')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="d-flex justify-content-center my-3">
                <span>{t('impersonation.page')} {currentPage}</span>
            </div>
            <Pagination className="justify-content-center">
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1} 
                >
                    {t('impersonation.previous')}
                </Pagination.Prev>
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!response.hasNextPage}
                >
                    {t('impersonation.next')}
                </Pagination.Next>
            </Pagination>
        </div>
    );
};

export default Impersonation;
