export default {
  nl: {
    title: "Opdrachten",
    title_steps: "Verschillende stappen van de dossierbehandeling",
    header_receivedDate: "Ontvangstdatum",
    header_dossier: "Dossier",
    header_type: "Type verslag",
    header_company: "Vennootschap",
    header_status: "Status",
    header_definition: "Definitie",
    header_date: "Datum",
    types: {
      100000004: "165 - Informatief verslag (niet verplicht)",
      100000003: "166 - Informatief verslag (niet verplicht)",
      100000000: "181 - Ontbinding en vereffening",
      100000001: "582/657 - Uitgifte van aandelen...",
      100000007:
        "596 en/of 598 - Beperking of afstand voorkeurrecht bij kapitaalverhoging",
      100000006: "670->773 - Fusies en splitsingen",
      100000005: "774->788 - Omzetting",
      100000002: "777",
    },
    details: 'Details',
    statusses: {
      None: '-',
      Research: 'In onderzoek',
      WithoutConsequence: 'Afgesloten zonder gevolg',
      CommentLetter: 'Afgesloten met bemerkingsbrief',
      Covocation1: '1e oproeping',
      Covocation2: '2e oproeping',
      Covocation3: '3e oproeping',
      CovocationLetter: 'Afgesloten met verslag oproeping',
      LegalService: '	oorgegeven aan juridische dienst',
    },
    statusdefinitions: {
      Research: 'Het dossier wordt onderzocht door de commissie van begeleiding en toezicht',
      WithoutConsequence: 'Het dossier is goedgekeurd door het ITAA',
      CommentLetter: 'Het dossier is afgesloten met een bemerking van de commissie van begeleiding en toezicht',
      Covocation1: 'U wordt opgeroepen om het dossier te bespreken met de commissie van begeleiding en toezicht',
      Covocation2: '-',
      Covocation3: '-',
      CovocationLetter: 'Het dossier is afgesloten met het verslag van de oproep',
      LegalService: '	Het dossier wordt onderzocht door de juridische dienst van het IAB met het oog op een tuchtprocedure',
    },
  },
  fr: {
    title: "Missions",
    title_steps: "Statut",
    header_receivedDate: "Date de réception",
    header_dossier: "Dossier",
    header_type: "Type de rapport",
    header_company: "Société",
    header_status: "Statut",
    header_definition: "Définition",
    header_date: "Date",
    types: {
      100000004: "165 - Rapport informatif (pas obligé)",
      100000003: "166 - Rapport informatif (pas obligé)",
      100000000: "181 - Dissolution et liquidation",
      100000001: "582/657 - Emissions d’actions...",
      100000007:
        "596 en/of 598 - Limitation ou suppression du droit de préférence en cas d’augmentation de capital",
      100000006: "670->773 - Fusions et scissions",
      100000005: "774->788 - Transformation",
      100000002: "777",
    },
    details: 'Détails',
    statusses: {
      None: '-',
      Research: "A l'étude",
      WithoutConsequence: 'Classement sans suite',
      CommentLetter: 'Classement moyennant envoi d’une lettre de remarque(s)',
      Covocation1: '1re convocation',
      Covocation2: '2e convocation',
      Covocation3: '3e convocation',
      CovocationLetter: 'Classement après audition',
      LegalService: 'Transmis au service juridique',
    },
    statusdefinitions: {
      Research: "Le dossier est examiné par la Commission de surveillance",
      WithoutConsequence: 'Le dossier est approuvé par la Commission d’accompagnement et de surveillance',
      CommentLetter: 'Le dossier est approuvé par la Commission d’accompagnement et de surveillance moyennant l’envoi d’une lettre de remarque(s)',
      Covocation1: 'Audition par la Commission d’accompagnement et de surveillance',
      Covocation2: '-',
      Covocation3: '-',
      CovocationLetter: 'Le dossier a été approuvé après audition et classé par la Commission d’accompagnement et de surveillance',
      LegalService: "Après analyse par le service juridique, le dossier sera transmis en vue d'une procédure disciplinaire",
    }
  },
};
