import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import {
    getPersonalDataDetail,
    patchPersonalDataDetail,
} from "./http/HttpClientHelper";
import { Modal, Button } from "react-bootstrap";
import NaturalPersonInfoHeader from "../../NaturalPersonInfoHeader/NaturalPersonInfoHeader";
import { FLAGS } from "../../../../hooks/useFlag";

const PersonalData = () => {
    const { t } = useTranslation();
    const { userDetail } = useOutletContext();
    const [myPersonalDataDetail, setMyPersonalDataDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuccess, SetIsSuccess] = useState(false);
    const [isSubmitted, setIsSubmited] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const details = await getPersonalDataDetail(userDetail.id).finally(() => {
                setLoading(false);
            });
            setMyPersonalDataDetail(details);
        })();
    }, [userDetail]);

    const handleCheckboxChange = async (fieldName) => {
        const updatedDetails = {
            ...myPersonalDataDetail,
            [fieldName]: !myPersonalDataDetail[fieldName],

        };
        setMyPersonalDataDetail(updatedDetails);
        setIsSubmited(true)
    };

    const handleSubmit = async () => {
        await patchPersonalDataDetail(userDetail.id, myPersonalDataDetail);
        SetIsSuccess(true);
    };
    const handleCloseSuccess = () => {
        SetIsSuccess(false);
        window.location.reload()
    };
    return (
        <div id="main">
            {loading && (
                <>
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                </>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("mandate_application.modal_success_title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t("popup.successUpdate")}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}
                        >
                            {t("mandate_application.modal_success_close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <NaturalPersonInfoHeader userDetail={userDetail} />
                <form
                    action="#"
                    className="form-overview form-detect-unsaved"
                    id="form"
                    method="post"
                >
                    <div className="text-h1">{t("personaldata.title")}</div>
                    <div className="form-group">
                        <div className="form-row">
                            <p className="text-description">
                                {t("personaldata.personalDataText1")}
                            </p>
                            <p className="text-description">
                                <u>{t("personaldata.personalDataLabel2")}</u>
                                <br />
                                <br />
                                {t("personaldata.personalDataText2")}
                            </p>
                            <div className="controls">
                                {t("personaldata.personalSubDataText2")}
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <p className="text-description">
                                <u>{t("personaldata.personalDataLabel3")}</u>
                                <b>{t("personaldata.personalSubDataLabel3")}</b>
                                <br />
                                {t("personaldata.personalDataText3")}
                            </p>
                            <div className="control-group no-label">
                                <div className="controls row-s" data-val="false">
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="allowInvitationsForSeminarsAndTraining"
                                            checked={
                                                myPersonalDataDetail?.allowInvitationsForSeminarsAndTraining ||
                                                ""
                                            }
                                            onChange={() =>
                                                handleCheckboxChange(
                                                    "allowInvitationsForSeminarsAndTraining"
                                                )
                                            }
                                        />
                                        {t("personaldata.personalDataInputField1")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <p className="text-description">
                                <br />
                                <u>{t("personaldata.personalDataText4")}</u>
                            </p>
                            <div className="control-group no-label">
                                <div className="controls row-s" data-val="false">
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="allowSharingWithProfessionalAssociations"
                                            checked={
                                                myPersonalDataDetail?.allowSharingWithProfessionalAssociations ||
                                                ""
                                            }
                                            onChange={() =>
                                                handleCheckboxChange(
                                                    "allowSharingWithProfessionalAssociations"
                                                )
                                            }
                                        />
                                        {t("personaldata.personalDataInputField2")}
                                    </div>
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="allowSharingWithB2BOrganizations"
                                            checked={
                                                myPersonalDataDetail?.allowSharingWithB2BOrganizations ||
                                                ""
                                            }
                                            onChange={() =>
                                                handleCheckboxChange("allowSharingWithB2BOrganizations")
                                            }
                                        />
                                        {t("personaldata.personalDataInputField3")}
                                    </div>
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="allowSharingWithCommercialPartners"
                                            checked={
                                                myPersonalDataDetail?.allowSharingWithCommercialPartners ||
                                                ""
                                            }
                                            onChange={() =>
                                                handleCheckboxChange(
                                                    "allowSharingWithCommercialPartners"
                                                )
                                            }
                                        />
                                        {t("personaldata.personalDataInputField4")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                    action=""
                    className="form-overview form-detect-unsaved"
                    id="form"
                    method="post"
                >
                    {FLAGS.CAN_UPDATE_PUBLIC_PROFILE && (
                        <>
                            <div className="text-h1">{t("sidebar.publicprofile")}</div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="control-group no-label">
                                        <div className="controls row-s" data-val="false">
                                            <div className="form-col">
                                                <input
                                                    type="checkbox"
                                                    name="allowSharingWithB2BOrganizations"
                                                    checked={
                                                        myPersonalDataDetail?.allowSharingWithB2BOrganizations ||
                                                        ""
                                                    }
                                                    onChange={() =>
                                                        handleCheckboxChange("allowSharingWithB2BOrganizations")
                                                    }
                                                />
                                                {t("personaldata.showWebsite")}
                                            </div>
                                            <div className="form-col">
                                                <input
                                                    type="checkbox"
                                                    name="allowSharingWithCommercialPartners"
                                                    checked={
                                                        myPersonalDataDetail?.allowSharingWithCommercialPartners ||
                                                        ""
                                                    }
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "allowSharingWithCommercialPartners"
                                                        )
                                                    }
                                                />
                                                {t("personaldata.showMobilePhone")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
            <div>
                <footer>
                    <div className="button-save-warning-container">
                        <button
                            className="btn btn-eloket"
                            onClick={handleSubmit}
                            disabled={!isSubmitted}
                        >
                            <i className="fa fa-check mr-2"></i>
                            {t("personaldata.save")}
                        </button>
                        {isSubmitted && <div className="save-warning"> {t("personaldata.notSaved")} </div>}
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default PersonalData;
