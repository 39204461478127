export default {
    nl: {
    mainContact: "Hoofdcontactpersoon",
    mainContactDescription: "Hoofdcontactpersoon verantwoordelijk voor de contacten met het ITAA.",
    generalDataDescription: "Raadpleeg uw gegevens die gekend zijn bij het ITAA en die u niet kunt wijzigen.",
    coadministrator: "Medebeheerder",
    coadministratorDescription: "Medebeheerder rubriek onderneming(en).",
    yes: "Deze onderneming (ITAA-vennootschap of eenpersoonszaak) heeft geen eigen cliënteel en werkt dus enkel voor één of meerdere erkende ITAA- of IBR-beroepsbeoefenaars, zoals bvb. in geval van een managementvennootschap. N.B. Door ‘JA’ te antwoorden zal het niet vereist zijn om een samenstelling kantoor in te vullen, noch om de antiwitwasfuncties AMLCO en Verantwoordelijke persoon op het hoogste niveau in te vullen voor deze onderneming.",
    no: "Nee",
  },
  fr: {
    mainContact: "Personne de contact principale",
    mainContactDescription: "Personne de contact principale responsable des contacts avec l'ITAA.",
    generalDataDescription: "Consultez vos données connues à l'ITAA que vous ne pouvez pas modifier.",
    coadministrator: "Co-administrateur",
    coadministratorDescription: "Rubrique co-administrateur entreprise(s).",
    yes: "Cette entreprise (société ITAA ou entreprise unipersonnelle) n’a pas de clients propres et donc preste uniquement pour et facture exclusivement à un ou plusieurs professionnels ITAA ou IRE, comme par exemple une société de management. N.B. En répondant 'OUI', il ne sera pas nécessaire de remplir une composition de cabinet, ni de remplir les fonctions AMLCO et Personne Responsable au plus haut niveau pour cette entreprise.",
    no: "Non",
  },
};
