import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const moment = require('moment-timezone');

export const formatItaaNumber = (number) => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return number;
};

export const formatShortDateAsLocalDate = (datetime) => {
    const appointmentTimeZone = 'Europe/Istanbul';
    if (!datetime) {
        return "-";
    }
    let date = new Date(datetime);
    if (date.getTime() <= 0) {
        return "-";
    }
    const formattedDate = formatInTimeZone(date, appointmentTimeZone, 'dd/MM/yyyy')
    return formattedDate;
};

export const formatDateOfBirth = (datetime) => {
    if (!datetime) {
        return "-";
    }

    let date = new Date(datetime);
    if (isNaN(date.getTime())) {
        return "-";
    }

    let localDate = new Date(date.getTime() + (new Date()).getTimezoneOffset() * -60000);
    let formattedDate = format(localDate, "dd/MM/yyyy");
    return formattedDate;
}

export const formatDateTimeAsLocalDateTime = (datetime) => {
    if (!datetime) {
        return "-";
    }
    const date = new Date(datetime);
    if (isNaN(date.getTime())) {
        return "-";
    }
    const formattedDate = format(date, 'dd-MM-yyyy HH:mm');
    return formattedDate;
};

export const formatDateTimeForEditorAsLocalDateTime = (datetime) => {
    if (!datetime) {
        return null;
    }
    const date = new Date(datetime);
    if (isNaN(date.getTime())) {
        return null;
    }
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm');
    return formattedDate;
};

export const formatDateTimeAsUtc = (datetime) => {
    if (!datetime)
        return null;
    let date = moment(datetime).utc().format();
    return date;
};

export const formatFileNumber = (fileNumber) => {
    const fileNumberStr = fileNumber.toString();
    const formattedNumber = fileNumberStr.slice(0, 2) + '.' + fileNumberStr.slice(2);
    return formattedNumber;
};

export const formatNationalRegistrationNumber = (value) => {
    // Verwijder alle niet-cijfertekens
    let numbersOnly = value.replace(/\D/g, '');

    // Voeg punten en streepje toe op de juiste posities
    if (numbersOnly.length > 2) numbersOnly = `${numbersOnly.slice(0, 2)}.${numbersOnly.slice(2)}`;
    if (numbersOnly.length > 5) numbersOnly = `${numbersOnly.slice(0, 5)}.${numbersOnly.slice(5)}`;
    if (numbersOnly.length > 8) numbersOnly = `${numbersOnly.slice(0, 8)}-${numbersOnly.slice(8)}`;
    if (numbersOnly.length > 12) numbersOnly = `${numbersOnly.slice(0, 12)}.${numbersOnly.slice(12, 15)}`;

    // Beperk de lengte tot 15 karakters
    return numbersOnly.slice(0, 15);
};

export const formatDatePicker = (value) => {
    // Verwijder alle niet-numerieke karakters
    const cleanedValue = value.replace(/\D/g, '');

    // Deel de schoongewiste waarde op in dag, maand en jaar
    const day = cleanedValue.substring(0, 2);
    const month = cleanedValue.substring(2, 4);
    const year = cleanedValue.substring(4, 8);

    // Bouw de geformatteerde datum op
    let formattedValue = '';

    if (day) {
        formattedValue += day; // Voeg dag toe
    }
    if (month) {
        formattedValue += (formattedValue ? '/' : '') + month; // Voeg maand toe
    }
    if (year) {
        formattedValue += (formattedValue ? '/' : '') + year; // Voeg jaar toe
    }

    return formattedValue;
};

export const formatCompanyName = (companyName) => {
    if (!companyName)
        return '';
    return companyName.toUpperCase();
}

export const formatLabelWithColon = (label, value) => {
    if (value) {
        return `${label} : ${value}`;
    } else {
        return `${label} : -`;
    }
}