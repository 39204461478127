export default {
  nl: {
    title: "Netwerk(en)",
    titleProfile:"Mijn netwerk(en)",
    subtitle:
      "Deze lijst geeft een overzicht weer van de netwerken van deze onderneming.",
    subtitleMyNetwork:"Deze lijst geeft een overzicht weer van uw netwerk(en)",
    name: "Netwerknaam",
    search: "Zoeken",
    delete: "Verwijderen",
    add: "Toevoegen",
    addNetwork: "Netwerk toevoegen",
    noDetail: "Geen netwerk(en)",
    cancel: "Annuleren",
    description:
      "Gelieve een bestaand of nieuw netwerk in de lijst te selecteren door erop te klikken en vervolgens op de knop “toevoegen” te klikken.",
    new: "Nieuw",
    duplicateError:"Dit netwerk zit al in de lijst!"
  },
  fr: {
    title: "Réseau(x)",
    titleProfile:"Mon Réseaux",
    subtitle: "Cette liste donne un aperçu des réseaux de cette entreprise.",
    subtitleMyNetwork:"Cette liste donne un aperçu de votre/vos réseau(x)",
    name: "Nom du réseau",
    search: "Rechercher",
    delete: "Supprimer",
    add: "Ajouter",
    addNetwork: "Ajouter un réseau",
    noDetail: "Pas de réseau(x)",
    cancel: "Annuler",
    description:
      'Veuillez sélectionner un réseau existant ou nouveau dans la liste en cliquant dessus, puis cliquez sur le bouton "Ajouter".',
    new: "Nouveaux",
    duplicateError:"Ce réseau est déjà dans la liste !"
  },
};
