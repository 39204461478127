import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCompanyDetails, patchMyDetails } from "./http/HttpClientHelper";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import { ValidateRequiredText } from "../../../../config/validationUtils";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import Insurance from "../../Profile/Insurance/Insurance";
import { EntityType } from "../../../../Enums/EntityType";
const CompanyInsurance = ({ userDetail }) => {
    const { t } = useTranslation();
    const [myDetails, setMyDetails] = useState([]);
    const { legalPersonId } = useParams();
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSubmitted, setIsSubmited] = useState(false);
    const [errors, setErrors] = useState(false);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const companydetail = await getCompanyDetails(legalPersonId).finally(() => {
                setLoading(false);
            });;
            setMyDetails(companydetail);
        })();
    }, [legalPersonId]);

    const patchDetails = async () => {
        setLoading(true);
        await patchMyDetails(legalPersonId, myDetails).finally(() => {
            setLoading(false);
        });;
        setIsSuccess(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const validationErrors = {};
        myDetails.insurances.forEach((insurance, index) => {
            if (!ValidateRequiredText(insurance.insurer)) {
                validationErrors[`insurer-${index}`] = t('validation.required');
            }
            if (!ValidateRequiredText(insurance.policeNumber)) {
                validationErrors[`policeNumber-${index}`] = t('validation.required');
            }
        });
        setErrors(Object.keys(validationErrors).length > 0);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsSuccess(false);
        setLoading(true);
        setIsConfirmModalVisible(false);
        await patchDetails();
    };

    const handleInputChange = (e, index, field) => {
        const { value } = e.target;
        const updatedInsurances = [...myDetails.insurances];
        updatedInsurances[index] = {
            ...updatedInsurances[index],
            [field]: value
        };
        setMyDetails({ ...myDetails, insurances: updatedInsurances });
        setIsSubmited(true);
    };

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("mandate_application.modal_success_title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t("popup.successUpdate")}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}
                        >
                            {t("mandate_application.modal_success_close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Company}
                />
            </div>

            <div id="content">
                <div className="form-row row">
                    <div className="form-col col-12 col-md-12 col-lg-8 col-xl-6">
                        <form
                            action="#"
                            className="form-overview form-detect-unsaved"
                            id="form"
                            method="post"
                            onSubmit={handleSubmit}
                        >
                            {myDetails?.insurances && myDetails.insurances.map((insurance, index) => (
                                <div key={index} className="insurance-block">
                                    <div className="text-h1">{t("sidebar.insurance")}</div>
                                    <div className="form-group">
                                        <div className="form-row row">
                                            <div className="form-col col-12">
                                                <div className="controls">
                                                    <input
                                                        className="form-control detect-unsaved-ignore"
                                                        type="text"
                                                        name={`insuranceCompany-${index}`}
                                                        value={insurance.insurer || ""}
                                                        onChange={(e) => handleInputChange(e, index, 'insurer')}
                                                    />
                                                    <label className="control-label required" htmlFor={`CompulsoryLiability_Company-${index}`}>
                                                        {t("basicdata.insuranceCompany")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row row">
                                            <div className="form-col col-12">
                                                <div className="controls">
                                                    <input
                                                        className="form-control detect-unsaved-ignore"
                                                        type="text"
                                                        name={`insurancePolicyNumber-${index}`}
                                                        value={insurance.policeNumber || ""}
                                                        onChange={(e) => handleInputChange(e, index, 'policeNumber')}
                                                    />
                                                    <label className="control-label required" htmlFor={`CompulsoryLiability_PolicyNumber-${index}`}>
                                                        {t("basicdata.policyNumber")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {errors && (
                                <div className="form-group">
                                    <div className="form-row row">
                                        <div className="form-col col-12">
                                            <span className="text-danger">
                                                {t('validation.allRequiredFields')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <footer>
                                <span>
                                    <div className="button-save-warning-container">
                                        <button className="btn btn-eloket" type="submit" disabled={!isSubmitted}>
                                            <i className="fa fa-check mr-2"></i> {t("basicdata.save")}
                                        </button>
                                        {isSubmitted && <div className="save-warning"> {t("basicdata.notSaved")} </div>}
                                    </div>
                                </span>
                            </footer>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyInsurance;
