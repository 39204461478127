import BeExcellentRequest from "../../../../../models/office/BeExcellentRequest";
import axiosClient from "./../../../../../axiosClient";

export const getOffice = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/detail`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const getBeExcellentData = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/beexcellent`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const patchBeExcellentAdmin = (legalPersonId, naturalPersonId) => {
  return axiosClient
    .patch(`/api/offices/${legalPersonId}/beexcellent/${naturalPersonId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
}

export const searchBeExcellentData = (legalPersonId, search) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/mandatemanagement/search/${search}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postBeExcellentRequest = async (beExcellentRequest) => {
  if (beExcellentRequest instanceof BeExcellentRequest) {
    return await axiosClient
      .post(`/api/offices/${beExcellentRequest.legalPersonId}/beexcellent`, beExcellentRequest);
  } else {
    throw new Error(`Error! ${'Model is not an instance of BeExcellentRequest'}`);
  }
}
