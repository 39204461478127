import axiosClient from "../../../../../axiosClient";

export const getMyDetails = (legalPersonId) => {
  return axiosClient
    .get(`/api/companies/${legalPersonId}/ownclientele`)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};


export const patchMyDetails = (legalPersonId, detailsToUpdate) => {
  return axiosClient
    .patch(`/api/companies/${legalPersonId}/ownclientele`, detailsToUpdate)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};
