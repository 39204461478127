import React, { useState, useEffect } from 'react';
import { getMyDetails, patchMyDetails, getCities, getCountries, getRegisteredOffice, patchNationalNumber } from './http/HttpClientHelper';
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { ListGroup } from 'react-bootstrap';
import { getCityName, getCountryName } from '../../../../config/translationUtils';
import NaturalPersonInfoHeader from '../../NaturalPersonInfoHeader/NaturalPersonInfoHeader';
import { ValidatePhoneNumber, ValidateRequiredText, ValidateWebsite } from '../../../../config/validationUtils';
import Validation from '../../../Validation/Validation';
import { formatLabelWithColon, formatNationalRegistrationNumber, formatShortDateAsLocalDate } from '../../../../config/formatUtils';

const Data = () => {
    const { userDetail } = useOutletContext();
    const { t, i18n } = useTranslation();
    const userLanguage = i18n.language;
    const [myDetails, setMyDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuccess, SetIsSuccess] = useState(false);
    const [isManualAddress, setIsManualAddress] = useState(true);
    const [validationErrorPhoneOfficeHours, setValidationErrorPhoneOfficeHours] = useState({});
    const [professionalDetailsRequired, setProfessionalDetailsRequired] = useState(true);
    const DEBOUNCE_TIME = 250;

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [showNationalNumberError, setShowNationalNumberError] = useState(false);
    const [showWebsiteValidationError, setShowWebsiteValidationError] = useState(false);

    // Private
    const [filteredPrivateCountries, setFilteredPrivateCountries] = useState([]);
    const [filteredPrivateCities, setFilteredPrivateCities] = useState([]);
    const [countryPrivateDropdown, setCountryPrivateDropdown] = useState(false);
    const [postalcodePrivateDropdown, setPostalcodePrivateDropdown] = useState(false);

    // Public
    const [filteredPublicCountries, setFilteredPublicCountries] = useState([]);
    const [filteredPublicCities, setFilteredPublicCities] = useState([]);
    const [countryPublicDropdown, setCountryPublicDropdown] = useState(false);
    const [postalcodePublicDropdown, setPostalcodePublicDropdown] = useState(false);
    var [allowProfCellPhoneNumberToSearchModule, setAllowProfCellPhoneNumberToSearchModule] = useState(false);
    var [allowProffesionalWebpageToSearchModule, setallowProffesionalWebpageToSearchModule] = useState(false);

    // KBO
    const [error, setError] = useState(false);
    const [loadingKBO, setLoadingKBO] = useState(false);

    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [hasEmptyField, setHasEmptyField] = useState(false)
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            const details = await getMyDetails(userDetail.id);

            setMyDetails(details);
        };

        fetchDetails().finally(() => {
            setLoading(false);
        });

        const fetchCountriesAndCities = async () => {
            const countries = await getCountries();
            const cities = await getCities();

            setCities(cities);
            setCountries(countries);
        }
        fetchCountriesAndCities();

        setProfessionalDetailsRequired(userDetail.tracks && userDetail.tracks.length > 0);
    }, [isSuccess, userDetail]);

    useEffect(() => {
        setAllowProfCellPhoneNumberToSearchModule(myDetails?.professionalDetails?.cellphoneNumber?.shownOnPublicSearch)
        setallowProffesionalWebpageToSearchModule(myDetails?.professionalDetails?.webpage?.shownOnPublicSearch)

        setHasValidationErrors(validateRequiredFields());
    }, [myDetails]);

    const handleCountryChange = async (event) => {
        const { name, value } = event.target;
        const path = name.replace(/\[(\d+)\]/g, '.$1').split('.');

        setMyDetails(prevDetails => {
            const updatedDetails = { ...prevDetails };
            let current = updatedDetails;
            for (let i = 0; i < path.length - 1; i++) {
                const key = isNaN(path[i]) ? path[i] : Number(path[i]);
                current[key] = current[key] || (isNaN(path[i + 1]) ? {} : []);
                current = current[key];
            }
            current[path[path.length - 1]] = value;
            return updatedDetails;
        });

        // Private
        if (name.includes('privateDetails') && name.includes('country')) {
            const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase())
                                                         || country.dutchName.toLowerCase().includes(value.toLowerCase())
                                                         || country.frenchName.toLowerCase().includes(value.toLowerCase()));
            setFilteredPrivateCountries(filtered);
            setCountryPrivateDropdown(true);
        }

        // Public 
        if (name.includes('professionalDetails') && name.includes('country')) {
            const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase())
                                                         || country.dutchName.toLowerCase().includes(value.toLowerCase())
                                                         || country.frenchName.toLowerCase().includes(value.toLowerCase()));
            setFilteredPublicCountries(filtered);
            setCountryPublicDropdown(true);
        }
        setValidationAndChanges();
    };

    const handlePostalCodeChange = async (event) => {
        const { name, value } = event.target;

        const path = name.replace(/\[(\d+)\]/g, '.$1').split('.');
        setMyDetails(prevDetails => {
            const updatedDetails = { ...prevDetails };
            let current = updatedDetails;
            for (let i = 0; i < path.length - 1; i++) {
                const key = isNaN(path[i]) ? path[i] : Number(path[i]);
                current[key] = current[key] || (isNaN(path[i + 1]) ? {} : []);
                current = current[key];
            }
            current[path[path.length - 1]] = value;
            return updatedDetails;
        });

        // Private
        if (name.includes('privateDetails') && name.includes('postalCode')) {
            setPostalcodePrivateDropdown(true);
            const postalCodeFilter = cities.filter(c =>
                c.country.id == myDetails?.privateDetails?.addressDetails[0]?.country.id &&
                (c.postalCode.toLowerCase().includes(value.toLowerCase())
                    || c.dutchName.toLowerCase().includes(value.toLowerCase())
                    || c.englishName.toLowerCase().includes(value.toLowerCase())
                    || c.frenchName.toLowerCase().includes(value.toLowerCase())))
                    .sort((a, b) => a.dutchName.localeCompare(b.dutchName));
            setFilteredPrivateCities(postalCodeFilter);
        }

        // Public
        if (name.includes('professionalDetails') && name.includes('postalCode')) {
            setPostalcodePublicDropdown(true);
            const postalCodeFilter = cities.filter(c =>
                c.country.id == myDetails?.professionalDetails?.addressDetails[0]?.country.id &&
                (c.postalCode.toLowerCase().includes(value.toLowerCase())
                    || c.dutchName.toLowerCase().includes(value.toLowerCase())
                    || c.englishName.toLowerCase().includes(value.toLowerCase())
                    || c.frenchName.toLowerCase().includes(value.toLowerCase())))
                    .sort((a, b) => a.dutchName.localeCompare(b.dutchName));
            setFilteredPublicCities(postalCodeFilter);
        }
        setValidationAndChanges();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
 
        const path = name.replace(/\[(\d+)\]/g, '.$1').split('.');
    
        setMyDetails((prevDetails) => {
            const updatedDetails = { ...prevDetails };

            let current = updatedDetails;
    
            for (let i = 0; i < path.length - 1; i++) {
                const key = isNaN(path[i]) ? path[i] : Number(path[i]); // Bepaal of de key een array-index is
    
                if (current[key] === null || current[key] === undefined) {
                    current[key] = isNaN(path[i + 1]) ? {} : [];
                }
    
                current = current[key];
            }
    
            const finalKey = path[path.length - 1];
            current[finalKey] = value;
    
            return updatedDetails;
        });

        setValidationAndChanges();
    };
    
    const handleKBOChange = (event) => {
        const { name, value } = event.target;
        const path = name.replace(/\[(\d+)\]/g, '.$1').split('.');
        setMyDetails(prevDetails => {
            const updatedDetails = { ...prevDetails };

            let current = updatedDetails;
            for (let i = 0; i < path.length - 1; i++) {
                const key = isNaN(path[i]) ? path[i] : Number(path[i]);
                current[key] = current[key] || (isNaN(path[i + 1]) ? {} : []);
                current = current[key];
            }

            current[path[path.length - 1]] = value;

            return updatedDetails;
        });
        (async () => {
            // Check if the input is complete (BE followed by 10 digits)
            if (/^BE\d{10}$/.test(value)) {
                setLoadingKBO(true);
                var data = await getRegisteredOffice(value.substring(2), ConvertToAbbreviation(userDetail?.language)); // Remove 'BE' and pass only the digits & language
                const city = cities.find(city => city?.postalCode === data?.registeredOffice?.postalCode);
                const country = countries.find(country => country.code == data?.registeredOffice?.country);

                if (data === null || data.length === 0) {
                    setError(true);
                } else {
                    setError(false);

                    setMyDetails(prevDetails => {
                        const updatedAddressDetails = [...prevDetails.professionalDetails.addressDetails];

                        updatedAddressDetails[0] = {
                            ...updatedAddressDetails[0],
                            street: `${data?.registeredOffice?.street || ""} ${data?.registeredOffice?.number || ""}`,
                            postalCode: city?.postalCode || "",
                            cityId: city?.id || "",
                            city: getCityName(city, userLanguage),
                            country: country,
                        };

                        return {
                            ...prevDetails,
                            professionalDetails: {
                                ...prevDetails.professionalDetails,
                                addressDetails: updatedAddressDetails,
                            },
                        };
                    });
                }

                setLoadingKBO(false);
            }
        })();
        setValidationAndChanges();
    };

    const ConvertToAbbreviation = (language) => {
        var lowercaseLanguage = language.toLowerCase();

        if (lowercaseLanguage === "dutch") {
            return "nl";
        }
        if (lowercaseLanguage === "french") {
            return "fr";
        }
        if (lowercaseLanguage === "english") {
            return "en";
        }

        // default langauge
        return "en"
    }

    const handlePrivateItemClick = (item, field) => {
        const index = 0;
        setMyDetails(prevDetails => {
            const updatedAddressDetails = [...prevDetails.privateDetails.addressDetails];

            if (field === 'privateDetails_Country') {
                updatedAddressDetails[index] = {
                    ...updatedAddressDetails[index],
                    country: item,
                    postalCode: '',
                    city: '',
                };

                const filtered = cities.filter(city =>
                    city.country.id === item.id
                );
                setFilteredPrivateCities(filtered);

                setCountryPrivateDropdown(false);
            }

            if (field === 'privateDetails_PostalCode') {
                updatedAddressDetails[index] = {
                    ...updatedAddressDetails[index],
                    postalCode: item.postalCode,
                    cityId: item.id,
                    city: getCityName(item, userLanguage),
                };

                setPostalcodePrivateDropdown(false);
            }

            return {
                ...prevDetails,
                privateDetails: {
                    ...prevDetails.privateDetails,
                    addressDetails: updatedAddressDetails,
                },
            };
        });

        // Validatie en updates
        setValidationAndChanges();
    };

    const handlePublicItemClick = (item, field) => {
        const index = 0;
        setMyDetails(prevDetails => {
            const updatedAddressDetails = [...prevDetails.professionalDetails.addressDetails];

            if (field === 'professionalDetails_Country') {
                updatedAddressDetails[index] = {
                    ...updatedAddressDetails[index],
                    country: item,
                    postalCode: '',
                    city: '',
                };

                const filtered = cities.filter(city =>
                    city.country.id === item.id
                );
                setFilteredPrivateCities(filtered);

                setCountryPrivateDropdown(false);
            }

            if (field === 'professionalDetails_PostalCode') {
                updatedAddressDetails[index] = {
                    ...updatedAddressDetails[index],
                    postalCode: item.postalCode,
                    cityId: item.id,
                    city: getCityName(item, userLanguage),
                };

                setPostalcodePrivateDropdown(false);
            }

            return {
                ...prevDetails,
                professionalDetails: {
                    ...prevDetails.professionalDetails,
                    addressDetails: updatedAddressDetails,
                },
            };
        });

        // Validatie en updates
        setValidationAndChanges();
    };

    const handleCloseSuccess = () => {
        window.location.reload()
    };

    const handleRadioChange = (event) => {
        setError(false);
        setIsManualAddress(event.target.value === 'False');
        setValidationAndChanges();
    };

    const setValidationAndChanges = () => {
        setHasValidationErrors(validateRequiredFields());
        setHasChanges(true);
    }

    const handleDataChange = async () => {
        setLoading(true);

        var result = await patchNationalNumber(userDetail.id, myDetails.nationalNumber);
        if (result !== '500') {
            await patchMyDetails(userDetail.id, myDetails);
            SetIsSuccess(true);
        }
        else {
            setShowNationalNumberError(true);
        }

        setHasChanges(false);
        setLoading(false);
        setIsConfirmModalVisible(false);
    };

    const validateRequiredFields = () => {
        let validationErrorCount = 0;

        ValidateRequiredText(myDetails?.privateDetails?.addressDetails[0]?.street) || validationErrorCount++;
        ValidateRequiredText(myDetails?.privateDetails?.addressDetails[0]?.country?.name) || validationErrorCount++;
        ValidateRequiredText(myDetails?.privateDetails?.addressDetails[0]?.postalCode) || validationErrorCount++;
        ValidateRequiredText(myDetails?.nationalNumber) || validationErrorCount++;

        if (professionalDetailsRequired) {
            ValidateRequiredText(myDetails.professionalDetails?.addressDetails[0]?.street) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails?.addressDetails[0]?.country?.name) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails?.addressDetails[0]?.postalCode) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails?.addressDetails[0]?.city) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails?.email?.value) || validationErrorCount++;
        }

        if (validationErrorCount > 0) {
            return false;
        }
        else {
            return true;
        }
    }


    const handleProfCellPhoneNumberCheckboxChange = (event) => {
        const { checked } = event.target;
         
        if(!(myDetails?.professionalDetails?.cellphoneNumber?.value?.trim())){
            setHasEmptyField(true); 

        }else{
            setHasChanges(true);
            setAllowProfCellPhoneNumberToSearchModule(prev => prev =checked); 

            setMyDetails(prevDetails => ({
                ...prevDetails,
                professionalDetails: {
                    ...prevDetails.professionalDetails,
                    cellphoneNumber: {
                        ...prevDetails.professionalDetails.cellphoneNumber,
                        shownOnPublicSearch: checked, 
                    },
                },
            }));
        }

    };

    const handleProfWebPageCheckboxChange = (event) => {
        const { checked } = event.target; 
    
        if(!(myDetails?.professionalDetails?.webpage?.value?.trim())){
            setShowWebsiteValidationError(true); 
        }else{
            setHasChanges(true); 

            setallowProffesionalWebpageToSearchModule(checked);
    
            setMyDetails(prevDetails => ({
                ...prevDetails,
                professionalDetails: {
                    ...prevDetails.professionalDetails,
                    webpage: {
                        ...prevDetails.professionalDetails.webpage,
                        shownOnPublicSearch: checked, 
                    },
                },
            }));
        }
    };
    const handleSubmit = () => {
        const validationErrors = {};
        ValidatePhoneNumber(myDetails.professionalDetails?.phoneNumber?.value) || (validationErrors.phoneOfficeHours = t('validation.phoneNumber'));
        setValidationErrorPhoneOfficeHours(validationErrors);

        if (!ValidateWebsite(myDetails.professionalDetails?.webpage.value)) {
            validationErrors.webpage = '';
            setShowWebsiteValidationError(true);
        }

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        setShowWebsiteValidationError(false);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await handleDataChange();
    };

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isSuccess && ( // Ensure that modal is displayed when isSuccess is true
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('data.modal_success_text')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <NaturalPersonInfoHeader userDetail={userDetail} />
            {!hasValidationErrors && (
                <div className="form-group">
                    <div className="form-row row">
                        <div className="form-col col-12">
                            <span className="text-danger">
                                {t('validation.allRequiredFields')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className="form-grid">
                <div className='form-row row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className="text-h1">
                            {t('data.public_register_email')}
                        </div>
                        <div className="form-group">
                            <div className='mb-3'>
                                <span>{t('data.text_public_register_email')}</span>
                            </div>
                            <div className="form-row">
                                <div className="form-col col-1-1">
                                    <div className="controls">
                                        <input
                                            autoComplete="off"
                                            className="form-control masked-kbo-input"
                                            type="text"
                                            name="professionalDetails.email.value"
                                            value={myDetails?.professionalDetails?.email?.value || ""}
                                            onChange={handleInputChange}
                                        />
                                        <label className='control-label required'>{t('data.public_register_email')}</label>
                                    </div>
                                    <span>{formatLabelWithColon(t('data.modifiedOn'), formatShortDateAsLocalDate(myDetails?.professionalDetails?.email?.modifiedOn))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-h1">
                            {t('data.contactDetailsPrivate')}
                        </div>
                        <div className="form-group">
                            <form action="#" className="form-overview form-detect-unsaved" id="form" method="post" onSubmit={handleSubmit} noValidate>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.addressDetails[0].street"
                                                value={myDetails?.privateDetails?.addressDetails[0]?.street || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className="control-label required">{t('data.street')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.addressDetails[0].country"
                                                value={getCountryName(myDetails?.privateDetails?.addressDetails[0]?.country, userLanguage)}
                                                onBlur={() => setTimeout(() => setCountryPrivateDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handleCountryChange}
                                                onChange={handleCountryChange}
                                            />
                                            <label className="control-label required">{t('data.country')}</label>
                                            {countryPrivateDropdown && (
                                                <ListGroup className="list-group-data">
                                                    <>
                                                        {filteredPrivateCountries.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePrivateItemClick(item, 'privateDetails_Country')}>
                                                                {getCountryName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.addressDetails[0].postalCode"
                                                value={myDetails?.privateDetails?.addressDetails[0]?.postalCode || ""}
                                                onBlur={() => setTimeout(() => setPostalcodePrivateDropdown(false), DEBOUNCE_TIME)}
                                                disabled={!myDetails?.privateDetails?.addressDetails[0]?.country}
                                                onFocus={handlePostalCodeChange}
                                                onChange={handlePostalCodeChange}
                                            />
                                            <label className="control-label required">{t('data.postalcode')}</label>
                                            {postalcodePrivateDropdown && (
                                                <ListGroup>
                                                    <>
                                                        {filteredPrivateCities.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePrivateItemClick(item, 'privateDetails_PostalCode')}>
                                                                {item.postalCode} {getCityName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                        {filteredPrivateCities.length === 0 && myDetails?.privateDetails?.addressDetails[0]?.postalCode.length === 0 &&
                                                            (<ListGroup.Item>
                                                                {t('data.noResult')}
                                                            </ListGroup.Item>
                                                            )}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.addressDetails[0].city"
                                                value={myDetails?.privateDetails?.addressDetails[0]?.city || ""}
                                                disabled
                                            />
                                            <label className="control-label required">{t('data.city')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.phoneNumber.value"
                                                value={myDetails?.privateDetails?.phoneNumber?.value || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.phone')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails.cellphoneNumber.value"
                                                value={myDetails?.privateDetails?.cellphoneNumber?.value || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.mobilephone')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="nationalNumber"
                                                value={formatNationalRegistrationNumber(myDetails?.nationalNumber || "")}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label required'>{t('data.nationalNumber')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        {showNationalNumberError && (
                                            <span className='warning-text'>{t('data.wrong_nationalnumber')}</span>
                                        )}
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className="text-h1">
                            {t('data.contactDetails')}
                        </div>
                        <div className="form-group">
                            <form action="#" className="form-overview form-detect-unsaved" id="form" method="post" onSubmit={handleSubmit}>
                                <div className="form-row row mb-3">
                                    <div className="form-col col-md-12" style={{ width: 'auto' }}>
                                        <label>{t('data.select_source_professional_contactdetails')}</label><br />
                                        <span style={{ color: '#550A21' }}>
                                            {t('data.kbo_text')}
                                        </span>
                                    </div>

                                    <div className="controls" style={{ width: '500px' }} >
                                        <input
                                            autoComplete="off"
                                            type="radio"
                                            value="True"
                                            checked={!isManualAddress}
                                            onChange={handleRadioChange}
                                        />
                                        {t('data.kbo_input_text')}
                                        <br />
                                        <input
                                            autoComplete="off"
                                            type="radio"
                                            value="False"
                                            checked={isManualAddress}
                                            onChange={handleRadioChange}
                                        />
                                        {t('data.manual_input_text')}
                                    </div>
                                </div>
                                {!isManualAddress && (
                                    <div className="form-row company-number-kbo row">
                                        <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="controls">
                                                <InputMask
                                                    mask="BE9999999999"
                                                    maskChar={null}
                                                    className="form-control"
                                                    type="text"
                                                    name="professionalDetails.addressDetails[0].addressFromKBO"
                                                    value={myDetails?.professionalDetails?.addressDetails[0]?.addressFromKBO || ""}
                                                    onChange={handleKBOChange}
                                                />
                                                <label className='control-label'>{t('data.companynumber')}</label>
                                                {loadingKBO && <div className="loader-small" />}
                                                {!loadingKBO && error && (<span className="error-field">{t('data.error')}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.addressDetails[0].street"
                                                value={myDetails?.professionalDetails?.addressDetails[0]?.street || ""}
                                                onChange={handleInputChange}
                                                disabled={!isManualAddress}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.street')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.addressDetails[0].country"
                                                value={getCountryName(myDetails?.professionalDetails?.addressDetails[0]?.country, userLanguage)}
                                                onBlur={() => setTimeout(() => setCountryPublicDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handleCountryChange}
                                                onChange={handleCountryChange}
                                                disabled={!isManualAddress}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.country')}</label>
                                            {countryPublicDropdown && (
                                                <ListGroup className="list-group list-group-data">
                                                    <>
                                                        {filteredPublicCountries.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePublicItemClick(item, 'professionalDetails_Country')}>
                                                                 {getCountryName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.addressDetails[0].postalCode"
                                                value={myDetails?.professionalDetails?.addressDetails[0]?.postalCode || ""}
                                                onBlur={() => setTimeout(() => setPostalcodePublicDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handlePostalCodeChange}
                                                onChange={handlePostalCodeChange}
                                                disabled={!isManualAddress || !myDetails?.professionalDetails?.addressDetails[0]?.country}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.postalcode')}</label>
                                            {postalcodePublicDropdown && (
                                                <ListGroup>
                                                    <>
                                                        {filteredPublicCities.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePublicItemClick(item, 'professionalDetails_PostalCode')}>
                                                                {item.postalCode} {getCityName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                        {filteredPublicCities.length === 0 && myDetails?.professionalDetails?.addressDetails[0]?.postalCode.length === 0 &&
                                                            (<ListGroup.Item>
                                                                {t('data.noResult')}
                                                            </ListGroup.Item>
                                                            )}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.addressDetails[0].city"
                                                value={myDetails?.professionalDetails?.addressDetails[0]?.city || ""}
                                                onChange={handleInputChange}
                                                disabled
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.city')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.phoneNumber.value"
                                                value={myDetails?.professionalDetails?.phoneNumber?.value || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.phone_officehours')}</label>
                                            <Validation errors={validationErrorPhoneOfficeHours} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <span>{t('data.text_public_register_phone_web')}</span>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.cellphoneNumber.value"
                                                value={myDetails?.professionalDetails?.cellphoneNumber?.value || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.mobilephone')}</label>
                                            {hasEmptyField && (
                                                <span className="warning-text">
                                                    {t('validation.phoneNumber')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails.webpage.value"
                                                value={myDetails?.professionalDetails?.webpage?.value || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.web')}</label>
                                            {showWebsiteValidationError && (
                                                <span className="warning-text">
                                                    {t('validation.webpage')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col">
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="professionalDetails.cellphoneNumber.shownOnPublicSearch"
                                            checked={allowProfCellPhoneNumberToSearchModule ?? false } 
                                            onChange={handleProfCellPhoneNumberCheckboxChange}
                                        />
                                        {t("personaldata.showPhoneNumberOnPublicSearch")}
                                    </div>
                                    <div className="form-col">
                                        <input
                                            type="checkbox"
                                            name="professionalDetails.webpage.shownOnPublicSearch"
                                            checked={allowProffesionalWebpageToSearchModule ?? false} 
                                            onChange={handleProfWebPageCheckboxChange} 
                                        />
                                        {t("personaldata.showWebPageOnPublicSearch")}
                                    </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="button-container-personal-data">
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="submit" onClick={handleSubmit} disabled={!hasValidationErrors || !hasChanges || error}>
                                <i className="fa fa-check mr-2"></i>{t('data.save')}
                            </button>
                        </div>

                        {hasChanges && <div className="save-warning"> {t("data.notSaved")} </div>}
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Data;