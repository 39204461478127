export default {
    nl: {
        Accountant: "Accountant",
        TaxAccountant: "Fiscaal accountant",
        FiscalAccountant: "Fiscaal accountant",

        //Trainees
        TraineeInternalAccountant: "Intern stagiair accountant",
        TraineeInternalTaxAccountant: "Intern stagiair fiscaal accountant",
        TraineeInternalCertifiedAccountant: "Intern stagiair gecertificeerd accountant",
        TraineeInternalCertifiedTaxAdvisor: "Intern stagiair gecertificeerd belastingadviseur",
        TraineeAccountant: "Stagiair accountant",
        TraineeTaxAccountant: "Stagiair fiscaal accountant",
        TraineeCertifiedAccountant: "Stagiair gecertificeerd accountant",
        TraineeCertifiedTaxAdvisor: "Stagiair gecertificeerd belastingadviseur",
        //Internal
        InternalIntern: "Intern stagiair",
        InternalCertifiedFiscalAccountant: "Intern gecertificeerd (fiscaal) accountant",
        InternalTaxAccountant: "Intern belastingadviseur",
        InternalAccountant: "Intern accountant",
        InternalCertifiedAccountant: "Intern gecertificeerd accountant",
        InternalCertifiedTaxAdvisor: "Intern gecertificeerd belastingadviseur",
        InternalFiscalAccountant: "Intern fiscaal accountant",

        //Certified
        CertifiedInternalMember: "Gecertificeerd intern lid",
        CertifiedExternalMember: "Gecertificeerd extern lid",
        CertifiedInternLessOrEqualsThen5Years: "Gecertificeerd stagiair =/< 5 jaar",
        CertifiedInternGreaterThen5Years: "Gecertificeerd stagiair > 5 jaar",
        CertifiedTraineeTaxAdvisor: "Stagiair gecertificeerd belastingadviseur",
        CertifiedTraineeTaxAccountant: "Stagiair gecertificeerd accountant",
        CertifiedTaxAccountant: "Gecertificeerd fiscaal accountant",
        CertifiedAccountant: "Gecertificeerd  accountant",
        CertifiedTaxAdvisor: "Gecertificeerd belastingadviseur",
        CertifiedFiscalAccountant: "Gecertificeerd (fiscaal) accountant",

        HonoraryMember: "Erelid",
        InternalMember: "Intern lid",
        ExternalMember: "Extern lid",

        //Candidates
        CandidateIntern: "Kandidaat stagiair",
        Candidate7Year: "Kandidaat 7 jaar",
        CandidateCertifiedFiscalAccountant: "Kandidaat gecertificeerd fiscaal accountant",

        _7YearCertifiedFiscalAccountant: "7 jaar gecertificeerd fiscaal accountant",
        _7YearCertifiedTaxAdvisor: "7 jaar gecertificeerd tax accountant",

        //Internship
        InternshipFiscalAccountant: "Stagiair fiscaal accountant",
        InternshipInternalFiscalAccountant: "Intern stagiair fiscaal accountant",
        InternshipAccountant: "Stagiair accountant",
        InternshipInternalAccountant: "Intern stagiair accountant",
        InternshipCertifiedFiscalAccountant: "Stagiair gecertificieerd fiscaal accountant",
        InternshipInternalCertifiedFiscalAccountant: "Intern stagiair gecertificeerd accountant",
        InternshipCertifiedTaxAdvisor: "Stagiair gecertificeerd belastingadviseur",
        InternshipInternalCertifiedTaxAdvisor: "Intern stagiair gecertificeerd belastingadviseur",
        InternLessOrEqualsThen3Years: "Stagiair =/< 3 jaar",
        InternGreaterThen3Years: "Stagiair > 3 jaar",

        Employee: "Medewerker",
        _7YearArt56: "7 jaar ART 56",
        _7Year: "7 jaar",

        Recognisedlegalperson: 'Erkend rechtspersoon',
        Recognisedlegalpersontrainee: 'Erkend rechtspersoon stagiair',
    },
    fr: {
        Accountant: "expert-comptable",
        TaxAccountant: "expert-comptable fiscaliste",
        FiscalAccountant: "Expert-comptable fiscaliste",


        //Trainees
        TraineeInternalAccountant: "Stagiair expert-comptable interne",
        TraineeInternalTaxAccountant: "Stagiair expert-comptable fiscaliste interne",
        TraineeInternalCertifiedAccountant: "Stagiair  expert-comptable interne certifié",
        TraineeInternalCertifiedTaxAdvisor: "Stagiair conseiller fiscal interne certifié",
        TraineeAccountant: "Stagiair accountant",
        TraineeTaxAccountant: "Stagiair expert-comptable fiscaliste",
        TraineeCertifiedAccountant: "Stagiair expert-comptable certifié",
        TraineeCertifiedTaxAdvisor: "Stagiair conseiller fiscal certifié",


        //Internal
        InternalIntern: "Stagiair interne",
        InternalCertifiedFiscalAccountant: "expert-comptable (fiscal) interne certifié",
        InternalAccountant: "Expert-comptable interne",
        InternalTaxAccountant: "Expert-comptable fiscaliste interne",
        InternalCertifiedAccountant: "Expert-comptable interne certifié",
        InternalCertifiedTaxAdvisor: "Conseiller fiscal interne certifié",
        InternalFiscalAccountant: "Expert-comptable fiscaliste interne",

        //Certified
        CertifiedInternalMember: "Membre certifié intern",
        CertifiedExternalMember: "Membre certifié extern",
        CertifiedInternLessOrEqualsThen5Years: "Stagiaire certifié =/< 5 ans",
        CertifiedInternGreaterThen5Years: "Stagiaire certifié > 5 ans",
        CertifiedTraineeTaxAdvisor: "Stagiair conseiller fiscal certifié",
        CertifiedTraineeTaxAccountant: "Stagiaire expert-comptable certifié",
        CertifiedTaxAccountant: "Expert-comptable certifié",
        CertifiedAccountant: "Expert-comptable certifié",
        CertifiedTaxAdvisor: "Conseiller fiscal certifié",
        CertifiedFiscalAccountant: "Expert-comptable (fiscal) certifié",

        HonoraryMember: "Membre honaire",
        InternalMember: "Membre interne",
        ExternalMember: "Membre externe",

        //Candidates    
        CandidateIntern: "Candidat stagiair",
        Candidate7Year: "Candidat 7 ans",
        CandidateCertifiedFiscalAccountant: "Candidat comptable fiscaliste agréé",

        _7YearCertifiedFiscalAccountant: "7 ans d'experience en tant que comptable fiscal agréé",
        _7YearCertifiedTaxAdvisor: "7 ans d'experience en tant que comptable fiscal agréé",


        //Internship
        InternshipFiscalAccountant: "Stagiair expert-comptable fiscaliste",
        InternshipInternalFiscalAccountant: "Stagiaire expert-comptable fiscaliste interne",
        InternshipAccountant: "Stagiair expert-comptable",
        InternshipInternalAccountant: "Stagiair interne comptable",
        InternshipCertifiedFiscalAccountant: "Stagiair en comptabilité fiscale",
        InternshipInternalCertifiedFiscalAccountant: "Intern stagiair gecertificeerd accountant",
        InternshipCertifiedTaxAdvisor: "Stagiair gecertificeerd belastingadviseur",
        InternshipInternalCertifiedTaxAdvisor: "Stagiaire conseiller fiscal interne certifié",
        InternLessOrEqualsThen3Years: "Stagiair =/< 3 ans",
        InternGreaterThen3Years: "Stagiair > 3 ans",

        Employee: "Collaborateur",
        _7YearArt56: "7 ans ART 56",
        _7Year: "7 ans",

        Recognisedlegalperson: 'Société reconnue',
        Recognisedlegalpersontrainee: 'Société reconnue stagiaire',
    },
};
