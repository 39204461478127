import ExistingUserToolAccess from "../../../../../models/naturalPerson/ExistingUserToolAccess";
import NewUserToolAccess from "../../../../../models/naturalPerson/NewUserToolAccess";
import showToastMessage from "../../../../Toast/ToastMessage";
import axiosClient from "./../../../../../axiosClient";
import i18next from "i18next";

export const getAccessToolsData = async (legalPersonId) => {
  const response = await axiosClient
    .get(`/api/offices/${legalPersonId}/accesstools`);
  return response.data;
};

export const getLanguages = async () => {
  const response = await axiosClient
    .get(`/api/common/languages`);
  return response.data;
};

export const getGenders = async () => {
  const response = await axiosClient
    .get(`/api/common/genders`);
  return response.data;
};

export const getNationalities = async () => {
  const response = await axiosClient
    .get(`/api/common/nationalities`);
  return response.data;
};

export const searchAccessToolsCandidates = async (legalPersonId, search) => {
  const response = await axiosClient
    .get(`/api/offices/${legalPersonId}/accesstools/search/${search}`);
  return response.data;
};

export const postPersonAccessTools = async (userData) => {
  if (userData instanceof ExistingUserToolAccess) {
    return await axiosClient.post(`/api/offices/${userData.legalPersonId}/accesstools/addtoolaccess`, userData);
  } else {
    showToastMessage(i18next.t('errors.addTools'));
  }
};

export const postNewPersonAccessTools = async (userData) => {
  if (userData instanceof NewUserToolAccess) {
    return await axiosClient
      .post(`/api/offices/${userData.legalPersonId}/accesstools/addnewusertoolaccess`, userData);
  } else {
    showToastMessage(i18next.t('errors.addTools'));
  }
};

export const deletePersonAccessTools = async (deletePersonAccessToolsParams) => {
  const response = await axiosClient.delete(`/api/offices/deletetoolsaccess`, {
    data: deletePersonAccessToolsParams
  });
  return response;
};

export const newMandateManager = async (legalPersonId, naturalPersonId) => {
  return await axiosClient
    .post(`/api/offices/${legalPersonId}/mandatemanagement/addmandateadmin/${naturalPersonId}`, legalPersonId, naturalPersonId);
};
