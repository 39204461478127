import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutocompleteResults from '../AutoComplete/AutoComplete';
import { addPersonQualityResponsible } from '../http/HttpClientHelper';

const Add = ({ show, handleClose, legalPersonId }) => {
    const { t } = useTranslation();
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [hideListGroup, setHideListGroup] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const listGroupRef = useRef(null);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setHideListGroup(false);
    };

    const addQualityResponsible = async () => {
        setLoading(true);
        if (selectedPerson && selectedPerson.naturalPersonId) {
            await addPersonQualityResponsible(legalPersonId, selectedPerson.naturalPersonId);
            setSelectedName(selectedPerson.fullName);
            setIsSuccess(true);
        }
        setIsConfirmModalVisible(false);
        setLoading(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await addQualityResponsible();
    };

    const handleAutocompleteSelection = (selectedResult) => {
        if (selectedResult) {
            setSelectedPerson(selectedResult);
            setSelectedName(selectedResult.fullName);
        } 
        setHideListGroup(true);
    };

    const handleMouseDown = (e) => {
        if (listGroupRef.current && listGroupRef.current.contains(e.target)) {
            e.preventDefault();
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (listGroupRef.current && !listGroupRef.current.contains(document.activeElement)) {
                setHideListGroup(true);
            }
        }, 100);
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess ? (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('co_administrator.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <>
                    {isConfirmModalVisible ? (<>
                        <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                            <Modal.Header closeButton />
                            <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                                    {t("confirmation.cancel")}
                                </Button>
                                <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                                    {t("confirmation.confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    ) : (
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('quality_responsible.add_modal_title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="formNetworkName">
                                    <Form.Label>
                                        {t('aml_officers.modal_input_description')} <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedName}
                                        onChange={handleNameChange}
                                        onBlur={handleBlur}
                                        autoComplete='off'
                                        required
                                    />
                                    {selectedName.trim().length >= 3 && !hideListGroup && (
                                        <div ref={listGroupRef} onMouseDown={handleMouseDown}>
                                            <AutocompleteResults
                                                inputValue={selectedName}
                                                handleSelection={handleAutocompleteSelection}
                                                legalPersonId={legalPersonId}
                                            />
                                        </div>
                                    )}
                                    <span>{t('quality_responsible.modal_input_description')}</span>
                                </Form.Group>

                                <Modal.Footer className="justify-content-between">
                                    <Button variant="secondary" onClick={handleClose}>
                                        {t('access_tools.modal_cancel')}
                                    </Button>
                                    <Button
                                        className="btn-eloket"
                                        variant="secondary"
                                        onClick={handleSubmit}
                                        disabled={!selectedName.trim()}
                                    >
                                        {t('office_composition.modal_add')}
                                    </Button>
                                </Modal.Footer>
                            </Modal.Body>
                        </Modal>
                    )}
                </>

            )}
        </>
    )
};

export default Add;
