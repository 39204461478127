import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getEstoxCompanies, getEstoxLink } from './http/HttpClientHelper';

const Estox = ({ show, handleClose, userDetail }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEntityId, setSelectedEntityId] = useState('');
    const [selectedEnvironment, setSelectedEnvironment] = useState('');
    const [selectedEntityType, setSelectedEntityType] = useState('');

    useEffect(() => {
        (async () => {
            setLoading(true);
            var companies = await getEstoxCompanies(userDetail.id);
            setCompanies(companies);
            setLoading(false);
        })();

    }, []);

    const handleSelectChange = (event) => {
        let entityId = event.target.value;
        let entityType = companies.find(c => c.entityId == entityId).entityType;
        setSelectedEntityType(entityType);
        setSelectedEntityId(entityId);
    };

    const handleConfirm = () => {
        getEstoxLink(userDetail.id, selectedEntityId, selectedEntityType, selectedEnvironment)
            .then((link) => {
                window.open(link.estoxLink, '_blank');
                handleClose();
            });
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal show={show} onHide={handleClose}>
                {selectedEnvironment === '' ? (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('estoxModal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button className='estox-button' onClick={() => setSelectedEnvironment('LIVE')}>
                                <h4>{t('estoxModal.environment_live_title')}</h4>
                                <p>{t('estoxModal.environment_live_description')}</p>
                            </Button>
                            <Button className='estox-button' onClick={() => setSelectedEnvironment('DEMO')}>
                                <h4>{t('estoxModal.environment_demo_title')}</h4>
                                <p>{t('estoxModal.environment_demo_description')}</p>
                            </Button>
                        </Modal.Body>
                    </>
                ) : (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('estoxModal.title_environment').replace('{env}', selectedEnvironment)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t(`estoxModal.pricing_${selectedEnvironment.toLowerCase()}`)}</p>
                            <p>{t('estoxModal.choice')}</p>
                            <p dangerouslySetInnerHTML={{ __html: t('estoxModal.questions') }}></p>
                            <select className='form-control' onChange={handleSelectChange} value={selectedEntityId}>
                                <option value="">{t('estoxModal.selectTitle')}</option>
                                {companies
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(company => (
                                        <option key={company.entityId} value={company.entityId}>
                                            {company.name} | {company.vatNumber}
                                        </option>
                                    ))}
                            </select>
                            <br />
                            <p className='warning-text inline' dangerouslySetInnerHTML={{ __html: t('estoxModal.legal_warning') }}></p>
                            <p dangerouslySetInnerHTML={{ __html: t('estoxModal.pracitcial_warning') }}></p>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-end">
                            <Button
                                variant="secondary"
                                className="btn-eloket"
                                onClick={handleConfirm}
                                disabled={!selectedEntityId}>
                                {t('confirmation.confirm')}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
};

export default Estox;