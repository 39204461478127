import axiosClient from "./../../../../../axiosClient";

export const getCoAdmins = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/coadministrators`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
export const searchMainContact = async (legalPersonId, search) => {
  const url =
    search === ""
      ? `/api/offices/${legalPersonId}/maincontact/search`
      : `/api/offices/${legalPersonId}/maincontact/search?search=${search}`;

    const response = await axiosClient
      .get(url);
    return response.data;
};
export const searchCoAdmin = (legalPersonId, search) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/coadministrator/${search}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postCoAdmin = (legalPersonId, naturalPersonId) => {
  return axiosClient
    .post(`/api/offices/${legalPersonId}/coadministrator`, naturalPersonId)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const deleteCoAdmin = async (deleteCoAdminParams) => {
    try {
        const response = await axiosClient.delete(`/api/offices/deletecoadministrator`, {
            data: deleteCoAdminParams
        });
        return response;
    } catch (error) {
        error.message = `HTTP error! ${error.message}`;
        console.error(error.message);
        throw error;
    };
}