export default {
  nl: {
    title: "Beheer Mandatentoepassing",
    explanation:
      "Selecteer in de lijst hieronder de beheerder van de mandatentoepassing en klik op bevestigen. Deze persoon kan enkel een bestuurder zijn. Contacteer publicregister@itaa.be indien de bestuurder niet in de lijst voorkomt.",
    search: "Zoeken",
    column_one: "Naam",
    column_two: "ITAA Hoedanigheid",
    column_three: "Officieel Type",
    access_button: "Toegang verlenen tot mandatenbeheer",
    verify: "Bevestigen",
    verify_information:
      "Opgelet: indien u een nieuwe mandatenbeheerder toewijst, zult u geen toegang meer hebben tot deze pagina.",
    modal_title_add: "Nieuw persoon toevoegen aan kantoor",
    modal_input_description: "Naam persoon",
    modal_text:
      "U kan enkel de personen toevoegen die gekend zijn in de databank van het ITAA. Indien de medewerker niet in de lijst staat, kan u een aanvraag doen via deze <a href='https://s.chkmkt.com/?e=192574&h=042721A8171AE57&l=nl'>link</a>. Voor uw bijkomende vragen kan u steeds bij de service desk terecht: servicedesk@itaa.be Gelieve na te vragen of deze persoon effectief toegang heeft verkregen tot de Tools.",
    modal_cancel: "Annuleren",
    modal_add: "Toevoegen",
    modal_success_title: "SUCCES",
    modal_success_text:
      "Persoon werd aan de lijst toegevoegd. Het kan enige tijd duren alvorens uw wijzigingen zichtbaar zijn op het portaal.",
    modal_success_close: "Sluiten",
  },
  fr: {
    title: "Gestion de l'application des Mandats",
    explanation:
      "Sélectionnez l'administrateur de l’outil mandat dans la liste ci-dessous et cliquez sur confirmer. Cette personne ne peut qu’être un gérant. Contactez publicregister@itaa.be si le gérant n'est pas dans la liste.",
    search: "Rechercher",
    column_one: "Nom",
    column_two: "Qualité ITAA",
    column_three: "Type officiel",
    access_button: "+ Accorder l'accès à la gestion des mandats",
    verify: "Confirmer",
    verify_information:
      "Attention : si vous affectez un nouveau gestionnaire de mandat, vous n'aurez plus accès à cette page.",
    modal_title_add: "Ajouter un nouveau membre au cabinet",
    modal_input_description: "Nom de la personne",
    modal_text:
      "Vous ne pouvez ajouter que les personnes répertoriées dans la base de données de l'ITAA. Si le collaborateur ne figure pas dans la liste, vous pouvez faire une demande via ce <a href='https://s.chkmkt.com/?e=192574&h=042721A8171AE57&l=nl'>lien</a>. Pour toute question supplémentaire, vous pouvez toujours contacter le service desk : servicedesk@itaa.be. Veuillez vérifier si cette personne a effectivement obtenu l'accès aux outils.",
    modal_cancel: "Annuler",
    modal_add: "Ajouter",
    modal_success_title: "SUCCÈS",
    modal_success_text:
      "La personne a été ajoutée à la liste. Il peut s'écouler un certain temps avant que vos modifications ne soient visibles sur le portail.",
    modal_success_close: "Fermer",
  },
};
