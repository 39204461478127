import React, { useState,useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { patchNetworkDetail } from '../http/HttpClientHelper';
import AutocompleteResults from '../AutoComplete/AutoComplete';


const Add = ({ show, handleClose, natPersonId, existingNetworks }) => {
    const { t } = useTranslation();
    const [networkName, setNetworkName] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [validationError, setValidationError] = useState(null);
    
    const handleCloseSuccess = () => {
        handleClose();
        setIsSuccess(false);
        window.location.reload();

    };

    const handleNetworkNameChange = (event) => {
        if (networkName !== event.target.value) {
            setNetworkName(event.target.value);
        }
        setIsClickedResult(false);
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedNetwork(selectedResult);
        setNetworkName(selectedResult.networkName);
        setIsClickedResult(true);
    }

    const addNetwork = async () => {
        setIsConfirmModalVisible(true);
        setLoading(true);
    
        // Check if selectedNetwork already exists in existingNetworks
        const isDuplicate = existingNetworks.some(item => item.networkName === selectedNetwork.networkName);

        if (isDuplicate) {
            setValidationError(t('network.duplicateError'));
            setLoading(false);
            setIsConfirmModalVisible(false);
            return;
        }
    
        const detailsToAdd = {
            naturalPersonId: natPersonId,
            networkName: networkName,
            networkId: selectedNetwork.networkId,
        };
    
        await patchNetworkDetail(natPersonId, detailsToAdd);
        setIsSuccess(true);
        setLoading(false);
        setIsConfirmModalVisible(false);
    };
            
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await addNetwork();
      };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
                  <Modal class="modal-popup" show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                    </Modal.Footer>
                </Modal>
            {isSuccess ? (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('network.addNetwork')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('network.description')}</small>
                        <br /><br />
                        <Form>
                            <Form.Group controlId="formNetworkName">
                                <Form.Label>
                                    {t('network.name')} <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={networkName}
                                    onChange={handleNetworkNameChange}
                                    isInvalid={!!validationError}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationError}
                                </Form.Control.Feedback>
                                {networkName.length > 0 && !isClickedResult && (
                                    <AutocompleteResults
                                        inputValue={networkName}
                                        handleSelection={handleAutocompleteSelection}
                                        legalPersonId={natPersonId}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" onClick={handleClose}>
                            {t('network.cancel')}
                        </Button>
                        <Button
                            className="btn-eloket"
                            variant="secondary"
                            onClick={handleSubmit}
                        >
                            {t('network.add')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default Add;