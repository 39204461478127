class ExistingUserToolAccess {
    constructor({
        legalPersonId,
        selectedToolIds,
        naturalPersonId,
    }) {
        this.legalPersonId = legalPersonId;
        this.selectedToolIds = selectedToolIds;
        this.naturalPersonId = naturalPersonId;
    }
}

export default ExistingUserToolAccess;