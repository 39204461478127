import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import SimpleSidebar from "./SimpleSidebar";
import { useMediaQuery } from 'react-responsive';
import { Outlet } from "react-router-dom";
import Navbar from './Main/Navbar/Navbar';

const SidebarLayout = ({userDetail, updateUserDetail}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isSidebarOpen, setSidebarOpen] = useState(isMobile ? false : true);

  useEffect(() => {
    isSidebarOpen && isMobile ? document.querySelector('.main-content').style.display = "none" : document.querySelector('.main-content').style.display = "block";
  }, [isSidebarOpen, isMobile]);

  return (
    <div className="wrapper">
      <SimpleSidebar
        isSidebarOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
        isMobile={isMobile}
      />
      <div className="main-content">
        <ToastContainer />
        <Navbar
            userDetail={userDetail}
            updateUserDetail={updateUserDetail}
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
            isMobile={isMobile}
        />
        <Outlet context={{ isMobile }} />
      </div>
    </div>
  );
};

export default SidebarLayout;