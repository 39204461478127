import axiosClient from "./../../../../../axiosClient";

export const getMyDetails = async (userId) => {
  const url = `/api/Profiles/${userId}/mydetails`;

  try {
    const response = await axiosClient
      .get(url);
    if (response.status=== response.error) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`HTTP request failed: ${error.message}`);
  }
};

export const patchMyDetails = async (userId, detailsToUpdate) => {
  const url = `/api/Profiles/${userId}/mydetails`;

  try {
    const response = await axiosClient
      .patch(url, detailsToUpdate);
    if (response.status === response.error) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`HTTP request failed: ${error.message}`);
  }
};
