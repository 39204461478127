import React from 'react';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

const ErrorBoundary = ({ children }) => {
  const { t } = useTranslation();

  const history = createBrowserHistory();
  const toggleRefLink = () => {
    // Ga terug naar de vorige URL als deze bestaat, anders ga naar de homepage
    history.go(-1);
  };

  return (
    <div className="page-wrap d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">OOPS!</span>
            <div className="mb-4 lead">Er is iets foutgelopen </div>
            <button className="btn-error" onClick={toggleRefLink}>
              <i className="fa fa-arrow-left"></i>&nbsp;{t('sidebar.previousPage')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
