import axiosClient from "./../../../../../axiosClient";

export const getMyDetails = (userId) => {
    return axiosClient
        .get(`/api/Profiles/${userId}/mydetails`)
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};

export const getItaaDetails = (userId) => {
    return axiosClient
        .get(`/api/Profiles/${userId}/itaadetails`)
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const patchMyDetails = (userId, detailsToUpdate) => {
    return axiosClient
        .patch(`/api/Profiles/${userId}/mydetails`, detailsToUpdate)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};

export const patchNationalNumber = async (userId, nationalNumber) => {
    try {
        const response = await axiosClient
            .patch(`/api/Profiles/${userId}/nationalnumber`, nationalNumber, {
                headers: {
                    'X-Skip-Error-Toast': 'true' 
                }
            });
        return response.data;
    }
    catch (error) {
        return '500';
    }
}

export const getCountries = () => {
    return axiosClient
        .get(`/api/common/countries`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};
export const getCities = () => {
    return axiosClient
        .get(`/api/common/cities`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};
export const getRegisteredOffice = async (Id, Language) => {
    return axiosClient
        .get(`/api/profiles/${Id}/kbo?language=${Language}`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};
