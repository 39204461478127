import React, { useState, useEffect } from 'react';
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "../Main/Navbar/Navbar";
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';

const ELoketLayout = ({ userDetail, updateUserDetail }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [isSidebarOpen, setSidebarOpen] = useState(isMobile ? false : true);

    useEffect(() => {
        isSidebarOpen && isMobile ? document.querySelector('.main-content').style.display = "none" : document.querySelector('.main-content').style.display = "block";  
    }, [isSidebarOpen, isMobile]);

    return (
      <div className="wrapper">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
          isMobile={isMobile}
          userDetail={userDetail}
        />
        <div className="main-content">
          <ToastContainer />
          <Navbar
            userDetail={userDetail}
            updateUserDetail={updateUserDetail}
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
            isMobile={isMobile}
          />
          <Outlet context={{ userDetail, isMobile }} />
        </div>
      </div>
    );
};

export default ELoketLayout;