import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Navbar from './Translations/Navbar';
import LandingPage from './Translations/LandingPage';
import Popup from './Translations/Popup';
import Card from './Translations/Card';
import Impersonation from './Translations/Impersonation';
import Sidebar from './Translations/Sidebar';
import OfficeRoles from './Translations/OfficeRoles';
import Groups from './Translations/Groups';
import Data from './Translations/Data';
import Insurance from './Translations/Insurance';
import PersonalData from './Translations/PersonalData';
import ItaaData from './Translations/ItaaData';
import BasicData from './Translations/BasicData';
import CompanyItaaData from './Translations/CompanyItaaData';
import BeExcellent from './Translations/BeExcellent';
import MandateApplication from './Translations/MandateApplication';
import OfficeComposition from './Translations/OfficeComposition';
import MainContact from './Translations/MainContact';
import CoAdministrator from './Translations/CoAdministrator';
import QualityResponsible from './Translations/QualityResponsible';
import AmlOfficers from './Translations/AmlOfficers';
import AccessTools from './Translations/AccessTools';
import Clientele from './Translations/Clientele';
import DirectorAndPartner from './Translations/DirectorAndPartner';
import Network from './Translations/Network';
import InfoMessage from './Translations/InfoMessage';
import ItaaTypes from './Translations/ItaaTypes';
import Qualifications from './Translations/Qualifications';
import TrackPhaseTypes from './Translations/TrackPhaseTypes';
import Quality from './Translations/Quality';
import GeneralAssembly from './Translations/GeneralAssembly';
import MandateModal from './Translations/MandateModal';
import EstoxModal from './Translations/EstoxModal';
import Missions from './Translations/Missions';
import Confirmation from './Translations/Confirmation';
import Errors from './Translations/Errors';
import Validation from './Translations/Validation';
import Role from './Translations/Role';
import ResetPassword from './Translations/ResetPassword';
import NavCompaniesDetail from './Translations/NavCompaniesDetail';
import CompanyMainContact from './Translations/CompanyMainContact';
import CompanyGeneralData from './Translations/CompanyGeneralData';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: "nl",
        debug: true,
        interpolation: {
            escapeValue: false,
        },

        resources: {
            nl: {
                translation: {
                    navbar: Navbar.nl,
                    landingpage: LandingPage.nl,
                    popup: Popup.nl,
                    card: Card.nl,
                    impersonation: Impersonation.nl,
                    sidebar: Sidebar.nl,
                    groups: Groups.nl,
                    office_roles: OfficeRoles.nl,
                    data: Data.nl,
                    insurance: Insurance.nl,
                    personaldata: PersonalData.nl,
                    itaadata: ItaaData.nl,
                    basicdata: BasicData.nl,
                    companyitaadata: CompanyItaaData.nl,
                    be_excellent: BeExcellent.nl,
                    mandate_application: MandateApplication.nl,
                    office_composition: OfficeComposition.nl,
                    main_contact: MainContact.nl,
                    co_administrator: CoAdministrator.nl,
                    quality_responsible: QualityResponsible.nl,
                    aml_officers: AmlOfficers.nl,
                    access_tools: AccessTools.nl,
                    clientele: Clientele.nl,
                    directorAndPartner: DirectorAndPartner.nl,
                    network: Network.nl,
                    infomessage: InfoMessage.nl,
                    itaatypes: ItaaTypes.nl,
                    qualifications: Qualifications.nl,
                    trackphasetypes: TrackPhaseTypes.nl,
                    quality: Quality.nl,
                    generalAssembly: GeneralAssembly.nl,
                    mandateModal: MandateModal.nl,
                    estoxModal: EstoxModal.nl,
                    missions: Missions.nl,
                    confirmation: Confirmation.nl,
                    errors: Errors.nl,
                    validation: Validation.nl,
                    role: Role.nl,
                    navCompaniesDetail: NavCompaniesDetail.nl,
                    companyMainContact: CompanyMainContact.nl,
                    CompanyGeneralData: CompanyGeneralData.nl,
                    resetPassword: ResetPassword.nl,
                },
            },
            fr: {
                translation: {
                    navbar: Navbar.fr,
                    landingpage: LandingPage.fr,
                    popup: Popup.fr,
                    card: Card.fr,
                    impersonation: Impersonation.fr,
                    sidebar: Sidebar.fr,
                    groups: Groups.fr,
                    office_roles: OfficeRoles.fr,
                    data: Data.fr,
                    insurance: Insurance.fr,
                    personaldata: PersonalData.fr,
                    itaadata: ItaaData.fr,
                    basicdata: BasicData.fr,
                    companyitaadata: CompanyItaaData.fr,
                    be_excellent: BeExcellent.fr,
                    mandate_application: MandateApplication.fr,
                    office_composition: OfficeComposition.fr,
                    main_contact: MainContact.fr,
                    co_administrator: CoAdministrator.fr,
                    quality_responsible: QualityResponsible.fr,
                    aml_officers: AmlOfficers.fr,
                    access_tools: AccessTools.fr,
                    clientele: Clientele.fr,
                    directorAndPartner: DirectorAndPartner.fr,
                    network: Network.fr,
                    infomessage: InfoMessage.fr,
                    itaatypes: ItaaTypes.fr,
                    qualifications: Qualifications.fr,
                    trackphasetypes: TrackPhaseTypes.fr,
                    quality: Quality.fr,
                    generalAssembly: GeneralAssembly.fr,
                    mandateModal: MandateModal.fr,
                    estoxModal: EstoxModal.fr,
                    missions: Missions.fr,
                    confirmation: Confirmation.fr,
                    errors: Errors.fr,
                    validation: Validation.fr,
                    role: Role.fr,
                    navCompaniesDetail: NavCompaniesDetail.fr,
                    companyMainContact: CompanyMainContact.fr,
                    CompanyGeneralData: CompanyGeneralData.fr,
                    resetPassword: ResetPassword.fr,
                },
            },
        },
    });

export default i18n;