import axiosClient from "../../../../../axiosClient";
export const getCompanyDetails = async (legalPersonId) => {
  try {
    const response = await axiosClient
      .get(`/api/companies/${legalPersonId}/details`);
    if (response.status !== 200 && response.status !== 204) {
      throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`HTTP request failed: ${error.message}`);
  }
};

export const patchMyDetails = (legalPersonId, detailsToUpdate) => {
  return axiosClient
    .patch(`/api/companies/${legalPersonId}/details`, detailsToUpdate)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const getOffice = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/detail`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
