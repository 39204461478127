import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompanyMobileNavigation = ({legalPersonId}) => {
    const { t } = useTranslation();

    const muCompanyItaaData = [
        {
            url: `/eloket/company/basicdata/general/${legalPersonId}`,
            title: t('basicdata.general'),
            text: t('data.text'),
            isCertificate: false,
            newTab: false,
        },
        {
            url: `/eloket/company/itaadata/details/${legalPersonId}`,
            title: t('sidebar.itaaData'),
            text: '',
            isCertificate: false,
            newTab: false,
        }
    ];

    return (
        <div className="container" id="main">
            <Row className='row-m-1'>
                {muCompanyItaaData.map((data, index) => (
                    <Col key={index} md={4}>
                        <Link to={data.url}>
                            <Card className="h-100 custom-card">
                                <Card.Body>
                                    <Card.Title>{data.title}</Card.Title>
                                    <Card.Text>{data.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CompanyMobileNavigation;
