import NewUser from "../../../../../models/naturalPerson/NewUser"
import axiosClient from "../../../../../axiosClient";
import showToastMessage from "../../../../Toast/ToastMessage";
import i18next from "i18next";

export const postNewUser = async (user) => {
    if (user instanceof (NewUser)) {
        return await axiosClient
            .post(`/api/employees/create`, user);
    } else {
        showToastMessage(i18next.t('errors.addUser'));
    }
};