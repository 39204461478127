export default {
  nl: {
    title: "Beheer van het abonnement",
    subText: "Beheer van het abonnement.",
    information_form:
      "Gelieve beide velden in te vullen. Indien u vragen heeft kan u mailen naar <a href='mailto:helpdesk@beexcellent.be'>helpdesk@beexcellent.be</a>.",
    dropdown_label: "Taal van de inhoud (handboeken)",
    placeholder: "Selecteer",
    dutch: "Nederlands",
    french: "Frans",
    daf: "Nederlands / Frans",
    be_excellent_admin: "BeExcellent Beheerder",
    info_url:
      "U mag een URL voorstellen. Dit wordt dan: https://uwvoorstel.beexcellent.be Opgepast: dit is een voorstel en kan, indien niet mogelijk, nog door de helpdesk BeExcellent worden aangepast.",
    warning:
      "Vooraleer u kan bevestigen, dient u de algemene voorwaarden te aanvaarden door het vakje aan te vinken.",
    verify: "Bevestigen",
    subscription_changelanguage:
      "Gelieve contact op te nemen met het Instituut indien u de taal van het abonnement wenst te wijzigen.",
    info_subscription: "Abonnement Informatie",
    book_language: "Taal van de inhoud (handboeken): ",
    license_code: "Licentiecode: ",
    license_letter: "Licentiebrief: ",
    version: "Versie: ",
    owner_subscription: "BeExcellent beheerder: ",
    terms_and_conditions: "Algemene voorwaarden",
    accepted_on: "aanvaard op: ",
    termsBeExcellent: "terms_BeExcellentNL",
    licenseBeExcellent: "license_BeExcellentNL",
    confidentialityBeExcellent: "confidentiality_ResponsibleNL",
    info: "Info",
    open: "Openen",
    request: "Uw aanvraag",
    not_allowed: "Enkel lid {{adminFullName}} kan deze aanvraag aanpassen.",
    language_100000000: "Frans",
    language_100000001: "Nederlands",
    language_100000002: "Nederlands/Frans",
    version_100000000: "Start",
    version_100000001: "Business",
    version_100000002: "Premium",
    licenceletter_url: process.env.REACT_APP_URL_DOCUMENT_BEEXCELLENT_LICENCELETTER_NL,
    termsconditions_url: process.env.REACT_APP_URL_DOCUMENT_BEEXCELLENT_TERMSCONDTIONS_NL,
    processing: "De aanvraag is in behandeling.",
    label_none: 'U hebt nog geen aanvraag gedaan.',
    label_processing: 'In behandeling',
    label_completed: 'Voltooid',
  },
  fr: {
    title: "Gestion de l'abonnement",
    subText: "Gestion de l'abonnement.",
    information_form:
      "Veuillez remplir les deux champs. Si vous avez des questions, vous pouvez envoyer un e-mail à <a href='mailto:helpdesk@beexcellent.be'>helpdesk@beexcellent.be</a>.",
    dropdown_label: "Langue du contenu (manuels)",
    placeholder: "Sélectionner",
    dutch: "Néerlandais",
    french: "Français",
    daf: "Néerlandais / Français",
    be_excellent_admin: "Administrateur BeExcellent",
    info_url:
      "Vous pouvez proposer une URL. Cela deviendra alors : https://votreproposition.beexcellent.be Attention : ceci est une proposition et peut être modifié par le helpdesk BeExcellent s'il n'est pas possible.",
    warning:
      "Avant de pouvoir confirmer, vous devez accepter les conditions générales en cochant la case.",
    verify: "Confirmer",
    subscription_changelanguage:
      "Veuillez contacter l'Institut si vous souhaitez modifier la langue de l'abonnement.",
    info_subscription: "Informations sur l'abonnement",
    book_language: "Langue du contenu (manuels) : ",
    license_code: "Code de licence : ",
    license_letter: "Lettre de licence : ",
    version: "Version : ",
    owner_subscription: "Administrateur BeExcellent : ",
    terms_and_conditions: "Conditions Générales",
    accepted_on: "acceptées le : ",
    termsBeExcellent: "terms_BeExcellentFR",
    licenseBeExcellent: "license_BeExcellentFR",
    confidentialityBeExcellent: "confidentiality_ResponsibleFR",
    info: "Info",
    open: "Ouvrir",
    request: "Votre demande",
    not_allowed: "Seul le membre {{adminFullName}} peut modifier cette demande.",
    language_100000000: "Français",
    language_100000001: "Néerlandais",
    language_100000002: "Néerlandais/Français",
    version_100000000: "Start",
    version_100000001: "Business",
    version_100000002: "Premium",
    licenceletter_url: process.env.REACT_APP_URL_DOCUMENT_BEEXCELLENT_LICENCELETTER_FR,
    termsconditions_url: process.env.REACT_APP_URL_DOCUMENT_BEEXCELLENT_TERMSCONDTIONS_FR,
    processing: "La demande est en cours de traitement.",
    label_none: 'Vous n\'avez pas encore effectué de demande.',
    label_processing: 'En cours',
    label_completed: 'Complétée',
  },
};
