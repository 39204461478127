import React, { useState, useEffect } from 'react';
import { formatCompanyName, formatItaaNumber, formatLabelWithColon, formatShortDateAsLocalDate } from '../../../config/formatUtils';
import { getOffice } from './http/HttpClientHelper';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import ClientelInfoCircle from './ClientelInfoCircle/ClientelInfoCircle';
import { LegalPersonTypeEnum } from '../../../Enums/LegalPersonTypeEnum';
import CompanyMobileNavigation from '../NavigationPages/companies/CompanyMobileNavigation';
import { EntityType } from '../../../Enums/EntityType';

const LegalPersonInfoHeader = ({ isMobile, userDetail, legalPersonId, legalPersonType }) => {
    const [userCompany, setUserCompany] = useState({});
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedLegalPersonId, setSelectedLegalPersonId] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            if (legalPersonId) {
                setLoading(true);
                const office = await getOffice(legalPersonId);
                const updatedCompany = {
                    isManagementCompany: office.companyType?.name,
                    office,
                    mainContact: office.mainContacts[0]?.value || '-',
                    coAdmins: office.coAdministrators || [],
                };
                setUserCompany(updatedCompany);
                setLoading(false);
            }
        };

        fetchData();
    }, [userDetail, legalPersonId]);

    const handleShow = (legalPersonId) => {
        setSelectedLegalPersonId(legalPersonId);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const LoadingIndicator = () => (
        <div className="loader"></div>
    );

    return (
        <div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("clientele.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <b>{userCompany.office?.companyName}</b>
                        <br />
                        <b>{t('main_contact.main_contact')}</b>
                        {userCompany.mainContact}
                        <br />
                    </>
                    <ClientelInfoCircle
                        legalPersonId={selectedLegalPersonId}
                        userDetail={userDetail}
                        handleCloseModal={handleClose} // Pass the handleClose function as a prop
                    />
                    <p></p>
                </Modal.Body>
            </Modal>

            {legalPersonType === LegalPersonTypeEnum.Office ? (
                <a
                    href={`/eloket/office/${legalPersonId}`}
                    className="text-h1"
                    style={{ marginTop: 5, marginBottom: 0 }}
                >
                    <i className="fa-solid fa-circle-arrow-right"></i>
                    {formatCompanyName(userCompany.office?.companyName)}
                </a>
            ) : (

                <div>
                    <a
                        href={`/eloket/company/basicdata/general/${legalPersonId}`}
                        className="text-h1"
                        style={{ marginTop: 5, marginBottom: 0 }}
                    >
                        <i className="fa-solid fa-circle-arrow-right"></i>
                        {formatCompanyName(userCompany.office?.companyName)}
                    </a>

                </div>
            )}

            {!loading && (
                <div className="form-row officeDeterminationText">
                    {userCompany.isManagementCompany === LegalPersonTypeEnum.Office ? (
                        <div onClick={() => handleShow(legalPersonId)} dangerouslySetInnerHTML={{ __html: t("clientele.textIsOffice") }} />
                    ) : (
                        <div onClick={() => handleShow(legalPersonId)} dangerouslySetInnerHTML={{ __html: t("clientele.textIsNoOffice") }} />
                    )}
                </div>
            )}

            <div className="form-group">
                <div className="form-row row">
                    {loading ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                <i className="fa fa-building mr-2"></i>
                                {formatCompanyName(userCompany.office?.companyName)}
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                <i className="fa fa-id-card mr-2"></i>
                                {formatLabelWithColon(t('data.detailsConfirmed'), formatShortDateAsLocalDate(userCompany.office?.dataVerifiedOn))}
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                <i className="fa fa-id-card mr-2"></i>
                                {formatLabelWithColon(
                                    t('data.itaaNumber'),
                                    userCompany.office?.entityType?.id === EntityType.EPP
                                        ? formatItaaNumber(userDetail?.itaaNumber)
                                        : formatItaaNumber(userCompany.office?.itaaNumber)
                                )}
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                <i className="fa fa-envelope mr-2"></i>
                                {(userCompany.office?.emails[0]?.value || '-')}
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                {formatLabelWithColon(t('main_contact.main_contact'), userCompany.mainContact)}
                            </div>
                            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                {formatLabelWithColon(
                                    t('co_administrator.CoAdminShortText'),
                                    userCompany.coAdmins?.length > 0
                                        ? userCompany.coAdmins?.map(coAdmin => coAdmin.value).join(' | ')
                                        : null
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {(isMobile && legalPersonType === LegalPersonTypeEnum.Company) && (
                <CompanyMobileNavigation
                    legalPersonId={legalPersonId}
                />
            )}

        </div>
    );
};

export default LegalPersonInfoHeader;