export default {
  nl: {
    directorTitleELP: "Bestuurder(s)",
    directorTitleEPP: "Oprichter van een geregistreerde entiteit-natuurlijk persoon",
    directorSubtitle:
      "Deze lijst geeft een overzicht weer van de bestuurders van deze onderneming.",
    activePartnerTitle: "Actieve venno(o)t(en)",
    activePartnerSubtitle:
      "Deze lijst geeft een overzicht weer van de natuurlijke personen en vennootschappen die actieve vennoot zijn in de vennootschap.",
    passivePartnerTitle: "Passieve venno(o)t(en)",
    passivePartnerSubtitle:
      "Dit zijn vennoten die niet actief werken in de vennootschap.",
    name: "Naam",
    personType: "Type",
    itaaCapacity: "Itaa Hoedanigheid",
    officalType: "Officeel Type",
    shares: "Aandelen",
    votingRights: "Stemrechten",
    noDetail: "Geen gegevens beschikbaar",
    text: "Overzicht van bestuurders en actieve venoten.",
    add: "Vennoot toevoegen",
    addDescription: "Selecteer een persoon uit de lijst om deze als vennoot toe te voegen aan de onderneming. Indien de persoon nog niet gekend is bij het ITAA, kies dan voor 'Nieuw contact' en vul de gegevens voor deze persoon in.",
    newContact: "Nieuw contact",
    newContactNationalityWarning: "Nieuw contact toevoegen kan enkel voor Belgische rijksregisternummers. Als u een andere nationaliteit wil toevoegen, neem dan contact op met het ITAA.",
  },
  fr: {
    directorTitleELP: "Bestuurder(s)",
    directorTitleEPP: "Fondateur d'une entité enregistrée personne physique",
    directorSubtitle:
      "Cette liste donne un aperçu des administrateurs de cette entreprise.",
    activePartnerTitle: "Associé(s) actif(s)",
    activePartnerSubtitle:
      "Cette liste donne un aperçu des personnes physiques et morales qui sont des associés actifs dans l'entreprise.",
    passivePartnerTitle: "Associé(s) passif(s)",
    passivePartnerSubtitle:
      "Ce sont des associés qui ne travaillent pas activement dans l'entreprise.",
    name: "Nom",
    personType: "Type",
    itaaCapacity: "Qualité ITAA",
    officialType: "Type officiel",
    shares: "Actions",
    votingRights: "Droits de vote",
    noDetail: "Aucune donnée disponible",
    text: "Aperçu des administrateurs et associés actifs.",
    add: "Ajouter un associé",
    addDescription: "Sélectionnez une personne dans la liste pour l'ajouter en tant qu'associé à l'entreprise. Si la personne n'est pas encore connue de l'ITAA, choisissez \"Nouveau contact\" et saisissez les informations de cette personne.",
    newContact: "Nouveau contact",
    newContactNationalityWarning: "L'ajout d'un nouveau contact est possible uniquement pour les numéros de registre national belges. Pour toute autre nationalité, veuillez contacter l'ITAA.",
  },
};
