import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { searchMainContact } from '../http/HttpClientHelper';
import { formatItaaNumber } from '../../../../../config/formatUtils';

const AutocompleteResults = ({ inputValue, handleSelection, legalPersonId }) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchResults = async () => {
            setLoading(true);
            let result = await searchMainContact(legalPersonId, inputValue).finally(() => {
                setLoading(false);
            });
            result = result?.sort((a, b) => a.contact.fullName.localeCompare(b.contact.fullName));
            setResults(result)
        };
        fetchResults();
    }, [inputValue, legalPersonId]);

    const handleItemClick = async (result) => {
        await handleSelection(result.contact);
    };

    return (
        <ListGroup className="clickable">
            {loading && (
                <ListGroup.Item className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </ListGroup.Item>
            )}
            {results.map((result) => (
                <ListGroup.Item key={result.contact.naturalPersonId} onMouseDown={() => handleItemClick(result)}>
                    {result.contact.fullName}{result.contact.city && `, (${result.contact.city})`} #{formatItaaNumber(result.contact.itaaNumber)}
                </ListGroup.Item>
            ))}
            {!loading && results.length === 0 && (
                <ListGroup.Item>Geen resultaten gevonden</ListGroup.Item>
            )}
        </ListGroup>
    );
};

export default AutocompleteResults;