export default {
  nl: {
    successUpdate: "De gegevens werden succesvol bijgewerkt",
    successAdd: "De gegevens werden succesvol toegevoegd",
    verifyChange: "Bent u zeker dat u deze gegevens wilt bijwerken?",
    cancel:"Nee",
    confirm:"Ja",
    ErrorUpdate:
      "Er ging iets fout bij het bewerken van de gegevens, probeer opnieuw",
    ErrorAdd:
      "Er ging fout mis bij het opslaan van de gegevens, probeer opnieuw",
  },
  fr: {
    successUpdate: "Les données ont été mises à jour avec succès",
    verifyChange: "Êtes-vous sûr de vouloir mettre à jour ces données ?",
    succes: "Les données ont été traiter avec succès",
    cancel:"Non",
    confirm:"oui",
    ErrorUpdate:
      "Une erreur s'est produite lors de la mise à jour des données. Veuillez réessayer.",
    ErrorAdd:
      "Une erreur s'est produite lors de l'enregistrement des données. Veuillez réessayer.",
  },
};
