import { useTranslation } from "react-i18next";

const OfficialTypeTableCell = ({ item, isMobile }) => {
    const { t } = useTranslation();

    return (
        <>
            {isMobile ? (
                <div className='pill-badge-primary'>
                    {item.officialTypes && item.officialTypes.length > 0
                        ? item.officialTypes.map(type =>
                            t(`trackphasetypes.${type.name}`)
                        ).join(' | ')
                        : ' - '}
                </div>
            ) : (
                <td>
                    <div>
                        {item.officialTypes && item.officialTypes.length > 0
                            ? item.officialTypes.map(type =>
                                t(`trackphasetypes.${type.name}`)
                            ).join(' | ')
                            : ' - '}
                    </div>
                </td>
            )}
        </>
    );
};

export default OfficialTypeTableCell;