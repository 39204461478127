export default {
  nl: {
    title: "Gegevens bij ITAA",
    itaaNumber: "ITAA-nummer",
    companyNumber: "Ondernemingsnummer",
    legalForm: "Rechtsvorm",
    language: "Taal",
    dateOfIncorporation: "Oprichtingsdatum",
    totalShares: "Totaal aantal aandelen",
    text: "Raadpleeg erkenningsdatum, ondernemingsnummer, oprichtingsdatum",
    no_mention: "Geen melding",
    qualificationSince: 'Hoedanigheid sinds',
    mentionSince: "Melding sinds",
    qualifications: {
      Recognisedlegalperson: 'Erkend rechtspersoon',
      Recognisedlegalpersontrainee: 'Erkend rechtspersoon stagiair'
    },
    legalforms: {
      SCE: 'SCE',
      PENSIO: 'OFP',
      'VAT-GR': 'BTWE',
      CSUL: 'CVOA',
      CSULPS: 'CVOACD',
      CSLL: 'CVBA',
      CSLLPS: 'CVBA CD',
      PLIMLC: 'EBVBA',
      GENPAR: 'VOF',
      OLP: 'Comm.V',
      LTDPRTN:"CommV",
      PLTDSH: 'Comm.VA',
      PLIMCO: 'NV',
      'Pr LLC': 'BVBA',
      CSOR: 'CV (OS)',
      NPROF: 'VZW',
      PUBLUT: 'Inst OpN',
      HEALTH: 'ZIEK F',
      PROFUN: 'B Ver.',
      MUTINS: 'OVV',
      ISCORG: 'IWO BR',
      PFA: 'BPV',
      AGRICO: 'LV',
      PRIVFO: 'PS',
      SE: 'SE',
      NPROFI: 'IZW',
      PUBLUF: 'SON',
      FORENT: 'BU ENT',
      CONCOM: 'KONG VEN',
      OTHPRI: 'PRV',
      EIG: 'ESV',
      EEABE: 'EESV',
      COOWN: 'VME',
      CSULPL: 'CVOA PR',
      CSULPP: 'CVOA CD',
      CSLLPL: 'CVBA PR',
      CSLLPP: 'CVBA CD',
      SPRC: 'RPGG',
      PLIMCO_PR: 'NV PR',
      CSPLOR: 'CV PR',
      PNPASS: 'VZW PR',
      MUTINS: 'OVV PR',
      PROFCO: 'BV ORDEN',
      PUBINS: 'OI',
      INPASS: 'IVZW',
      PAWN: 'BVB',
      CULT: 'EREDIENS',
      POLDER: 'PW',
      OTHER: 'AV',
      FPUORG: 'BIPO',
      COFORM: 'VV',
      CSUNCS: 'BV CVOA',
      CSLLCS: 'BV CVBA',
      CSCOLS: 'BV VOF',
      CSLPAR: 'BV GCV',
      CSLPS: 'BV CVA',
      CSPLCO: 'BV NV',
      CSPLLC: 'BV BVBA',
      EUPOLP: 'EUPP',
      EUPOLF: 'EUPS',
      CSAGCO: 'BV LV',
      FEPIBLP: 'BEOGBRP',
      FEWOUVAT: 'BEZBVBTW',
      ECASBE: 'ESV VB',
      EEAWOH: 'EESV VB',
      FPS: 'FOD',
      FPPS: 'POD',
      OTHFS: 'FEDEDIEN',
      FLEMRC: 'VLGEWGEM',
      WALLRA: 'WG',
      INPASSPL: 'IVZW PR',
      BRUSCR: 'BRUHOOGE',
      FRENCH: 'FRAGEMEE',
      GERMAN: 'DUITGEME',
      MINECO: 'ECOZAKEN',
      MINFOR: 'BZ',
      MINAGR: 'LANDMIN',
      MINMC: 'MIDDENST',
      MINRW: 'VERKEER',
      MINDEF: 'MD',
      MINEDU: 'MNOC',
      MINEMP: 'MTA',
      MINFIN: 'MINFIN',
      MINHAF: 'MBZ',
      MINJUS: 'MINJUST',
      MINSWF: 'MSV',
      MINHEA: 'MVG',
      SPRMIN: 'FIRSTMIN',
      MINTIN: 'MVI',
      MINFLC: 'MVG',
      MINFRC: 'MFG',
      MINBCR: 'MBHG',
      MINWAR: 'MWG',
      MINGSC: 'MDG',
      MINSER: 'MAZ',
      MINSEA: 'MML',
      MINSAH: 'MSZVL',
      MISC: 'ANDERE',
      PROVAU: 'PROVOVER',
      NSSOLR: 'ORG PPO',
      MUNICIPALITY: 'GEMEENTE',
      PSOACT: 'OCMW',
      LOCPOL: 'LOKPOL',
      INTERC: 'IC',
      PROJAS: 'PROJ V',
      SEPRAS: 'DIENSTV',
      REPRAS: 'OPDRAVER',
      AUMUCO: 'AUTOGEMB',
      AUPRCO: 'AUTOPRB',
      CPASAS: 'Ver.OCMW',
      PREZON: 'PRZ',
      HULPVZ: 'HVZ',
      ORGONP: 'RVP',
      ORGPUP: 'OIAP',
      FCOWHE: 'BOZBVGB',
      FEPBE: 'BEOGBE',
      CSULSO: 'CVOA SO',
      CSLLSO: 'CVBA SO',
      ULPCSO: 'EBVBA SO',
      'GP SO': 'VOF SO',
      LTDPSO: 'GCW SO',
      LTPSSO: 'CVA SO',
      PLCSO: 'NV SO',
      PRLCSO: 'BVBA SO',
      'EIG SO': 'ESV SO',
      CSULSO_2004: 'CVOA SO',
      CSLLSO_2004: 'CVA SO',
      PLC: 'BV',
      'LTD PRTN': 'CommV',
      PLTDSO: 'NV SO',
      'PLC PL': 'BV PR',
      'LP PL': 'CommV PR',
      OTHISO: 'VORM SO',
      UNLCC: 'OMHV',
      COMLCO: 'MS',
      TEMPCO: 'TH',
      INTCO: 'SH',
      CS: 'CV',
      CSPL: 'CV PR',
      CAWOLP: 'VVZRL',
      TEMPAS: 'TV',
      EXPASS: 'KV',
      TUNION: 'VAKBOND',
      MIWOLP: 'DZRL',
      UNNSSO: 'OUTRSZ',
    }
  },
  fr: {
    title: "Les données à l'ITAA",
    itaaNumber: "Numéro d'ITAA",
    companyNumber: "Numéro d'entreprise",
    legalForm: "Forme juridique",
    language: "Langue",
    dateOfIncorporation: "Date de constitution",
    totalShares: "Nombre total des parts",
    text: "Consultez la date d'admission, le numéro d'entreprise, la date de constitution",
    no_mention: "Pas de mention",
    qualificationSince: 'Qualité depuis',
    mentionSince: "Mention depuis",
    qualifications: {
      Recognisedlegalperson: 'Société reconnue',
      Recognisedlegalpersontrainee: 'Société reconnue stagiaire'
    },
    legalforms: {
      LTDPRTN:"CommV",
      SCE: 'SCE',
      PENSIO: 'OFP',
      'VAT-GR': 'UTVA',
      CSUL: 'SCRI',
      CSULPS: 'SCRISCP',
      CSLL: 'SCRL',
      CSLLPS: 'SCRL CP',
      PLIMLC: 'SPRLU',
      GENPAR: 'SNC',
      OLP: 'SCS',
      PLTDSH: 'SCA',
      PLIMCO: 'SA',
      'Pr LLC': 'SPRL',
      CSOR: 'SC (AS)',
      NPROF: 'ASBL',
      PUBLUT: 'EtUtPub',
      HEALTH: 'MUTU',
      PROFUN: 'UP',
      MUTINS: 'AAM',
      ISCORG: 'OSI DB',
      PFA: 'AEP',
      AGRICO: 'S. Agr.',
      PRIVFO: 'FP',
      SE: 'SE',
      NPROFI: 'ISBL',
      PUBLUF: 'FUP',
      FORENT: 'ENT E',
      CONCOM: 'SOC CONG',
      OTHPRI: 'FDP PJ',
      EIG: 'GIE',
      EEABE: 'GEIE',
      COOWN: 'ACP',
      CSULPL: 'SCRI DPU',
      CSULPP: 'SCRI CP',
      CSLLPL: 'SCRL DPU',
      CSLLPP: 'SCRL CP',
      SPRC: 'EPRC',
      PLIMCO_PR: 'SA DPU',
      CSPLOR: 'SC DPU',
      PNPASS: 'ASBL DPU',
      MUTINS: 'SAM DPU',
      PROFCO: 'CP ORDRE',
      PUBINS: 'ETSPUBLI',
      INPASS: 'AISBL',
      PAWN: 'PIETE',
      CULT: 'TEMPCULT',
      POLDER: 'PW',
      OTHER: 'AFJ',
      FPUORG: 'OPEI',
      COFORM: 'SF',
      CSUNCS: 'SC SCRI',
      CSLLCS: 'SC SCRL',
      CSCOLS: 'SC SNC',
      CSLPAR: 'SC SCS',
      CSLPS: 'SC SCA',
      CSPLCO: 'SC SA',
      CSPLLC: 'SC SPRL',
      EUPOLP: 'PPEU',
      EUPOLF: 'FPEU',
      CSAGCO: 'SC SAGR',
      FEPIBLP: 'EEBIBPJ',
      FEWOUVAT: 'EESEBTVA',
      ECASBE: 'GIE EB',
      EEAWOH: 'GEIE EB',
      FPS: 'SPF',
      FPPS: 'SPP',
      OTHFS: 'SERVFEDE',
      FLEMRC: 'REGCOMNL',
      WALLRA: 'RW',
      INPASSPL: 'AISBL DPU',
      BRUSCR: 'REGBRXCA',
      FRENCH: 'COMMFRAN',
      GERMAN: 'COMGERMA',
      MINECO: 'AFFECO',
      MINFOR: 'AE',
      MINAGR: 'MINAGRI.',
      MINMC: 'MOYENNES',
      MINRW: 'MINCOMM',
      MINDEF: 'MD',
      MINEDU: 'MENC',
      MINEMP: 'MET',
      MINFIN: 'MINFIN',
      MINHAF: 'MININT',
      MINJUS: 'MINJUST',
      MINSWF: 'MPS',
      MINHEA: 'MSPF',
      SPRMIN: 'FIRSTMIN',
      MINTIN: 'MCI',
      MINFLC: 'MCF',
      MINFRC: 'MCF',
      MINBCR: 'MRBC',
      MINWAR: 'MRW',
      MINGSC: 'MCG',
      MINSER: 'MFP',
      MINSEA: 'MCMA',
      MINSAH: 'MASSPE',
      MISC: 'DIVERS',
      PROVAU: 'AUTOPROV',
      NSSOLR: 'ORGAPL',
      MUNICIPALITY: 'COMMUNES',
      PSOACT: 'CPAS',
      LOCPOL: 'POLLOC',
      INTERC: 'IC',
      PROJAS: 'APROJ',
      SEPRAS: 'ASS SERV',
      REPRAS: 'ACMISS',
      AUMUCO: 'RCOMAUT',
      AUPRCO: 'RPROVAUT',
      CPASAS: 'Ass.CPAS',
      PREZON: 'PRZ',
      HULPVZ: 'ZDS',
      ORGONP: 'ONP',
      ORGPUP: 'OIAP',
      FCOWHE: 'SESEBCB',
      FEPBE: 'EEBIBE',
      CSULSO: 'SCRI FS',
      CSLLSO: 'SCRL FS',
      ULPCSO: 'SPRLU FS',
      'GP SO': 'SNC FS',
      LTDPSO: 'SCS FS',
      LTPSSO: 'SCA FS',
      PLCSO: 'SA FS',
      PRLCSO: 'SPRL FS',
      'EIG SO': 'GIE FS',
      CSULSO_2004: 'SCRI FS',
      CSLLSO_2004: 'SCRL FS',
      PLC: 'SRL',
      'LTD PRTN': 'SComm',
      PLTDSO: 'SA FS',
      'PLC PL': 'SRL DPU',
      'LP PL': 'ScommDPU',
      OTHISO: 'FORME FS',
      UNLCC: 'SCI',
      COMLCO: 'SDC',
      TEMPCO: 'SM',
      INTCO: 'SI',
      CS: 'SC',
      CSPL: 'SC DPU',
      CAWOLP: 'SASPJ',
      TEMPAS: 'AM',
      EXPASS: 'AFRAIS',
      TUNION: 'SYNDIC',
      MIWOLP: 'DSPJ',
      UNNSSO: 'OUTONSS',
    }
  },
};
