export default {
    nl: {
        title: "Hoofdcontactpersoon",
        noResults: "Geen resultaten",
  },
    fr: {
        title: "Personne de contact principale",
        noResults: "Aucun résultat",
  },
};
