export default {
  nl: {
    defaultTitle: "Algemene vergadering",
    not_possible: {
      message:
        "Inschrijven voor de algemene vergadering is op dit moment niet mogelijk.",
      cancel: "Sluiten",
    },
  },
  fr: {
    defaultTitle: "Assemblée générale",
    not_possible: {
      message:
        "L'inscription à l'assemblée générale n'est actuellement pas possible.",
      cancel: "Fermer",
    },
  },
};
