export default {
    nl: {
        title: "Kwaliteitsverantwoordelijke",
        quality_assessment: "Kwaliteitstoetsing",
        explanation:
            "Contactpersoon m.b.t. de kwaliteitstoetsing t.o.v. het ITAA: dit dient beroepsbeoefenaar te zijn.",
        responsible_assessment: "Verantwoordelijke kwaliteitstoetsing",
        header_name: "Naam",
        header_qualification: "ITAA Hoedanigheid",
        header_type: "Officieel Type",
        disclaimer: "Disclaimer",
        text: "Aanstelling contactpersoon m.b.t. de kwaliteitstoetsing t.o.v. het ITAA.",
        warning:
            "Enkel de hoofdcontactpersoon kan deze gegevens wijzigen.",
        add: "Toevoegen",
        add_modal_title: "Voeg een kwaliteitsverantwoordelijke toe",
        add_not_available: "Indien u een kwaliteitsverantwoordelijke wil toevoegen, moet u eerst de bestaande kwaliteitsverantwoordelijke verwijderen.",
        modal_input_description: "U kan enkel personen toevoegen die gekend zijn in de databank van het ITAA (enkel extern lid).",
        download_pdf: "Download disclaimer"
    },
    fr: {
        title: "Responsable qualité",
        quality_assessment: "Revue qualité",
        explanation:
            "Personne de contact pour la revue de qualité vis-à-vis de l'ITAA: il doit s'agir d'un professionnel.",
        responsible_assessment: "Responsable revue qualité",
        header_name: "Nom",
        header_qualification: "Qualité ITAA",
        header_type: "Type officiel",
        disclaimer: "Clause de non-responsabilité",
        text: "Désignation du contact responsable de la revue qualité vis- à - vis de l’ITAA.",
        warning:
            "Seule la personne de contact principale peut modifier ces données.",
        add: "Ajouter",
        add_modal_title: "Ajouter un responsable qualité",
        add_not_available: "Si vous souhaitez ajouter un responsable qualité, vous devez d'abord supprimer le responsable qualité existant.",
        modal_input_description: "Vous ne pouvez ajouter que des personnes figurant dans la banque de données de l'ITAA (membre externe uniquement).",
        download_pdf: "Download clause de non-responsabilité"
    },
};
