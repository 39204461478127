import React from 'react';
import { Form } from 'react-bootstrap';

const Validation = ({ errors }) => {
    return (
        <>
            {Object.keys(errors).map((key) => (
                <>
                    <Form.Text className="text-danger" key={key}>
                        {errors[key]}
                    </Form.Text>
                    <br />
                </>
            ))}
        </>
    );
};

export default Validation;
