export default {
  nl: {
    OngoingAssessments: "Lopende toetsingen",
    ArchivedAssessments: "Gearchiveerde toetsingen",
    Documentation: "Documentatie",
    DocumentationLink: process.env.REACT_APP_URL_DASHBOARDDOCUMENTATION_NL,
    DashboardLink: process.env.REACT_APP_URL_DASHBOARD,
    DashboardArchiveLink: process.env.REACT_APP_URL_DASHBOARD + '/Overview/Archive',
  },
  fr: {
    OngoingAssessments: "Revues en cours",
    ArchivedAssessments: "Revues archivées",
    Documentation: "Documentation",
    DocumentationLink: process.env.REACT_APP_URL_DASHBOARDDOCUMENTATION_FR,
    DashboardLink: process.env.REACT_APP_URL_DASHBOARD,
    DashboardArchiveLink: process.env.REACT_APP_URL_DASHBOARD + '/Overview/Archive',
  },
};
