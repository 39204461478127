import axiosClient from "./../../../../../axiosClient";

export const getQualityData = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/qualityresponsible`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const deletePersonQualityResponsible = (
  legalPersonId,
  naturalPersonId
) => {
  return axiosClient
    .delete(`/api/offices/${legalPersonId}/qualityresponsible/${naturalPersonId}`)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const addPersonQualityResponsible = (legalPersonId, naturalPersonId) => {
  return axiosClient
    .post(`/api/offices/${legalPersonId}/qualityresponsible/${naturalPersonId}`)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const searchQualityResponsibleCandidates = async (search) => {
  const response = await axiosClient
    .get(`api/offices/qualityresponsible/search/${search}`);
  return response.data;
};