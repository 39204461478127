import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getAntiMoneyLaunderingData, postAntiMoneyLaunderingData, getAntiMoneyLaunderingRolesData, deleteAntiMoneyLaunderingRolesData } from './http/HttpClientHelper';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import { getMainContactOrCoAdmin } from '../MainContact/http/HttpClientHelper';
import Add from './Add/Add';
import { Modal, Button } from 'react-bootstrap';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import QualificationTableCell from '../../MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../MembershipInformation/OfficialTypeTableCell';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const AntiMoneyLaunderingOfficers = ({ userDetail, isMobile }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [officeDetails, setOfficeDetails] = useState(null);
    const [showExtraFieldset, setShowExtraFieldset] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [antiMoneyLaunderingResultData, setAntiMoneyLaunderingResultData] = useState([]);
    const [moreThan10State, setMoreThan10State] = useState(null);
    const [amlcoIsvhpnState, setAmlcoIsvhpnState] = useState(null);
    const [antiMoneyLaunderingPatchObject, setAntiMoneyLaunderingPatchObject] = useState({
        hasMoreThan10Employees: false,
        amlcOisVPHN: false
    });
    const [antiMoneyLaunderingRolesData, setAntiMoneyLaunderingRolesData] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [patchDataChanged, setPatchDataChanged] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSubmitted, setIsSubmited] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        setLoadingRoles(true);
        window.location.reload();
    };

    useEffect(() => {
        const fetchOfficeDetails = async () => {
            const data = await getOffice(legalPersonId);
            setOfficeDetails(data);
            const moneyData = await getAntiMoneyLaunderingData(legalPersonId);
            setAntiMoneyLaunderingResultData(moneyData);
            setLoadingRoles(true);
            const rolesData = await getAntiMoneyLaunderingRolesData(legalPersonId);
            setAntiMoneyLaunderingRolesData(rolesData);

            var currentUserIsMainContactOrCoAdmin = await getMainContactOrCoAdmin(legalPersonId, userDetail.id);
            setShowWarning(!currentUserIsMainContactOrCoAdmin.isMainContactOrCoAdmin);
            setLoadingRoles(false);
        };
        fetchOfficeDetails().finally(() => setLoadingRoles(false));
    }, [legalPersonId]);

    useEffect(() => {
        setMoreThan10State(antiMoneyLaunderingResultData?.hasMoreThan10Employees || false);
        setShowExtraFieldset(!antiMoneyLaunderingResultData.hasMoreThan10Employees || false);
        setAmlcoIsvhpnState(antiMoneyLaunderingResultData.amlcOisVPHN || false);
    }, [antiMoneyLaunderingResultData]);

    useEffect(() => {
        if (patchDataChanged) {
            patchAntiMoneyDataFunc(antiMoneyLaunderingPatchObject);
            setPatchDataChanged(false);
        }
    }, [patchDataChanged, antiMoneyLaunderingPatchObject]);

    const handleRadioChangeFirstYes = () => {
        setShowExtraFieldset(false);
        setIsSubmited(true);
        setMoreThan10State(true);
        setAmlcoIsvhpnState(false);
    };

    const handleRadioChangeFirstNo = () => {
        setIsSubmited(true);
        setShowExtraFieldset(true);
        setMoreThan10State(false);
    };

    const handleRadioChangeSecondYes = () => {
        setIsSubmited(true);
        setAmlcoIsvhpnState(true);
    };

    const handleRadioChangeSecondNo = () => {
        setAmlcoIsvhpnState(false);
        setIsSubmited(true);
    };

    const patchAntiMoneyDataFunc = async (data) => {
        await postAntiMoneyLaunderingData(legalPersonId, data);
    };

    const handleSaveButton = () => {
        setAntiMoneyLaunderingPatchObject({
            hasMoreThan10Employees: moreThan10State,
            amlcOisVPHN: amlcoIsvhpnState
        });

        setPatchDataChanged(true);
        setIsSuccess(true);
        setLoadingRoles(false);
        setIsSubmited(false);
    };

    const handleDeleteClick = (index, id) => {
        setDeleteIndex(index);
        setDeleteId(id);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmDelete = async () => {
        if (deleteId !== null) {
            await deleteAntiMoneyLaunderingRolesData(deleteId);
            const updatedRolesData = [...antiMoneyLaunderingRolesData];
            updatedRolesData.splice(deleteIndex, 1);
            setAntiMoneyLaunderingRolesData(updatedRolesData);
            setDeleteIndex(null);
            setDeleteId(null);
            setIsConfirmModalVisible(false);
        }
    };

    const handleCancelDelete = () => {
        setDeleteIndex(null);
        setDeleteId(null);
        setIsConfirmModalVisible(false);
    };

    if (!officeDetails) {
        return null;
    }

    return (
        <div id="main">
            {loadingRoles && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            {isConfirmModalVisible && (
                <Modal show={isConfirmModalVisible} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleCancelDelete(false)}>
                            {t("confirmation.cancel")}
                        </Button>
                        <Button variant="primary" className="btn-eloket" onClick={handleConfirmDelete}>
                            {t("confirmation.confirm")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Office}
                />
                <div className="text-h1">
                    {t('aml_officers.title')}
                    <i className="fa fa-info-circle info-format" title={t('aml_officers.title_information')}></i>
                </div>
                <section>
                    <div className="form-group">
                        {showWarning && (
                            <div className='form-row'>
                                <div className='alert alert-danger mb-0'>
                                    <span>{t('office_composition.warningMainContact')}</span>
                                </div>
                            </div>
                        )}
                        <div className="form-row">
                            <h5>
                                {t('aml_officers.size_office')}
                                <i className="fa fa-info-circle info-format" title={t('aml_officers.size_office_information')}></i>
                            </h5>
                            <span className="text-description">{t('aml_officers.option_one_explanation')}</span>
                        </div>
                        <fieldset>
                            {antiMoneyLaunderingResultData && (
                                <div className='flex-div'>
                                    <div className='mr-2'>
                                        <input
                                            type='radio'
                                            name="size_office"
                                            value="true"
                                            checked={moreThan10State}
                                            onChange={handleRadioChangeFirstYes} />
                                        {t('aml_officers.yes')}
                                    </div>
                                    <div className='mr-2'>
                                        <input
                                            type='radio'
                                            name="size_office"
                                            value="false"
                                            checked={!moreThan10State}
                                            onChange={handleRadioChangeFirstNo} />
                                        {t('aml_officers.no')}
                                    </div>
                                </div>
                            )}
                        </fieldset>
                        <br />

                        {showExtraFieldset && (
                            <div>
                                <div className="form-row">
                                    <span className="text-description">{t('aml_officers.option_two_explanation')}</span>
                                </div>
                                <fieldset>
                                    <div className='flex-div'>
                                        <div className='mr-2'>
                                            <input
                                                type='radio'
                                                name="function"
                                                value="true"
                                                checked={amlcoIsvhpnState}
                                                onChange={handleRadioChangeSecondYes}
                                            />
                                            {t('aml_officers.yes')}
                                        </div>
                                        <div className='mr-2'>
                                            <input
                                                type='radio'
                                                name="function"
                                                value="false"
                                                checked={!amlcoIsvhpnState}
                                                onChange={handleRadioChangeSecondNo}
                                            />
                                            {t('aml_officers.no')}
                                        </div>
                                    </div>
                                </fieldset>
                                <br />
                            </div>
                        )}
                        <div className="form-row table-wrapper">
                            <table className="table">
                                <thead className='moneylaundrey-table-head'>
                                    <tr>
                                        <th>{t('aml_officers.header_name')}</th>
                                        {!isMobile && (
                                            <>
                                                <th>{t('aml_officers.header_qualification')}</th>
                                                <th>{t('aml_officers.header_type')}</th>
                                                <th>{t('aml_officers.header_function')}</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {antiMoneyLaunderingRolesData.map((role, index) => (
                                        <tr key={index}>
                                            <td>{role.naturalPerson.name}</td>
                                            {isMobile ? (
                                                <td className="badges-office-composition">
                                                    <QualificationTableCell item={role} isMobile={isMobile} />
                                                    <OfficialTypeTableCell item={role} isMobile={isMobile} />
                                                </td>
                                            ) : (
                                                <>
                                                    <QualificationTableCell item={role} isMobile={isMobile} />
                                                    <OfficialTypeTableCell item={role} isMobile={isMobile} />
                                                    <td>{t(`aml_officers.${role.role}`)}</td>
                                                </>
                                            )}
                                            <td className='lastColumn'>
                                                {!showWarning && (
                                                    <button className='btn btn-eloket' onClick={() => handleDeleteClick(index, role.id)}>
                                                        {!isMobile ? (
                                                            <>
                                                                {t('aml_officers.button_delete')}
                                                            </>
                                                        ) : (
                                                            <i className='fa fa-trash'></i>
                                                        )}
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                {showAddModal && (
                    <Add
                        show={showAddModal}
                        handleClose={() => setShowAddModal(false)}
                        legalPersonId={legalPersonId}
                        antiMoneyLaunderingRolesData={antiMoneyLaunderingRolesData}
                    />
                )}

                {!showWarning && (
                    <footer>
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="submit" onClick={handleSaveButton} disabled={!isSubmitted}>{t('aml_officers.save_button')}</button>
                            <button className="btn btn-eloket" type="submit" disabled={isSubmitted} onClick={() => setShowAddModal(true)}>{t('aml_officers.add')}</button>
                            {isSubmitted && <div className="save-warning"> {t("aml_officers.notSaved")} </div>}
                        </div>
                    </footer>
                )}
            </div>
        </div>
    );
};

export default AntiMoneyLaunderingOfficers;
