import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getMandateLink, getMandateCompanies } from './http/HttpClientHelper';
import { checkLoginWithEIdItsMe } from '../../../../config/tokenUtils';

const Mandate = ({ show, handleClose, userDetail }) => {
    const { t } = useTranslation();
    const [offices, setOffices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOfficeId, setSelectedOfficeId] = useState('');
    const [canOpenMandates, setCanOpenMandates] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setCanOpenMandates(checkLoginWithEIdItsMe());

            var companies = await getMandateCompanies(userDetail.id);
            setOffices(companies);
            setLoading(false);
        })();
    }, []);

    const handleSelectChange = (event) => {
        setSelectedOfficeId(event.target.value);
    };

    const handleConfirm = () => {
        getMandateLink(selectedOfficeId)
            .then((link) => {
                window.open(link.mandateLink, '_blank');
                handleClose();
            });
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {canOpenMandates ? (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandateModal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t('mandateModal.text1')}</p>
                        <p>{t('mandateModal.text2')}</p>
                        <select className='form-control' onChange={handleSelectChange} value={selectedOfficeId}>
                            <option value="">{t('mandateModal.selectTitle')}</option>
                            {offices.map(office => (
                                <option key={office.legalPersonId} value={office.legalPersonId}>
                                    {office.name}
                                </option>
                            ))}
                        </select>
                        <p>{t('mandateModal.text3')}</p>
                        <p>{t('mandateModal.text4')}</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleConfirm}>
                            {t('confirmation.confirm')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('mandateModal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('mandateModal.bosaLoginRequired')}</p>
                    <p>{t('mandateModal.bosaLoginRequired2')}</p>
                    <ul>
                        <li>{t('mandateModal.mandateLoginStep1')}</li>
                        <li>{t('mandateModal.mandateLoginStep2')}</li>
                        <li>{t('mandateModal.mandateLoginStep3')}</li>
                    </ul>
                </Modal.Body>
            </Modal>
            )}
        </>
    );
};

export default Mandate;