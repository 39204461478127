import axiosClient from "../../../../../axiosClient";

export const getdirectorAndPartnerDetail = (legalPersonId) => {
  return axiosClient
    .get(`/api/companies/${legalPersonId}/partnersanddirectors`)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const searchPartnerCandidates = (legalPersonId, search) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/accesstools/search/${search}`)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const postPartnerCandidates = (legalPersonId, 
                                      naturalPersonId, 
                                      shares, 
                                      votingRights,
                                      firstname,
                                      lastname,
                                      selectedGender,
                                      selectedLanguage,
                                      selectedNationality,
                                      birthdate,
                                      nationalRegistrationNumber
                                    ) => {
  const data = {
    legalPersonId,
    naturalPersonId,
    shares,
    votingRights,
    firstname,
    lastname,
    gender: selectedGender || null,
    language: selectedLanguage || null,
    nationality: selectedNationality || null,
    dateOfBirth: birthdate,
    nationalNumber: nationalRegistrationNumber
  };

  return axiosClient
    .post(`/api/companies/${legalPersonId}/partner`, data)
    .then((response) => {
      if (response.status !== 204) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const getOffice = (legalPersonId) => {
  return axiosClient
    .get(`/api/offices/${legalPersonId}/detail`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
