export default {
  nl: {
    title: "Gebruik van mijn persoonsgegevens",
    personalDataText1:
      "Het ITAA verwerkt persoonsgegevens conform de geldende Europese en Belgische wetgeving inzake de bescherming van de persoonsgegevens alsook de Wet van 17 maart 2019 en het KB van 11 september 2020.",
    personalDataLabel2: "Gebruik van deze gegevens door ITAA",
    personalDataText2:
      "In het kader van haar wettelijke opdracht zullen deze gegevens gebruikt worden om met U te communiceren en U te informeren over:",
    personalSubDataText2:
      "- Informatie over evenementen en seminars georganiseerd door of in samenwerking met het ITAA",
    personalDataLabel3:
      "Doorgifte van bepaalde *professionele persoonsgegevens op basis van een gerechtvaardigd belang",
    personalSubDataLabel3:
      "(*naam/voornaam/taalrol/lidnummer/hoedanigheid/professioneel adres/professioneel mailadres)",
    personalDataText3:
      "Daarenboven heeft het ITAA, in het kader van haar toezichtrol inzake de permanente vorming van de leden en stagiairs, een gerechtvaardigd belang, om voor de organisatie van deze vorming samen te werken met de door het ITAA erkende beroepsverenigingen. Het ITAA zal dan ook op vraag en na opmaak van een verwerkingsovereenkomst bovenvermelde gegevens van haar leden en stagiairs ter beschikking stellen aan deze erkende beroepsverenigingen. Deze gegevens worden enkel ter beschikking gesteld voor het verzenden van uitnodigingen (hetzij papier hetzij digitaal) betreffende het door het ITAA erkende opleidingen of seminaries."
      +"Indien U dit evenwel niet wenst dat uw professionele gegevens hiervoor worden overgemaakt aan de erkende beroepsverenigingen kan U zich hiervoor uitschrijven.",
    personalDataInputField1:
      "Ik wens niet dat bovenvermelde professionele persoonsgegevens worden overgemaakt aan de erkende beroepsverenigingen specifiek om mij uit te nodigen voor seminaries/opleidingen.",
    personalDataText4:
      "Doorgifte van deze professionele persoonsgegevens op basis van uw uitdrukkelijke en steeds herroepbare toestemming",
    personalDataInputField2:
      "Het ITAA mag mijn bovenvermelde professionele persoonsgegevens doorgeven aan erkende beroepsverenigingen met het oog op het verstrekken van beroepsinformatie (vb. ledenblad)",
    personalDataInputField3:
      "Het ITAA mag mijn bovenvermelde professionele persoonsgegevens doorgeven aan nationale (UNIZO, UCM, VOKA, VBO, UWE….) of internationale (IFAC; Accountancy Europe, …) beroepsgerelateerde organisaties alsook aan de regionale en nationale overheidsdiensten met het oog op het verstrekken van informatie (nieuwsbrieven, uitnodiging seminaries en opleidingen …. ).",
    personalDataInputField4:
      "Het ITAA mag mijn bovenvermelde professionele persoonsgegevens doorgeven aan haar commerciële partners met het oog op het verstrekken van informatie en/of commerciële communicatie. Het ITAA zal hiervoor nooit enige vergoeding ontvangen.",
    save: "Opslaan",
    certificateTitle: "Mijn ITAA-certificaat",
    certificateText: "Download of druk uw ITAA-certificaat af.",
    certificateDownloadName: "ITAA-certificaat.pdf",
    certificateDownload: "Download",
    showMobilePhone: "Toon professionele GSM",
    showWebsite: "Toon professionele website",
    officeRolesTitle: "Mijn kantoorrollen",
    officeRolesText:
      "Een overzicht van de rollen die u heeft binnen één of meerdere kantoren.",
    groupsTitle: "Mijn groepen binnen ITAA",
    groupsText: "Een overzicht van de groepen waartoe u behoort binnen ITAA.",
    networksTitle: "Netwerk(en)",
    networksText: "De netwerken waartoe u behoort.",
    usePersonalData:"Gebruik van mijn persoonsgegevens",
    publicProfile: "Uw gegevens die zichtbaar zijn in de zoekmodule.",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen...",
    showPhoneNumberOnPublicSearch: "Ik geef toestemming om mijn mobiele telefoon weer te geven in het publiek register",
    showWebPageOnPublicSearch: "Ik geef toestemming om mijn website weer te geven in het publiek register"

  },
  fr: {
    title: "Utilisation de mes données personnelles",
    personalDataText1:
      "L'ITAA traite les données à caractère personnel conformément à la législation européenne et belge en vigueur en matière de protection des données à caractère personnel ainsi qu'à la loi du 17 mars 2019 et à l'Arrêté royal du 11 septembre 2020.",
    personalDataLabel2: "Utilisation de ces données par l’ITAA",
    personalDataText2:
      "Dans le cadre de sa mission légale, ces données seront utilisées pour communiquer avec vous et pour vous informer sur : ",
    personalSubDataText2:
      "- Informations sur les événements et séminaires organisés par ou en coopération avec l'ITAA",
    personalDataLabel3:
      "Transfert de certaines *données personnelles professionnelles sur la base d'un intérêt justifié ",
    personalSubDataLabel3:
      " (*nom/prénom/rôle linguistique/numéro de membre/qualité /adresse professionnelle/adresse courriel professionnelle)",
    personalDataText3:
      "En outre, dans le cadre de son rôle de supervision de la formation continue de ses membres et stagiaires, l'ITAA a un intérêt légitime à collaborer avec les associations professionnelles agréées par l'ITAA pour organiser cette formation. L'ITAA mettra donc, sur demande et après avoir établi un accord de traitement, les données susmentionnées de ses membres et stagiaires à la disposition de ces associations professionnelles agréées. Ces données ne seront mises à disposition que pour l'envoi d'invitations (papier ou numériques) concernant les formations ou les séminaires agréés par l'ITAA."
      +"Toutefois, si vous ne souhaitez pas que vos données professionnelles soient transmises aux associations professionnelles agréées à cette fin, vous pouvez vous désinscrire en la matière.",
    personalDataInputField1:
      "Je ne souhaite pas que les données personnelles professionnelles susmentionnées soient transférées aux associations professionnelles agréées dans le but spécifique de m'inviter à des séminaires/formations.",
    personalDataText4:
      "Transfert de ces données personnelles professionnelles sur la base de votre consentement exprès et toujours révocable",
    personalDataInputField2:
      "L'ITAA peut transmettre mes données personnelles professionnelles susmentionnées à des associations professionnelles agréées dans le but de fournir des informations professionnelles (par exemple, le magazine des membres).",
    personalDataInputField3:
      "L'ITAA peut communiquer mes données professionnelles personnelles susmentionnées à des organisations professionnelles nationales (UNIZO, UCM, VOKA, VBO, UWE, ...) ou internationales (IFAC, Accountancy Europe, ...) ainsi qu'aux services publics régionaux et nationaux à des fins d'information (lettres d'information, invitation à des séminaires et à des formations, ...).",
    personalDataInputField4:
      "L'ITAA peut transmettre mes données personnelles professionnelles susmentionnées à ses partenaires commerciaux à des fins d'information et/ou de communication commerciale. L'ITAA ne recevra jamais aucune indemnité pour cela.",
    save: "Sauvegarder",
    certificateTitle: "Mon certificat ITAA",
    certificateText:
      "Téléchargez ou imprimez votre certificat ITAA.",
    certificateDownloadName: "Certificat-ITAA.pdf",
    certificateDownload: "Download",
    showMobilePhone: "Afficher le GSM professionnel",
    showWebsite: "Afficher le site web professionnel",
    officeRolesTitle: "Mes rôles au cabinet",
    officeRolesText:
      "Un aperçu des rôles que vous avez au sein d'un ou plusieurs cabinets.",
    groupsTitle: "Mes groupes au sein de l'ITAA",
    groupsText:
      "Un aperçu des groupes auxquels vous appartenez au sein d’ITAA.",
    networksTitle: "Reseau(x)",
    networksText: "Les réseaux auxquels vous appartenez.",
    usePersonalData:"Utilisation de mes données personnelles",
    publicProfile: "Vos données qui apparaissent dans le moteur de recherche.",
    notSaved: "Vos modifications n’ont pas encore été enregistrées...",
    showPhoneNumberOnPublicSearch: "Je donne la permission d'afficher mon numéro de GSM dans le registre public",
    showWebPageOnPublicSearch: "Je donne la permission d'afficher mon site dans le registre public"


  },
};
