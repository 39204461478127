import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { EntityType } from "../../../../Enums/EntityType";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import { FLAGS } from "../../../../hooks/useFlag";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import OfficialTypeTableCell from "../../MembershipInformation/OfficialTypeTableCell";
import QualificationTableCell from "../../MembershipInformation/QualificationTableCell";
import Add from "./add/add";
import {
  getdirectorAndPartnerDetail
} from "./http/HttpClientHelper";
import { PersonType } from "../../../../Enums/PersonType";

const DirectorAndPartner = ({ userDetail, isMobile }) => {
  const { t } = useTranslation();
  const [mydirectorAndPartnerDetail, setMydirectorAndPartnerDetail] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const legalPersonId = pathParts[pathParts.length - 1];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Fetch partner and director details
      const directorAndPartnerDetail = await getdirectorAndPartnerDetail(
        legalPersonId
      ).finally(() => {
        setLoading(false);
      });
      directorAndPartnerDetail.businessManagers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      directorAndPartnerDetail.activeAssociates.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setMydirectorAndPartnerDetail(directorAndPartnerDetail);
    };
    fetchData();
  }, [legalPersonId]);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div id="content">
        <LegalPersonInfoHeader
          legalPersonId={legalPersonId}
          userDetail={userDetail}
          legalPersonType={LegalPersonTypeEnum.Company}
        />
        {/* Business Managers */}
        <div id="table" className="container">
          {mydirectorAndPartnerDetail.entityType?.id !== EntityType.EPP ? (
            <div className="text-h1">{t("directorAndPartner.directorTitleELP")}</div>)
            :
            <div className="text-h1">{t("directorAndPartner.directorTitleEPP")}</div>}
          <div className="form-group">
            {mydirectorAndPartnerDetail.entityType?.id !== EntityType.EPP && (
              <p>{t("directorAndPartner.directorSubtitle")}</p>
            )}
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("directorAndPartner.name")}</th>
                  {!isMobile && (
                    <>
                      <th>{t("directorAndPartner.personType")}</th>
                      <th>{t("directorAndPartner.itaaCapacity")}</th>
                      <th>{t("directorAndPartner.officalType")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {mydirectorAndPartnerDetail.businessManagers &&
                  mydirectorAndPartnerDetail.businessManagers.length > 0 ? (
                  mydirectorAndPartnerDetail.businessManagers.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        {isMobile ? (
                          <td className="badges-office-composition">
                            <QualificationTableCell
                              item={item}
                              isMobile={isMobile}
                            />
                            <OfficialTypeTableCell
                              item={item}
                              isMobile={isMobile}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{t(`itaatypes.${PersonType[item.personType]}`)}</td>
                            <QualificationTableCell
                              item={item}
                              isMobile={isMobile}
                            />
                            <OfficialTypeTableCell
                              item={item}
                              isMobile={isMobile}
                            />
                          </>
                        )}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={isMobile ? 2 : 6}>
                      {t("directorAndPartner.noDetail")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Active Associates */}
        {mydirectorAndPartnerDetail.entityType?.id !== EntityType.EPP && (
          <div id="table" className="container .scrollable-table">
            <div className="text-h1">
              {t("directorAndPartner.activePartnerTitle")}
            </div>
            <div className="form-group">
              <p>{t("directorAndPartner.activePartnerSubtitle")}</p>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t("directorAndPartner.name")}</th>
                    {!isMobile && (
                      <>
                        <th>{t("directorAndPartner.personType")}</th>
                        <th>{t("directorAndPartner.itaaCapacity")}</th>
                        <th>{t("directorAndPartner.officalType")}</th>
                        <th>{t("directorAndPartner.votingRights")}</th>
                        <th>{t("directorAndPartner.shares")}</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {mydirectorAndPartnerDetail.activeAssociates &&
                    mydirectorAndPartnerDetail.activeAssociates.length > 0 ? (
                    mydirectorAndPartnerDetail.activeAssociates.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          {isMobile ? (
                            <td className="badges-office-composition">
                              <QualificationTableCell
                                item={item}
                                isMobile={isMobile}
                              />
                              <OfficialTypeTableCell
                                item={item}
                                isMobile={isMobile}
                              />
                            </td>
                          ) : (
                            <>
                              <td>{t(`itaatypes.${PersonType[item.personType]}`)}</td>
                              <QualificationTableCell
                                item={item}
                                isMobile={isMobile}
                              />
                              <OfficialTypeTableCell
                                item={item}
                                isMobile={isMobile}
                              />
                              <td>{item.numberOfVotingRights ?? "-"}</td>
                              <td>{item.amountOfShares ?? "-"}</td>
                            </>
                          )}
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>{t("directorAndPartner.noDetail")}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {FLAGS.CAN_ADD_PARTNERS && (
          <footer>
            <span onClick={() => setShowAddModal(true)}>
              <button className="btn btn-eloket">
                <span className="fa fa-plus"></span>{" "}
                {t("directorAndPartner.add")}
              </button>
            </span>
          </footer>
        )}
        {FLAGS.CAN_ADD_PARTNERS && showAddModal && (
          <Add
            show={showAddModal}
            legalPersonId={legalPersonId}
            handleClose={() => setShowAddModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default DirectorAndPartner;
