export default {
  nl: {
    title: "Medebeheerder rubriek kanto(o)r(en).",
    titleCompany: "Medebeheerder rubriek onderneming(en).",
    explanation:
      "Kies, indien u dat wenst, een medebeheerder voor de rubriek kanto(o)r(en). Indien de gewenste persoon niet in de keuzelijst voorkomt, contacteer dan servicedesk@itaa.be.",
    checkbox:
      "U, als hoofdcontactpersoon, bent verantwoordelijk voor de acties van de medebeheerder binnen de rubriek kanto(o)r(en).",
    verify: "Bevestigen",
    add: "+Voeg een medebeheerder toe",
    remove: "Verwijder",
    add_modal_title: "Voeg een medebeheerder toe",
    modal_success_title: "Medebeheerder",
    noResults: "Geen resultaten",
    CoAdminShortText: "Medebeheerder",
    add_not_available: "Indien u een medebeheerder wil toevoegen, moet u eerst de bestaande medebeheerder verwijderen.",
  },
  fr: {
    title: "Co-administrateur de la rubrique cabinet(s).",
    titleCompany:"Catégorie société(s) cogérante(s).",
    explanation:
      'Choisissez, si vous le souhaitez, un co-administrateur pour la rubrique "cabinet(s)". Si la personne souhaitée ne figure pas dans la liste, veuillez contacter servicedesk@itaa.be.',
    checkbox:
      'Vous, en tant que personne de contact principale, êtes responsable des actions du co-administrateur au sein de la rubrique "cabinet(s)".',
    verify: "Confirmer",
    add: "+Ajouter un co-administrateur",
    remove: "Supprimer",
    add_modal_title: "Ajouter un co-administrateur",
    modal_success_title: "Co-administrateur",
    noResults: "Aucun résultat",
    CoAdminShortText: "Co-administrateur",
    add_not_available: "Si vous souhaitez ajouter un co-administrateur, vous devez d'abord supprimer le co-administrateur existant.",
  },
};
