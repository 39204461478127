export const getCityName = (city, language) => {
    if (!city)
        return "";

    switch (language) {
        case 'nl':
            return city.dutchName;
        case 'fr':
            return city.frenchName;
        default:
            return city.englishName;
    }
}

export const getCountryName = (country, language) => {
    if (!country)
        return "";

    switch (language) {
        case 'nl':
            return country.dutchName;
        case 'fr':
            return country.frenchName;
        default:
            return country.name;
    }
}