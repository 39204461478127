import showToastMessage from "./components/Toast/ToastMessage";
import i18n from "./i18n";
export const handleApiError = (error, showToast = true) => {
  console.error(`HTTP error! ${error}`);

  let errorMessage = ''; 

  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        errorMessage = i18n.t('errors.Request400');
        break;
      case 401:
        errorMessage = i18n.t('errors.Request401');
        break;
      case 404:
        errorMessage = i18n.t('errors.Request404');
        break;
      case 500:
          errorMessage = i18n.t('errors.Request500');
          break;
      // Voeg meer cases toe voor andere statussen indien nodig
      default:
        errorMessage = `HTTP fout ${error.response.status}: ${error.message}`;
        break;
    }
  }
  if(showToast){
    showToastMessage(errorMessage); 
  }
};
