export default {
  nl: {
    myOffices: "Mijn kantoren",
    myGroups: "Mijn groepen binnen ITAA",
    MyNetworks: "Mijn netwerken",
    MyPublicProfile: "Mijn publiek profiel",
    MyCertificate: "Mijn ITAA certificaten",
  },
  fr: {
    myOffices: "Mes cabinets",
    myGroups: "Mes groupes au sein de l'ITAA",
    MyNetworks: "Mon réseaux",
    MyPublicProfile: "Mon profil public",
    MyCertificate: "Mes certificats ITAA",
  },
};
