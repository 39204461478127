export default {
  nl: {
    close: "Sluiten",
    profile: "Mijn profiel",
    data: "Mijn gegevens",
    personalData: "Gebruik van mijn persoonsgegevens",
    itaaData: "ITAA-gegevens",
    company: "Onderneming(en)",
    basicdata: "Basisgegevens",
    dataAtItaa: "Gegevens bij ITAA",
    directorAndPartner: "Bestuurder(s) en venno(o)t(en)",
    publicprofile: "Publiek profiel",
    network: "Mijn netwerk(en)",
    myNetwork: "Mijn netwerk(en)",
    office: "Kanto(o)r(en)",
    headContactOffice: "Hoofdcontactpersoon",
    mainContact: "Hoofdcontactpersoon",
    coadministrator: "Medebeheerder",
    officeComposition: "Samenstelling kantoor",
    antiMoneyLaunderingResponsibles: "Antiwitwasverantwoordelijken",
    qualityResponsible: "Kwaliteitsverantwoordelijke",
    accessTools: "Toegang tools",
    mandateApplicationManagement: "Beheer mandatentoepassing",
    backToHome: "Terug naar portaal",
    previousPage: "Vorige pagina",
    beExcellent: "BeExcellent",
    missions: "Opdrachten",
    general: "Algemeen",
    publicProfile: "Mijn publiek profiel",
    myPublicProfile: "Mijn publiek profiel",
    certificate: "Mijn ITAA-certificaat",
    myCertificates: "Mijn ITAA-certificaat",
    rolls: "Mijn kantoorrollen",
    contact: "Contactgegevens",
    insurance: "Verzekering burgerlijke beroepsaansprakelijkheid",
    myInsurance: "Verzekering burgerlijke aansprakelijkheid",
    myGroups: "Mijn groepen binnen ITAA",
    companyData: "Gebruik van onderneminggegevens",
    myOfficeRoles: "Mijn kantoorrollen",
    urls:{
      cookies:"https://www.itaa.be/nl/cookieverklaring/",
      privacy:"https://www.itaa.be/nl/privacyverklaring/",
      home:"https://www.itaa.be/nl/home/"
    }
  },
  fr: {
    close: "Fermer",
    profile: "Mon profil",
    data: "Mes coordonnées",
    personalData: "Utilisation de mes données personnelles",
    itaaData: "Données ITAA",
    company: "Entreprise(s)",
    basicdata: "Données de base",
    dataAtItaa: "Données chez l'ITAA",
    directorAndPartner: "Administrateur(s) et associé(s)",
    partnerAndDirector: "Associé(s) et administrateur(s)",
    publicprofile: "Profil public",
    myPublicProfile: "Mon profil public",
    network: "Mon réseau(x)",
    myNetwork: "Mon réseau(x)",
    office: "Cabinet(s)",
    backToHome: "Retour au portail",
    previousPage: "Page précédente",
    headContactOffice: "Personne de contact principale",
    mainContact: "Personne de contact principale",
    coadministrator: "Co-administrateur",
    officeComposition: "Composition du cabinet",
    antiMoneyLaunderingResponsibles: "Responsables antiblanchiment",
    qualityResponsible: "Responsable qualité",
    accessTools: "Accès aux outils",
    mandateApplicationManagement: "Gestion de l'application des mandats",
    beExcellent: "BeExcellent",
    missions: "Missions",
    contact: "Coordonnées",
    insurance: "Assurance responsabilité civile professionnelle",
    myInsurance: "Assurance responsabilité civile",
    general: "Général",
    publicProfile: "Profil public",
    certificate: "Mon certificat ITAA",
    myCertificates: "Mon certificat ITAA",
    rolls: "Rôles",
    myGroups: "Mes groupes au sein de l'ITAA",
    companyData: "Utilisation de données d'entreprise",
    myOfficeRoles: "Mes rôles au sein du cabinet",
    urls:{
      cookies:"https://www.itaa.be/fr/mention-cookies/",
      privacy:"https://www.itaa.be/fr/declaration-de-protection-de-la-vie-privee/",
      home:"https://www.itaa.be/fr/accueil/"
    }
  },
};