export default {
    nl: {
        selectedPersonRequired: 'Naam persoon is een verplicht veld.',
        selectedToolsRequired: 'Tools is een verplicht veld.',
        firstNameRequired: 'Voornaam is een verplicht veld.',
        lastNameRequired: 'Naam is een verplicht veld.',
        emailFormat: 'Vul een geldig email adres in.',
        webpage: 'Vul een geldige webpagina in.',
        genderRequired: 'Geslacht is een verplicht veld.',
        languageRequired: 'Taalrol is een verplicht veld.',
        nationalityRequired: 'Nationaliteit is een verplicht veld.',
        birthdateRequired: 'Geboortedatum is een verplicht veld.',
        nationalRegistrationNumber: 'Onjuist rijksregisternummer.',
        phoneNumber: 'Vul een geldig telefoonnummer in.',
        mandateAdminConflict:'Er is al een mandatenbeheerder (maximum 1) aangeduid. Gelieve eerst de vorige te verwijderen.',
        allRequiredFields: 'Vul alle verplichte velden (*) in.',
        startDateBeforeEndDate: 'Start moet vroeger zijn dan einde.',
        selectRole: 'Duid eerst een functie aan'
    },
    fr: {
        selectedPersonRequired: 'Nom de la personne est un champ obligatoire.',
        selectedToolsRequired: 'Outils est un champ obligatoire.',
        firstNameRequired: 'Prénom est un champ obligatoire.',
        lastNameRequired: 'Nom est un champ obligatoire.',
        emailFormat: 'Mettez une adresse email valide.',
        webpage: 'Mettez une page web valide.',
        genderRequired: 'Sexe est un champ obligatoire.',
        languageRequired: 'Rôle linguistique est un champ obligatoire.',
        nationalityRequired: 'Nationalité est un champ obligatoire.',
        birthdateRequired: 'Date de naissance est un champ obligatoire.',
        nationalRegistrationNumber: 'Numéro de registre national incorrect.',
        phoneNumber: 'Mettez un numéro de téléphone valide.',
        mandateAdminConflict:"Un gestionnaire de mandats (maximum 1) a déjà été désigné. Veuillez d’abord supprimer le précédent.",
        allRequiredFields: 'Remplissez tous les champs obligatoires (*).',
        startDateBeforeEndDate: 'Le début doit être antérieur à la fin.',
        selectRole: "Veuillez d'abord sélectionner un rôle"
    }
}