import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/logo.png";
import user from "../../../assets/default-user.svg";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserProvider } from "../../Auth/UserProvider";
import { getUserCompanies, getUserOffices } from "../../ELoket/Sidebar/http/HttpClientHelper";
import { sidebarPaths } from "../../../config/pathConfig";

const Navbar = ({ onSearch, isMobile, userDetail, isSidebarOpen, setSidebarOpen }) => {
    const [isUserMenuOpen, setUserMenuOpen] = useState(false);
    const [loadingLocalStorage, setLoadingLocalStorage] = useState(true);
    const { t, i18n } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const isImpersonationPage = location.pathname === "/impersonation";
    const isInfoOverview = location.pathname === '/infomessage/overview';
    const isQualityPage = location.pathname === "/quality";
    const isLandingPage = location.pathname === '/';
    const isSidebarPage = sidebarPaths.some(path => location.pathname.includes(path));
    const [isMounted, setIsMounted] = useState(true);

    const { isAuthenticated, loginWithRedirect, stopImpersonation, isLoading, error, handleLogout } = useUserProvider();
    const navigate = useNavigate();

    const handleStopImpersonationClick = () => {
        stopImpersonation(userDetail.itaaEmployeeId);
    }

    useEffect(() => {
        const handleAuthentication = async () => {
            console.log('useEffect triggered with dependencies:', isAuthenticated, isLoading, error);
            console.log('isMounted: ' + isMounted);

            if (error) {
                console.log('Error detected, redirecting to /auth-error page');
                navigate('/auth-error', { state: { errormessage: error } });
                return;
            }

            if (!isLoading && !isAuthenticated) {
                console.log('User is not authenticated. Initiating logout and redirect to login.');
                handleLogout();
                if (isMounted) {
                    console.log('loginWithRedirect');
                    await loginWithRedirect();
                }
            }
        };

        handleAuthentication();

        return () => {
            setIsMounted(false);
        };
    }, [isAuthenticated, isLoading, error]);


    useEffect(() => {
        const handleOfficeCompanies = async () => {
            if (userDetail.id) {
                const offices = localStorage.getItem('offices');
                const companies = localStorage.getItem('companies');
                if (offices || companies) {
                    setLoadingLocalStorage(false);
                }
                else {
                    setLoadingLocalStorage(true);
                    getUserCompanies(userDetail.id);
                    getUserOffices(userDetail.id);
                    setLoadingLocalStorage(false);
                }
            }
        }
        handleOfficeCompanies();
    }, [userDetail]);

    const toggleLanguage = () => {
        if (i18n.language === 'nl') {
            i18n.changeLanguage('fr');
        } else {
            i18n.changeLanguage('nl');
        }
        window.location.reload();
    };

    const toggleUserMenu = (event) => {
        event.stopPropagation();
        setUserMenuOpen(!isUserMenuOpen);
    };
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleSearch = (query, performSearch) => {
        setSearchQuery(query);
        if (!isImpersonationPage || performSearch) {
            onSearch(query);
        }
    };

    const userMenuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
            setUserMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header>
            {(loadingLocalStorage) && (
                <div className="loader-overlay">
                                    <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p>{t('estoxModal.ExternalProviderShareRes.signin')}</p>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
            )}
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid itaa-logo-container">
                    {isSidebarPage ? (
                        <>
                            {isMobile && (
                                <div className="menu">
                                    <button type="button" className="btn btn-eloket" onClick={toggleSidebar}>
                                        <i className="fa fa-align-left"></i>
                                    </button>
                                    <span>{t("navbar.backToMenu")}</span>
                                </div>
                            )}
                        </>
                    ) : (
                        <a className="navbar-brand" href="/">
                            <img className="itaa-logo" src={logo} alt="ITAA Logo" />
                        </a>
                    )}


                    {!isMobile && !isLandingPage && !isInfoOverview && !isQualityPage && !isSidebarPage && (
                        <div className="search-container">
                            <div className="search-bar">
                                <input
                                    className="form-control"
                                    placeholder={t("navbar.search")}
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value, false)}
                                    onKeyDown={(e) => {
                                        if ((e.key === 'Enter' || e.key === 'Backspace') && isImpersonationPage) {
                                            handleSearch(searchQuery, true);
                                        }
                                    }}
                                />
                                {isImpersonationPage ? (
                                    <Button
                                        className="btn btn-search"
                                        onClick={() => handleSearch(searchQuery, true)}
                                    >
                                        <i className="fa fa-search"></i>
                                    </Button>
                                ) : (
                                    <i className="fa fa-search search-icon"></i>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="user-container" onClick={toggleUserMenu} ref={userMenuRef}>

                        <div className={`${isUserMenuOpen ? 'active' : ''}`} onClick={toggleUserMenu} >
                            <div className="user-photo">
                                <img src={user} alt="" />
                            </div>

                            <div className="user-name">
                                {userDetail?.firstName} {userDetail?.lastName}
                            </div>

                            {isUserMenuOpen && (
                                <div className="user-menu">
                                    {isAuthenticated ? (
                                        <>
                                            {userDetail?.isItaaEmployee && (
                                                <>
                                                    <div className="user-menu-item">
                                                        {userDetail.isImpersonated ? (
                                                            <Link href="#" onClick={handleStopImpersonationClick}>
                                                                {t("navbar.impersonation_stop")}
                                                            </Link>
                                                        ) : (
                                                            <Link to="/impersonation">{t("navbar.impersonation")}</Link>
                                                        )}
                                                    </div>

                                                    {(!userDetail.isImpersonated || userDetail.isItaaIT || userDetail.isItaaSRD) && (
                                                        <>
                                                            <div className="user-menu-item">
                                                                <Link to="/reset-password">
                                                                    {t("navbar.changePassword")}
                                                                </Link>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="user-menu-item">
                                                <Link href="#" onClick={loginWithRedirect}>
                                                    {t("navbar.login")}
                                                </Link>
                                            </div>
                                        </>
                                    )}

                                    <div
                                        className="user-menu-item"
                                        onClick={() =>
                                            toggleLanguage()
                                        }
                                    >
                                        <Link href="#">
                                            {i18n.language === "nl"
                                                ? t("navbar.french")
                                                : t("navbar.dutch")}
                                        </Link>
                                    </div>
                                    {isAuthenticated && (
                                        <div className="user-menu-item">
                                            <Link href="#" onClick={handleLogout}>
                                                {t("navbar.logout")}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header >
    );
};

export default Navbar;