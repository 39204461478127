import React from "react";
import InsuranceForm from "./InsuranceForm";
import { useTranslation } from "react-i18next";

function InsuranceFormList({ insurances, hasActiveMembership, handleInputChange }) {
  const { t } = useTranslation();
  return (
    <div>
      {insurances.length > 0 ? (
        insurances.map((insurance, index) => (
          <InsuranceForm
            key={index}
            insurance={insurance}
            handleInputChange={(e) => handleInputChange(e, index)}
            index={index}
            disabled={!hasActiveMembership}
          />
        ))
      ) : (
        <div className='info-text'>
          <span dangerouslySetInnerHTML={{ __html: t('insurance.noInsurance') }}></span>
        </div>
      )}
    </div>
  )
}

export default InsuranceFormList;
