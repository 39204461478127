import axiosClient from "./../../../../../axiosClient";

export const getUserCompanies = (userId) => {
  return axiosClient
    .get(`/api/Profiles/${userId}/companies`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const getUserOffices = (userId) => {
  return axiosClient
    .get(`/api/Profiles/${userId}/offices`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
export const getCompaniesOwnClientele = (companyId) => {
  return axiosClient
    .get(`/api/companies/${companyId}/ownclientele`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
export const getCompaniesAssociate = (companyId) => {
  return axiosClient
    .get(`/api/companies/${companyId}/associate`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
