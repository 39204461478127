import React, { useState, useEffect } from 'react';
import { getEstoxExternalProviderShare } from './http/HttpClientHelper';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const EstoxExternalProviderShare = ({ userDetail }) => {
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [estoxExternalProvider, setEstoxExternalProvider] = useState(null);
    const [selectedEstox, setSelectedEstox] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(true); 
    const [isRedirection, setisRedirection] = useState(false);
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        (async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const callbackParam = urlParams.get('callbackurl');          
            const estoxExternalProviderData = await getEstoxExternalProviderShare(userDetail.id, callbackParam);
            setEstoxExternalProvider(estoxExternalProviderData);
            setIsLoading(false); 
        })();
    }, [userDetail.id]);

    const handleSelectChange = (event) => {
        const selectedId = event.target.value;
        if (estoxExternalProvider?.estox) {
            const selectedEntity = estoxExternalProvider.estox.find(entity => entity.entityId === selectedId);
            setSelectedEstox(selectedEntity);
        }
    };

    const handleAgreementChange = (event) => {
        setIsAgreementChecked(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isAgreementChecked && selectedEstox) {
            setShowConfirmation(true);
        } else {
            setErrors({
                agreement: !isAgreementChecked,
                dropdown: !selectedEstox
            });
        }
    };

    const postToCallbackUrl = () => {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = decodeURIComponent(estoxExternalProvider.callback); 

        const fields = {
            lastname: userDetail.lastName,
            firstname: userDetail.firstName,
            email: userDetail.email,
            externalid: userDetail.id,
            role: estoxExternalProvider.estoxType,
            organisationLastUpdated: selectedEstox.modifiedOn,
            organisationName: selectedEstox.name,
            organisationEntityNumber: selectedEstox.vatNumber,
            organisationid: selectedEstox.clientNumber,
            userid: selectedEstox.clientNumber
        };
        
        Object.keys(fields).forEach(key => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = fields[key];
            form.appendChild(input);
        });
        
        document.body.appendChild(form);
        form.submit();
        setisRedirection(true)
    };
    
    const handleConfirmation = () => {
        setShowConfirmation(false);
        postToCallbackUrl();
    };

    const handleCancelConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <div className="form-group">
            <h1 className="text-center m-2 mb-lg-5">{t('estoxModal.ExternalProviderShareRes.Title')}</h1>

            {isLoading ? ( 
                 <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            ) : !estoxExternalProvider?.externalProvider.isWhitelisted ? ( 
                <div className="alert alert-danger">
                    {t('estoxModal.ExternalProviderShareRes.InvalidUrl')}
                </div>
            ) : (
                <div>
                    <form className="form-overview form-detect-unsaved" onSubmit={handleSubmit}>
                        <p dangerouslySetInnerHTML={{ __html: t('estoxModal.ExternalProviderShareRes.EstoxInformation') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('estoxModal.ExternalProviderShareRes.EstoxConnectionIntro') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('estoxModal.ExternalProviderShareRes.EstoxConnectionNotice') }} />

                        <select 
                            id="connection"
                            className={`form-control ${errors.dropdown ? 'warning-choice' : ''}`}
                            onChange={handleSelectChange} 
                            defaultValue=""
                        >
                            <option value="" disabled>{t('estoxModal.ExternalProviderShareRes.DropdownDefault')}</option>
                            {estoxExternalProvider?.estox?.map((entity) => (
                                <option key={entity.entityId} value={entity.entityId}>
                                    {entity.name} - {entity.vatNumber}
                                </option>
                            ))}
                        </select>

                        <p dangerouslySetInnerHTML={{ __html: t('estoxModal.ExternalProviderShareRes.EstoxConnectionOutro') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('estoxModal.ExternalProviderShareRes.EstoxConnectionBrowserWarning') }} />

                        <input 
                            type="checkbox" 
                            id="share-agreement" 
                            checked={isAgreementChecked}
                            onChange={handleAgreementChange}
                            className={`form-check-input ${errors.agreement ? 'warning' : ''}`} 
                        />
                        <label className="form-check-label flex-prin-checkbox_label" htmlFor="share-agreement">
                            {t('estoxModal.ExternalProviderShareRes.ShareAgreement')}
                        </label>
                        <footer>
                            <button 
                                id="btn-share-ok"
                                type="submit" 
                                className="btn btn-footer btn-footer-right btn-success mt-3"
                                disabled={!isAgreementChecked} 
                            >
                                {t('estoxModal.ExternalProviderShareRes.BtnOk')}
                            </button>
                        </footer>

                    </form>

                </div>
            )}

            {/* Confirmation Popup */}
            {showConfirmation && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p>{t("popup.verifyChange")}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCancelConfirmation}>
                                    {t("confirmation.cancel")}
                                </button>
                                <button type="button" className="btn btn-primary" onClick={handleConfirmation}>
                                    {t("confirmation.confirm")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Loading Popup */}
            {isRedirection && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p>{t('estoxModal.ExternalProviderShareRes.Redirect')}</p>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EstoxExternalProviderShare;
