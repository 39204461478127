import axiosClient from "../../../../../axiosClient";

export const getActiveGeneralAssembly = () => {
  return axiosClient
    .get("/api/GeneralAssemblies/active")
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};