class FunctionsEnum {
    static MandateUser= '20ebf78f-0bfd-4ecc-a92c-c5fac38b9814';
    static EstoxAdmin= 'c577dd1f-c12e-4941-b777-3c47b9ebfb7f';
    static  MandateAdmin= 'a03a4f45-5bcc-465f-847c-ceaec0de8a9d';
    static Estox= 'e6afbd05-7ef9-4f94-80c2-d952658dd4ef';
    static CoAdministrator= '3d6bb835-c8e5-4f18-94cd-f48fc361aa10';
    static BeExcellentAdmin= '9789ab10-bb75-4198-b4ba-808a75ebc249';
    static INIS= '31516e24-abf9-4466-bb41-d4c39ad008b7';

    static getAccessToolFunctions(t) {
        const toolFunctions = [
            { value: FunctionsEnum.MandateUser, label: t('access_tools.Portal_MandateUser') },
            { value: FunctionsEnum.Estox, label: t('access_tools.Portal_Estox') },
            { value: FunctionsEnum.INIS, label: t('access_tools.Portal_INIS') },
            { value: FunctionsEnum.MandateAdmin, label: t('access_tools.Portal_MandateManager') }
        ];
        toolFunctions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        return toolFunctions;
    }
};
export { FunctionsEnum };