export default {
  nl: {
    title: "Heeft uw onderneming eigen cliënteel?",
    textIsNoOffice:
      "Uw onderneming heeft <span style='color: red;'>geen</span> eigen cliënteel. <a href='#' id='add-employee-link'>Klik hier</a></b> indien dit niet correct is.",
      textIsOffice:
      "Uw onderneming heeft eigen cliënteel. <a href='#' id='add-employee-link' style='text-decoration: underline;'>Klik hier</a></b> indien dit niet correct is",    
    yesText:"<b>(JA) Eigen cliënteel</b>  betekent dat deze onderneming prestaties aangaande ITAA-beroepsactiviteiten rechtstreeks levert en/of factureert aan cliënten (wat niet wegneemt dat deze onderneming tevens voor andere erkende ITAA- of IBR- beroepsbeoefenaars kan werken).",
    yes: "Ja",
    NB:"NB: In dat geval zal het niet vereist zijn om een samenstelling kantoor in te vullen, noch om de antiwitwasfuncties AMLCO en Verantwoordelijke persoon op het hoogste niveau in te vullen voor deze onderneming.",
    no: "Nee",
    noText:"<b>(NEEN) Geen eigen cliënteel</b> betekent dat deze onderneming (ITAA-vennootschap of eenmanszaak) enkel prestaties aangaande ITAA-beroepsactiviteiten levert en/of factureert aan 1 of meerdere erkende ITAA- of IBR- beroepsbeoefenaars, zoals bvb. in het geval van een zelfstandige dienstverlener, een management- of bestuursvennootschap.",
    confirm: "Bevestigen",
    close: "Sluiten",

  },
  fr: {
    title: "Cette entreprise a-t-elle sa propre clientèle?",
    textIsNoOffice:
      "Votre entreprise n'a <span style='color: red;'>pas de</span> clientèle propre.<a href='#' id='add-employee-link'> Cliquez ici</a></b> si ce n'est pas le cas.",
    textIsOffice:
      "Votre entreprise a sa propre clientèle. <a href='#' id='add-employee-link'> Cliquez ici</a></b> si ce n'est pas le cas.",
    yes: "Oui",
    yesText:"<b>(OUI) Clientèle propre</b> signifie que cette entreprise fournit et/ou facture des services liés aux activités professionnelles de l'ITAA en direct aux clients (ceci n’exclut pas la possibilité de travailler également pour d'autres professionnels agréés par l’ITAA ou l’IRE). ",
    noText:"<b>(NON) Pas de clientèle propre</b> signifie que cette entreprise (société ITAA ou entreprise unipersonnelle) ne fournit et/ou ne facture des services liés aux activités professionnelles de l’ITAA qu’à un ou plusieurs professionnels agréés par l’ITAA ou l’IRE, comme cela est par exemple le cas d’un prestataire de service indépendant, d'une société de management ou une société d’administration.",
    NB:"N.B. : Dans ce cas, il ne sera pas nécessaire de remplir une composition de cabinet, ni de remplir les fonctions AMLCO et Personne Responsable au plus haut niveau pour cette entreprise.",
    no: "Non",
    confirm: "Confirmer",
    close: "Fermer",
  },
};
