import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { searchBeExcellentData } from '../http/HttpClientHelper';
import { formatItaaNumber } from '../../../../../config/formatUtils';

const AutocompleteResults = ({ inputValue, handleSelection, legalPersonId }) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                if (!mounted) {
                    setLoading(true);
                    const response = await searchBeExcellentData(legalPersonId, inputValue);
                    setResults(response);
                }
                setLoading(false);
            };

            if (inputValue.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => {
            clearTimeout(delaySearch);
            setMounted(false);
        }
    }, [inputValue, legalPersonId]);

    const handleItemClick = async (result) => {
        await handleSelection(result);
        setResults([]);
    };

    return (
        <ListGroup>
            {loading && (
                <ListGroup.Item className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </ListGroup.Item>
            )}
            {!loading && results.map((result) => (
                <ListGroup.Item key={result.naturalPersonId} onMouseDown={() => handleItemClick(result)}>
                    {result.fullName}{result.city && `, (${result.city})`} #{formatItaaNumber(result.itaaNumber)}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default AutocompleteResults;