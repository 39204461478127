import axiosClient from "../../../../axiosClient";

export const getMainContact = async (legalPersonId) => {
  const response = await axiosClient
    .get(`/api/offices/${legalPersonId}/maincontact`);
  return response.data;
};

export const getCoAdmins = async (legalPersonId) => {
  const response = await axiosClient
    .get(`/api/offices/${legalPersonId}/coadministrators`);
  return response.data;
};
export const getOffice = async (legalPersonId) => {
  const response = await axiosClient
    .get(`/api/offices/${legalPersonId}/detail`);
  return response.data;
};
export const getUserCompany = async (userId, legalPersonId) => {
  const response = await axiosClient.get(`/api/Profiles/${userId}/companies`);
  const filteredData = response.data.filter((company) => company.legalPersonId === legalPersonId);
  return filteredData[0];
};

export const getCompaniesOwnClientele = async (companyId) => {
  const response = await axiosClient
    .get(`/api/companies/${companyId}/ownclientele`);
  return response.data;
};

export const patchCompanyDetails = async (legalPersonId, detailsToUpdate) => {
    const response = await axiosClient.patch(
      `/api/companies/${legalPersonId}/details`,
      detailsToUpdate
    );
    return response.data;

};

export const getMyDetails = async (legalPersonId) => {
    const response = await axiosClient
      .get(`/api/companies/${legalPersonId}/ownclientele`);

    return response.data;
};


export const patchMyDetails = async (legalPersonId, detailsToUpdate) => {
    await axiosClient
      .patch(`/api/companies/${legalPersonId}/ownclientele`, detailsToUpdate);
 
};

export const getUserOffices = async (userId) => {
    const response = await axiosClient.get(`/api/Profiles/${userId}/offices`);
    localStorage.setItem("offices", JSON.stringify(response.data));
    return response.data;
};