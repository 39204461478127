import axiosClient from "../../../../../axiosClient"


export const getOfficeRoles = (natPersonId) => {
    return axiosClient
        .get(`/api/profiles/${natPersonId}/myofficeroles`)
        .then((response) => {
            if(!response.status === 200) {
                throw new Error(`HTTP fout! Status: ${response.status}`);
            }
            return response.data;
        })
        .catch((error) => {
            throw new Error(`HTTP request failed: ${error.message}`);
        });
};