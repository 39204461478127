import axiosClient from "./../../../../axiosClient";

export const getUserImpersonations = async (itemsPerPage, pageNumber, searchQuery, qualificationFilter, trackTypeFilter, hasFilter) => {

  const payload = {
    PageNumber: pageNumber,
    PageSize: itemsPerPage,
    Search: searchQuery, 
    QualificationFilter: qualificationFilter,
    TrackTypeFilter: trackTypeFilter,
    HasFilter: hasFilter
  };

  try {
    const response = await axiosClient
      .post('/api/Impersonation', payload);
    if (response.status !== 200) {
      throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`HTTP request failed: ${error.message}`);
  }
};

export const getNaturalPersonTrackTypes = async () => {

  const response = await axiosClient
    .get('/api/common/trackTypes');
  if (response.status !== 200) {
    throw new Error(`trackTypes fout! Status: ${response.status}`);
  }
  return response.data;
};
export const getNaturalPersonQualifications = async () => {

  const response = await axiosClient
    .get('/api/common/qualifications');
  if (response.status !== 200) {
    throw new Error(`HTTP qualifications! Status: ${response.status}`);
  }
  return response.data;

};