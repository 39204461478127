export default {
    nl: {
        title: "Gebruik van bedrijfsgegevens",
        noResults: "Geen resultaten",
        noCompany:'Staat uw bedrijf niet in de lijst? Neem dan contact op met het ITAA  (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>).'
    }, 
    fr: {
        title: "Utilisation des données de l'entreprise",
        noResults: "Aucun résultat",
        noCompany:'Si votre entreprise ne se trouve pas dans la liste, prenez contact avec l’ITAA (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>).'
    }
}