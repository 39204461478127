import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { getNetworkDetail, deleteNetworkDetail } from './http/HttpClientHelper';
import Add from './add/Add';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const Network = ({ userDetail }) => {
    const { t } = useTranslation();
    const [myNetworkDetail, setMyNetworkDetail] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const location = useLocation();
    var pathParts = location.pathname.split('/');
    const legalPersonId = pathParts[pathParts.length - 1];
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);


    useEffect(() => {
        (async () => {
            const networkDetail = await getNetworkDetail(legalPersonId);
            setLoading(false);
            setMyNetworkDetail(networkDetail);
        })();
    }, [legalPersonId]);


    const deleteNetwork = async (selectedNetworkId) => {
        setLoading(true);
        await deleteNetworkDetail(selectedNetworkId);
        const updatedNetworkDetail = await getNetworkDetail(legalPersonId);
        setMyNetworkDetail(updatedNetworkDetail);
        setIsSuccess(true);
        setLoading(false);
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false); // Sluit de modal
    };

    const handleNetworkNameChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredNetworks = myNetworkDetail.networks
        ? myNetworkDetail.networks.filter((item) =>
            item.name?.toLowerCase().includes(searchTerm.toLowerCase())
        ) : [];

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <LegalPersonInfoHeader
                legalPersonId={legalPersonId}
                userDetail={userDetail}
                legalPersonType={LegalPersonTypeEnum.Company}
            />
            <div id="content">
                <div id="table">
                    <div className="text-h1">{t('network.title')}</div>
                    <div className="form-group">
                        <p>{t('network.subtitle')}</p>
                        <div className='form-row'>
                            <div className='form-col'>
                                <div className='controls'>
                                    <Form.Control
                                        className="network-search"
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleNetworkNameChange}
                                    />
                                    <label className='control-label'>{t('network.search')}</label>
                                </div>
                            </div>
                        </div>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>{t('network.name')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredNetworks?.length > 0 ? (
                                    filteredNetworks.sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item, index) => (
                                            <tr key={index}>
                                                <td width="75%">{item.name}</td>
                                                <td className='lastColumn' width="25%">
                                                    <Button
                                                        className="btn btn-danger"
                                                        variant="primary"
                                                        onClick={() => deleteNetwork(item.companyNetworkId)}
                                                    >
                                                        {t('network.delete')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td>{t('network.noDetail')}</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <footer>
                    <div className="button-save-warning-container">
                        <span onClick={() => setShowAddModal(true)}>
                            <button className="btn btn-eloket">
                                <span className="fa fa-plus"></span> {t('network.addNetwork')}
                            </button>
                        </span>
                    </div>
                </footer>
                {showAddModal && (
                    <Add show={showAddModal} handleClose={() => setShowAddModal(false)} legalPersonId={legalPersonId} />
                )}
            </div>
        </>
    );
};

export default Network;
