export default {
  nl: {
    general: "Algemeen",
    email: "E-mailadres",
    website: "Website",
    companyNumber: "Ondernemingsnummer",
    registeredOffice: "Maatschappelijke zetel",
    street: "Straat",
    country: "Land",
    postalCode: "Postcode",
    place: "Plaats",
    telephoneNumber: "Telefoon",
    liabilityCompany: "Verzekering burgerlijke beroepsaansprakelijkheid.",
    insuranceCompany: "Verzekeringsmaatschappij",
    policyNumber: "Polisnummer",
    save: "Opslaan",
    goToProfile: "Ga naar publiek profiel",
    text: "Beheer van e-mail, website, maatschappelijke zetel, verzekering BA",
    noResult: "Geen resultaat",
    registeredOfficeInformation: "De adresgegevens zijn gekoppeld aan de gegevens opgenomen in de KBO.",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen..."

  },
  fr: {
    general: "Général",
    email: "Adresse e-mail",
    website: "Site web",
    companyNumber: "Numéro d'entreprise",
    registeredOffice: "Siège social",
    street: "Rue",
    country: "Pays",
    postalCode: "Code postal",
    place: "Lieu",
    telephoneNumber: "Numéro de téléphone",
    liabilityCompany: "Assurance responsabilité civile professionnelle.",
    insuranceCompany: "Société d'assurance",
    policyNumber: "Numéro de police",
    save: "Sauvegarder",
    goToProfile: "Accéder au profil public",
    text: "Gestion du adresse e-mail, du site web, du siège social, de l'assurance RC.",
    noResult: "Pas de résultat",
    registeredOfficeInformation: "Les coordonnées sont liées aux données reprises dans la BCE.",
    notSaved: "Vos modifications n’ont pas encore été enregistrées..."
  },
};
