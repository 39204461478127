import axiosClient from "../../../axiosClient";

export const loginActionHistory = async (naturalPersonId, actionType) => {
    return axiosClient
        .post(`/api/actionhistories`, { naturalPersonId, actionType })
        .then((response) => {
            if (response.status !== 200 && response.status !== 204) {
                throw new Error(`HTTP fout! Status: ${response.status}`);
            }
            return response.data;
        })
        .catch((error) => {
            throw new Error(`HTTP request failed: ${error.message}`);
        });
};