import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { postPersonAccessTools, searchAccessToolsCandidates } from '../http/HttpClientHelper';
import { formatItaaNumber } from '../../../../../config/formatUtils';
import showToastMessage from '../../../../Toast/ToastMessage';
import AccessToolSelect from './AccessToolSelect';
import ExistingUserToolAccess from '../../../../../models/naturalPerson/ExistingUserToolAccess';
import Validation from '../../../../Validation/Validation';
import { FunctionsEnum } from '../../../../../Enums/FunctionsEnum';
import { ValidateRequiredMultiSelect, ValidateRequiredSingleSelect } from '../../../../../config/validationUtils';

const AddExisting = ({ show, handleClose, legalPersonId, hasMandateUserAdmin }) => {
    const { t } = useTranslation();
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [hideListGroup, setHideListGroup] = useState(false);
    const [loadingAC, setLoadingAC] = useState(false);
    const [results, setResults] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const listGroupRef = useRef(null);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                if (selectedName.trim().length >= 3) {
                    setLoadingAC(true);
                    const response = await searchAccessToolsCandidates(legalPersonId, selectedName);
                    setResults(response);
                }
                setLoadingAC(false);
            };

            fetchAutocompleteResults();
        }, 200);

        return () => clearTimeout(delaySearch);
    }, [selectedName, legalPersonId, selectedTools]);

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setHideListGroup(false);
    };

    const addTools = async () => {
        const selectedToolIds = selectedTools.map(tool => tool.value);
        if (hasMandateUserAdmin && selectedToolIds.includes(FunctionsEnum.MandateAdmin)) {
            setErrors({
                ...errors,
                mandateAdminError: t('errors.mandateAdminConflict'),
            });
            return;
        }

        setLoading(true);
        try {
            const existingUserToolAccessData = new ExistingUserToolAccess({
                legalPersonId: legalPersonId,
                naturalPersonId: selectedPerson.naturalPersonId,
                selectedToolIds: selectedToolIds,
            });
            await postPersonAccessTools(existingUserToolAccessData);
            setIsSuccess(true);
        } catch (error) {
            showToastMessage(t('errors.addTools'));
        } finally {
            setLoading(false);
            setIsConfirmModalVisible(false);
        }
    };

    const handleSubmit = () => {
        const validationErrors = {};

        ValidateRequiredSingleSelect(selectedPerson) || (validationErrors.selectedPerson = t('validation.selectedPersonRequired'));
        ValidateRequiredMultiSelect(selectedTools) || (validationErrors.selectedTools = t('validation.selectedToolsRequired'));

        const selectedToolIds = selectedTools.map(tool => tool.value);
        if (hasMandateUserAdmin && selectedToolIds.includes(FunctionsEnum.MandateAdmin)) {
            validationErrors.mandateAdminError = t('validation.mandateAdminConflict');
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await addTools();
    };

    const handleCancelSubmit = () => setIsConfirmModalVisible(false);

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
        setHideListGroup(true);
        setResults([]);
    };

    const handleItemClick = (result) => {
        if (result) {
            handleAutocompleteSelection(result);
            setResults([]);
        } else {
            setHideListGroup(true);
        }
    };

    const handleMouseDown = (e) => {
        if (listGroupRef.current && listGroupRef.current.contains(e.target)) {
            e.preventDefault();
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (listGroupRef.current && !listGroupRef.current.contains(document.activeElement)) {
                setHideListGroup(true);
            }
        }, 100);
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isConfirmModalVisible ? (
                <Modal show={isConfirmModalVisible} onHide={handleCancelSubmit}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelSubmit}>
                            {t("mandate_application.modal_cancel")}
                        </Button>
                        <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                            {t("confirmation.confirm")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <>
                    {isSuccess ? (
                        <Modal show={isSuccess} onHide={handleCloseSuccess}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <small>{t('popup.successUpdate')}</small>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between">
                                <Button
                                    variant="secondary"
                                    className="btn-eloket"
                                    onClick={handleCloseSuccess}>
                                    {t('mandate_application.modal_success_close')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    ) : (
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('access_tools.modal_title_existing_person')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="formNetworkName">
                                    <Form.Label>
                                        {t('access_tools.modal_input_description')} <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedName}
                                        onChange={handleNameChange}
                                        onBlur={handleBlur}
                                        autoComplete='off'
                                        required
                                    />
                                    {selectedName.trim().length >= 3 && !hideListGroup && (
                                        <ListGroup ref={listGroupRef} onMouseDown={handleMouseDown}>
                                            {loadingAC && (
                                                <ListGroup.Item className="text-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </ListGroup.Item>
                                            )}
                                            {!loadingAC && (
                                                <>
                                                    {results.map((result) => (
                                                        <ListGroup.Item key={result.naturalPersonId} onClick={() => handleItemClick(result)}>
                                                            {result.fullName}{result.city && `, (${result.city})`} #{formatItaaNumber(result.itaaNumber)}
                                                        </ListGroup.Item>
                                                    ))}
                                                </>
                                            )}
                                        </ListGroup>
                                    )}
                                    <br />

                                    <Form.Label>
                                        {t('access_tools.header_tools')} <span style={{ color: 'red' }}>*</span><br />
                                    </Form.Label>
                                    <AccessToolSelect className='accessToolSelect'
                                        selectedTools={selectedTools}
                                        setSelectedTools={setSelectedTools}
                                    />
                                    <br />
                                    <Validation errors={errors} />
                                </Form.Group>
                                <Modal.Footer className="justify-content-between access_tools_footer">
                                    <Button variant="secondary" onClick={handleClose}>
                                        {t('access_tools.modal_cancel')}
                                    </Button>
                                    <Button
                                        className="btn-eloket"
                                        variant="secondary"
                                        onClick={handleSubmit}
                                        disabled={!selectedName.trim()}
                                    >
                                        {t('office_composition.modal_add')}
                                    </Button>
                                </Modal.Footer>
                            </Modal.Body>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default AddExisting;
