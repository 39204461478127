import { useTranslation } from 'react-i18next';
import { formatShortDateAsLocalDate, formatItaaNumber, formatLabelWithColon } from '../../../config/formatUtils';

const NaturalPersonInfoHeader = ({ userDetail }) => {
    const { t } = useTranslation();
    const shortDate = formatShortDateAsLocalDate(userDetail?.verifiedDate);
    return (
        <div className="form-group">
            <div className="form-row row">
                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'><i className="fa fa-user mr-2"></i> {userDetail?.lastName} {userDetail?.firstName}</div>
                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'><i className="fa fa-calendar-check-o mr-2"></i>{formatLabelWithColon(t('data.detailsConfirmed'), shortDate)}</div>
                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'><i className="fa fa-id-card mr-2"></i>{formatLabelWithColon(t('data.itaaNumber'),formatItaaNumber(userDetail?.itaaNumber))}</div>
                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12'><i className="fa fa-envelope mr-2"></i>{userDetail?.email}</div>
            </div>
        </div>
    );
};

export default NaturalPersonInfoHeader;