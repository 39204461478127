import axiosClient from "../../../../../axiosClient";

export const getNetworkDetail = async (legalPersonId) => {
    const response = await axiosClient.get(
        `/api/companies/${legalPersonId}/networks`
    );
    return response.data;
};

export const patchNetworkDetail = async (legalPersonId, detailsToAdd) => {
    const response = await axiosClient.post(
        `/api/companies/${legalPersonId}/networks`,
        detailsToAdd
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};

export const deleteNetworkDetail = async (networkId) => {
    const response = await axiosClient.delete(
        `/api/companies/networks/${networkId}`
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};

export const searchNetworks = async (legalPersonId, search) => {
    const response = await axiosClient.get(
        `/api/companies/${legalPersonId}/networks/search?search=${search}`
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};

export const getOffice = (legalPersonId) => {
    return axiosClient
        .get(`/api/offices/${legalPersonId}/detail`)
        .then((response) => response.data);
};