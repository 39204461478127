import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NaturalPersonInfoHeader from '../../NaturalPersonInfoHeader/NaturalPersonInfoHeader';

const MyDdata = ({ isMobile, userDetail }) => {
    const { t, i18n } = useTranslation();
    const hasNoActiveTracks = !userDetail.officialTypes || userDetail.officialTypes.length === 0;

    const myData = [
        {
            url: '/eloket/data',
            title: t('sidebar.contact'),
            text: t('data.text')
        },
        {
            url: '/eloket/personaldata',
            title: t('sidebar.personalData'),
            text: t('personaldata.personalDataText1')
        },
        ...(hasNoActiveTracks ? [] : [
            {
                url: '/eloket/insurance',
                title: t('sidebar.myInsurance'),
                text: t('insurance.subText')
            }
        ])
    ];

    return (
        <div className="container" id="main">
            <NaturalPersonInfoHeader userDetail={userDetail} />
            {isMobile && (<div className="text-h1">{t('sidebar.profile')}</div>)}
            <div className="text-h2">{t('sidebar.data')} </div>
            <Row className='row-m-1'>
                {myData.map((data, index) => (
                    <Col key={index} md={4}>
                        <Link to={data.url} target={data.newTab ? "_blank" : "_self"}>
                            <Card lang={i18n.language} className="h-100 custom-card">
                                <Card.Body>
                                    <Card.Title>{data.title}</Card.Title>
                                    <Card.Text>{data.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MyDdata;



