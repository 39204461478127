import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { postInfoMessage, getInfoMessages, deleteInfoMessage, patchInfoMessage } from './http/HttpClientHelper';
import { Table, Modal } from 'react-bootstrap';
import { formatDateTimeAsLocalDateTime, formatDateTimeForEditorAsLocalDateTime, formatDateTimeAsUtc } from '../../../config/formatUtils';
import { ValidateRequiredText, ValidateStartEndDateTime } from '../../../config/validationUtils';

const Data = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [infoMessage, setInfoMessage] = useState({
        title_NL: '',
        title_FR: '',
        content_NL: '',
        content_FR: '',
        visibleFrom: '',
        visibleUntil: ''
    });
    const [messages, setMessages] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [showValidationErrorNewMessage, setShowValidationErrorNewMessage] = useState(false);
    const [showValidationErrorEditMessage, setShowValidationErrorEditMessage] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const m = await getInfoMessages();
            setMessages(m);
            setLoading(false);
        })();

        const now = new Date();
        const formattedDateTime = formatDateTimeForEditorAsLocalDateTime(now.toISOString());
        setInfoMessage(prevDetails => ({ ...prevDetails, visibleFrom: formattedDateTime }));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateInfomessage(infoMessage)) {
            setLoading(true);
            infoMessage.visibleFrom = formatDateTimeAsUtc(infoMessage.visibleFrom);
            infoMessage.visibleUntil = formatDateTimeAsUtc(infoMessage.visibleUntil);
            await postInfoMessage(infoMessage);
            setLoading(false);
            window.location.reload();
        }
        else {
            setShowValidationErrorNewMessage(true);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        await deleteInfoMessage(id);
        window.location.reload();
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInfoMessage(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const handleInputChangeModal = (event) => {
        const { name, value } = event.target;

        setEditItem(prevDetails => ({ ...prevDetails, [name]: value }));
    }

    const handleEdit = (item) => {
        item.visibleFrom = formatDateTimeForEditorAsLocalDateTime(item.visibleFrom);
        item.visibleUntil = formatDateTimeForEditorAsLocalDateTime(item.visibleUntil);
        setEditItem(item);
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setEditItem(null);
    };

    const handleSaveChanges = async () => {
        if (validateInfomessage(editItem)) {
            setLoading(true);
            editItem.visibleFrom = formatDateTimeAsUtc(editItem.visibleFrom);
            editItem.visibleUntil = formatDateTimeAsUtc(editItem.visibleUntil);
            await patchInfoMessage(editItem);
            handleCloseEditModal();
            window.location.reload();
        } else {
            setShowValidationErrorEditMessage(true);
        }
    };

    const validateInfomessage = (item) => {
        let countErrors = 0;
        ValidateRequiredText(item.title_NL) || countErrors++;
        ValidateRequiredText(item.title_FR) || countErrors++;
        ValidateRequiredText(item.content_NL) || countErrors++;
        ValidateRequiredText(item.content_FR) || countErrors++;

        ValidateStartEndDateTime(item.visibleFrom, item.visibleUntil) || countErrors++;

        if (countErrors == 0)
            return true;

        return false;
    }

    return (
        <div id="main">
            {loading ? (
                <>
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                </>
            ) : (
                <>
                    <div id="content">
                        <div className="text-h1"> {t('infomessage.create')}</div>
                        <div className="form-group">
                            <form action="#" className="form-overview form-detect-unsaved" id="form" method="post" onSubmit={handleSubmit}>
                                <div className="form-row row">
                                    <div className='form-col col-md-6'>
                                        <div className="controls">
                                            <input onChange={handleInputChange} name="title_NL" className="form-control" type="text" value={infoMessage.title_NL} />
                                            <label className='control-label required'>{t('infomessage.title_nl')}</label>
                                        </div>
                                        <div className="controls">
                                            <textarea onChange={handleInputChange} name="content_NL" className="form-control" value={infoMessage.content_NL}></textarea>
                                            <label className='control-label text-area required'>{t('infomessage.content_nl')}</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-md-6'>
                                        <div className="controls">
                                            <input onChange={handleInputChange} name="title_FR" className="form-control" type="text" value={infoMessage.title_FR} />
                                            <label className='control-label required'>{t('infomessage.title_fr')}</label>
                                        </div>
                                        <div className="controls">
                                            <textarea onChange={handleInputChange} name="content_FR" className="form-control" value={infoMessage.content_FR}></textarea>
                                            <label className='control-label text-area required'>{t('infomessage.content_fr')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className='form-col col-md-3'>
                                        <div className="controls">
                                            <input onChange={handleInputChange} name="visibleFrom" type="datetime-local" value={infoMessage.visibleFrom} />
                                            <label className='control-label required'>{t('infomessage.startdate')}</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-md-3'>
                                        <div className="controls">
                                            <input onChange={handleInputChange} name="visibleUntil" type="datetime-local" value={infoMessage.visibleUntil} />
                                            <label className='control-label'>{t('infomessage.enddate')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-col col-md-12">
                                        <div className="form-button-row">
                                            {showValidationErrorNewMessage && (
                                                <span className="text-danger">
                                                    {t('validation.allRequiredFields')} <br />
                                                    {t('validation.startDateBeforeEndDate')}
                                                </span>
                                            )}
                                            <button className="btn btn-eloket" type="submit">{t('data.save')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {Object.keys(messages.infoMessages).map(type => (
                            <div key={type}>
                                <div className="text-h1"> {t(`infomessage.${type.toLowerCase()}`)}</div>
                                <div className="form-group">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>{t('infomessage.title')}</th>
                                                <th>{t('infomessage.content')}</th>
                                                <th>{t('infomessage.startdate')}</th>
                                                <th>{t('infomessage.enddate')}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {messages.infoMessages[type]?.length > 0 ? (
                                                messages.infoMessages[type].map((infoMessage, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{infoMessage.title_NL}</td>
                                                            <td>{infoMessage.content_NL}</td>
                                                            <td rowSpan={2}><nobr>{formatDateTimeAsLocalDateTime(infoMessage.visibleFrom)}</nobr></td>
                                                            <td rowSpan={2}><nobr>{formatDateTimeAsLocalDateTime(infoMessage.visibleUntil)}</nobr></td>
                                                            <td rowSpan={2} className='lastColumn'>
                                                                <nobr>
                                                                    <button className='btn btn-eloket' onClick={() => handleDelete(infoMessage.id)}>{t('infomessage.delete')}</button>
                                                                    <button className='btn btn-primary' onClick={() => handleEdit(infoMessage)}>{t('infomessage.modify')}</button>
                                                                </nobr>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{infoMessage.title_FR}</td>
                                                            <td>{infoMessage.content_FR}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5}>{t('infomessage.noData')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Modal show={editModalOpen} onHide={handleCloseEditModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('infomessage.editTitle')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='form-group'>
                                <div className="form-row row">
                                    <div className='form-col col-md-12'>
                                        <div className="controls">
                                            <input onChange={handleInputChangeModal} name="title_NL" className="form-control" type="text" value={editItem?.title_NL} />
                                            <label className='control-label required'>{t('infomessage.title_nl')}</label>
                                        </div>
                                        <div className="controls">
                                            <textarea onChange={handleInputChangeModal} name="content_NL" className="form-control" value={editItem?.content_NL}></textarea>
                                            <label className='control-label text-area required'>{t('infomessage.content_nl')}</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-md-12'>
                                        <div className="controls">
                                            <input onChange={handleInputChangeModal} name="title_FR" className="form-control" type="text" value={editItem?.title_FR} />
                                            <label className='control-label required'>{t('infomessage.title_fr')}</label>
                                        </div>
                                        <div className="controls">
                                            <textarea onChange={handleInputChangeModal} name="content_FR" className="form-control" value={editItem?.content_FR}></textarea>
                                            <label className='control-label text-area required'>{t('infomessage.content_fr')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className='form-col col-md-6'>
                                        <div className="controls">
                                            <input onChange={handleInputChangeModal} name="visibleFrom" type="datetime-local" value={editItem?.visibleFrom} />
                                            <label className='control-label required'>{t('infomessage.startdate')}</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-md-6'>
                                        <div className="controls">
                                            <input onChange={handleInputChangeModal} name="visibleUntil" type="datetime-local" value={editItem?.visibleUntil} />
                                            <label className='control-label'>{t('infomessage.enddate')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showValidationErrorEditMessage && (
                                <div className="form-group">
                                    <div className="form-row row">
                                        <div className="form-col col-12">
                                            <span className="text-danger">
                                                {t('validation.allRequiredFields')} <br />
                                                {t('validation.startDateBeforeEndDate')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-primary' onClick={handleCloseEditModal}>{t('infomessage.close')}</button>
                            <button className='btn btn-eloket' onClick={handleSaveChanges}>{t('infomessage.save')}</button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}

        </div>
    );
};

export default Data;

