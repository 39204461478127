import axiosClient from "../../../../../axiosClient";

export const getUserCompany = async (userId, legalPersonId) => {
    const response = await axiosClient.get(`/api/Profiles/${userId}/companies`);
    const filteredData = response.data.filter((company) => company.legalPersonId === legalPersonId);
    return filteredData[0];
};

export const getCompaniesOwnClientele = (companyId) => {
  return axiosClient
    .get(`/api/companies/${companyId}/ownclientele`)
    .then((response) => response.data);
};

export const getCompaniesAssociate = (companyId) => {
  return axiosClient
    .get(`/api/companies/${companyId}/associate`)
    .then((response) => response.data);
};
