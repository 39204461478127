import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { searchNaturalPersons } from '../http/HttpClientHelper';
import { formatItaaNumber } from '../../../../../config/formatUtils';
import { searchQualityResponsibleCandidates } from '../../QualityResponsible/http/HttpClientHelper';
import { AMLFunctionEnum } from '../../../../../Enums/AMLFunctionEnum';
const AutocompleteResults = ({ inputValue, handleSelection, legalPersonId,selectedRole }) => {
    const { t } = useTranslation();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');


    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                
                setLoading(true);
                if (!selectedRole) {
                    setValidationError(t('validation.selectRole'));
                    setResults([]);
                    return;
                }
                setValidationError('');

                if(selectedRole===AMLFunctionEnum.AMLCO){
                    const response = await searchNaturalPersons(inputValue);
                    setResults(response);
                }else{
                    const response= await  searchQualityResponsibleCandidates(inputValue)
                    setResults(response);

                }
                setLoading(false);
            };

            if (inputValue.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => {
            clearTimeout(delaySearch);
        }
    }, [inputValue, legalPersonId,handleSelection]);

    const handleItemClick = (result) => {
        handleSelection(result);
        setResults([]);
    };

    return (
        <div>
            {validationError ? (
                <div className="alert alert-warning" role="alert">
                    {validationError}
                </div>
            ) : (
                <ListGroup>
                    {loading && (
                        <ListGroup.Item className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </ListGroup.Item>
                    )}
                    {!loading && results.map((result) => (
                        <ListGroup.Item key={result.naturalPersonId} onMouseDown={() => handleItemClick(result)}>
                            {result.fullName}{result.city && `, (${result.city})`} #{formatItaaNumber(result.itaaNumber)}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}

        </div>
    );
};

export default AutocompleteResults;