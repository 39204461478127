import axiosClient from "../../../../../axiosClient";

export const getCompanyDetail = async (legalPersonId) => {
    const response = await axiosClient.get(
        `/api/companies/${legalPersonId}/itaadetails`
    );
    return response.data;
};

export const getOffice = (legalPersonId) => {
    return axiosClient
        .get(`/api/offices/${legalPersonId}/detail`)
        .then((response) => response.data);
};