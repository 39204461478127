class BeExcellentRequest {
    constructor({
        legalPersonId,
        naturalPersonId,
        url,
        language,
        termsAndConditionsAccepted,
    }) {
        this.legalPersonId = legalPersonId;
        this.naturalPersonId = naturalPersonId;
        this.url = url;
        this.language = language;
        this.termsAndConditionsAccepted = termsAndConditionsAccepted;
    }
}

export default BeExcellentRequest;