import React from 'react';
import logo from '../assets/itaa-logo.png'
import { useTranslation } from 'react-i18next';

const SimpleSidebar = ({ isSidebarOpen, setSidebarOpen, isMobile }) => {
    const { t, i18n } = useTranslation();
    const userLanguage = i18n.language;

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    }

    const toggleLanguage = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        window.location.reload();
    };

    const toggleRefLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        isSidebarOpen ? (
            <nav id="sidebar" className={isMobile ? "active" : ""}>
                <div style={{ display: "flex" }}>
                    <a href="/">
                        <div className="logo">
                            <img src={logo} alt="ITAA Logo" />
                        </div>
                    </a>
                    <button
                        type="button"
                        id="mobileSidebarCollapse"
                        className="btn btn-eloket mobile-only"
                        onClick={toggleSidebar}
                    >
                        <i className="fa fa-align-left"></i>
                        <span>{t("sidebar.close")}</span>
                    </button>
                </div>

                <div className="footersidenav">
                    <div className="language-button-section btn-group d-flex justify-content-center">
                        <button
                            type="button"
                            className={`language-button ${userLanguage === 'nl' ? 'active' : ''}`}
                            onClick={() => toggleLanguage("nl")}
                        >
                            NL
                        </button>
                        <span id="btn-language-sperator">|</span>
                        <button
                            type="button"
                            className={`language-button ${userLanguage === 'fr' ? 'active' : ''}`}
                            onClick={() => toggleLanguage("fr")}
                        >
                            FR
                        </button>
                    </div>

                    <button className="backhome-btn" onClick={() => toggleRefLink("/")}>
                        <i className="fa fa-home"></i>&nbsp;{t("sidebar.backToHome")}
                    </button>
                    <div className="link-group">
                        <button
                                className="ref-link"
                                onClick={() => toggleRefLink(t('sidebar.urls.home'))}
                            >
                                ITAA website
                            </button>
                            <button
                                className="ref-link"
                                onClick={() =>
                                    toggleRefLink(
                                        t('sidebar.urls.privacy')
                                    )
                                }
                            >
                                Privacy
                            </button>
                            <button
                                className="ref-link "
                                onClick={() =>
                                    toggleRefLink(t('sidebar.urls.cookies'))
                                }
                            >
                                Cookies
                            </button>
                        </div>
                </div>
            </nav>
        ) : (
            <>
                <button type="button" className="btn btn-eloket" onClick={toggleSidebar}>
                    <i className="fa fa-align-left"></i>
                </button>
            </>
        )
    );
};

export default SimpleSidebar;
