import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { searchNetworks } from '../http/HttpClientHelper';


const AutocompleteResults = ({ inputValue, handleSelection, legalPersonId }) => {
    const { t } = useTranslation();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                if (!mounted) {
                    setLoading(true);
                    const response = await searchNetworks(legalPersonId, inputValue);
                    setResults(response);
                }
                setLoading(false);
            };

            if (inputValue.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => {
            clearTimeout(delaySearch);
            setMounted(false);
        }
    }, [inputValue, legalPersonId]);

    const handleItemClick = async (result) => {
        if (result === 'new') {
            const network = {
                networkId: "00000000-0000-0000-0000-000000000000",
                networkName: inputValue
            };
            await handleSelection(network);
        }
        else {
            await handleSelection(result);
        }
        setResults([]);
    };

    return (
        <ListGroup>
            {loading && (
                <ListGroup.Item className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </ListGroup.Item>
            )}
            {!loading && (
                <>
                    <ListGroup.Item key={'00000000-0000-0000-0000-000000000000'} onMouseDown={() => handleItemClick('new')}>
                        {inputValue} ({t('network.new')})
                    </ListGroup.Item>
                    {results.map((result) => (
                        <ListGroup.Item key={result.networkId} onMouseDown={() => handleItemClick(result)}>
                            {result.networkName}
                        </ListGroup.Item>
                    ))}
                </>
            )}
        </ListGroup>
    );
};

export default AutocompleteResults;