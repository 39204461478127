import React from "react";
import { useTranslation } from "react-i18next";

function InsuranceForm({ insurance, handleInputChange, index, disabled }) {
    const { t } = useTranslation();
    return (
        <div key={index} className="form-row row mb-2">
            <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="controls">
                    <input
                        autoComplete="off"
                        className="form-control masked-kbo-input"
                        type="text"
                        name={`insurer`}
                        value={insurance.insurer || ""}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled={disabled}
                    />
                    <label className="control-label required">
                        {t('basicdata.insuranceCompany')}
                    </label>
                </div>
            </div>
            <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="controls">
                    <input
                        autoComplete="off"
                        className="form-control masked-kbo-input"
                        type="text"
                        name={`policeNumber`}
                        value={insurance.policeNumber || ""}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled={disabled}
                    />
                    <label className="control-label required">
                        {t('basicdata.policyNumber')}
                    </label>
                </div>
            </div>
        </div>
    );
}

export default InsuranceForm;