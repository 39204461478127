import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import { useParams } from "react-router-dom";

const NavOfficeDetail = ({userDetail}) => {
  const { t, i18n } = useTranslation();
  const { legalPersonId } = useParams();

  const officesData = [
    {
      url: `/eloket/office/maincontact/${legalPersonId}`,
      title: t("sidebar.headContactOffice"),
      text: t("main_contact.title"),
    },
    {
      url: `/eloket/office/coadministrator/${legalPersonId}`,
      title: t("sidebar.coadministrator"),
      text: t("co_administrator.title"),
    },
    {
      url: `/eloket/office/officecomposition/${legalPersonId}`,
      title: t("sidebar.officeComposition"),
      text: t("office_composition.text"),
    },
    {
      url: `/eloket/office/antimoneylaunderingofficers/${legalPersonId}`,
      title: t("sidebar.antiMoneyLaunderingResponsibles"),
      text: t("aml_officers.text"),
    },
    {
      url: `/eloket/office/qualityresponsible/${legalPersonId}`,
      title: t("sidebar.qualityResponsible"),
      text: t("quality_responsible.text"),
    },
    {
      url: `/eloket/office/accesstools/${legalPersonId}`,
      title: t("sidebar.accessTools"),
      text: t("access_tools.text"),
    },
    {
      url: `/eloket/office/beexcellent/${legalPersonId}`,
      title: t("sidebar.beExcellent"),
      text: t("be_excellent.subText"),
    }
  ];

  return (
    <div className="container" id="main">
      <div>
        <LegalPersonInfoHeader
          legalPersonId={legalPersonId}
          userDetail={userDetail}
          legalPersonType={LegalPersonTypeEnum.Office}
        />
        <Row className="row-m-1">
          {officesData.map((data, idx) => (
            <Col key={idx} md={4}>
              <Link to={data.url}>
                <Card lang={i18n.language} className="h-100 custom-card">
                  <Card.Body>
                    <Card.Title className="card-title">
                      {data.title}
                    </Card.Title>
                    <Card.Text>{data.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default NavOfficeDetail;
