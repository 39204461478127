import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserProvider } from '../../Auth/UserProvider';
import {  useLocation } from "react-router-dom";

const AuthError = () => {
    const { handleLogout } = useUserProvider();
    const { t } = useTranslation();
    const location = useLocation();
    const  errormessage  = location.state.errormessage.message;

    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <div className="mb-4 lead" dangerouslySetInnerHTML={{ __html: t(`errors.${errormessage}`) }}>
                        </div>
                        <button className="btn-error" onClick={handleLogout}>
                            <i className="fa fa-arrow-left"></i>&nbsp;{t("navbar.logout")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthError;
