import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserOffices } from './http/HttpClientHelper';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';
import { formatCompanyName } from '../../../../config/formatUtils';
import { CompanyType } from '../../../../Enums/CompanyType'; // Import the CompanyType enum

const NavOffices = ({ userDetail, isMobile }) => {
    const [userOffices, setUserOffices] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const offices = await getUserOffices(userDetail.id);
            offices?.sort((a, b) => a.companyName.localeCompare(b.companyName));

            // Filter out offices that are ManagementCompany
            const filteredOffices = offices.filter(
                office => office.companyType.id !== CompanyType.ManagementCompany
            );

            setUserOffices(filteredOffices);
            setLoading(false);
        })();
    }, [userDetail]);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const renderOfficeDetails = () => {
        if (isMobile) {
            return (
                <div className="accordion" id="officeAccordion">
                    {userOffices.map((detail, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button
                                    className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                                    type="button"
                                    onClick={() => toggleAccordion(index)}
                                    aria-expanded={activeIndex === index}
                                    aria-controls={`collapse${index}`}
                                >
                                    {formatCompanyName(detail.companyName)}
                                </button>
                            </h2>
                            <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#officeAccordion"
                            >
                                <div className="accordion-body">
                                    <LegalPersonInfoHeader
                                        legalPersonId={detail.legalPersonId}
                                        userDetail={userDetail}
                                        legalPersonType={LegalPersonTypeEnum.Office}
                                        isMobile={isMobile}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <>
                    {userOffices.map((detail, index) => (
                        <div key={index}>
                            <LegalPersonInfoHeader
                                legalPersonId={detail.legalPersonId}
                                userDetail={userDetail}
                                legalPersonType={LegalPersonTypeEnum.Office}
                                isMobile={isMobile}
                            />
                        </div>
                    ))}
                </>
            );
        }
    };

    return (
        <div className="container" id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {renderOfficeDetails()}
            {userOffices.length === 0 && !loading && (
                <div className='info-text'>
                    <span dangerouslySetInnerHTML={{ __html: t('office_composition.noOffice') }}></span>
                </div>
            )}
        </div>
    );
};

export default NavOffices;