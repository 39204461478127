export default {
  nl: {
    title: "Mijn groepen binnen ITAA",
    text: "Indien uw hoofdcontactpersoon u niet kan verder helpen met eventuele wijzigingen, gelieve contact op te nemen met <a href='mailto:publicregister@itaa.be'>publicregister@itaa.be</a>.",
  },
  fr: {
    title: "Mes groupes au sein de l'ITAA",
    text: "Si votre contact principal n'est pas en mesure de vous aider à effectuer des changements, veuillez contacter <a href='mailto:publicregister@itaa.be'>publicregister@itaa.be</a>.",
  },
};
