import axiosClient from "./../../../../axiosClient";

export const getUserVisibilities = async (userId) => {
  const response = await axiosClient.get(`/api/Users/${userId}/visibilities`);
  return response.data;
};

export const getActiveInfoMessages = async () => {
  const response = await axiosClient.get("/api/infomessages/active");
  return response.data;
};

export const getNaturalPersonInformationValidation = async (
  naturalPersonId
) => {
  const response = await axiosClient.get(
    `/api/naturalPersonInformationValidation/${naturalPersonId}`
  );
  return response.data;
};

export const patchNaturalPersonInformationValidation = async (
  naturalPersonId,
  patchObject
) => {
  const response = await axiosClient.post(
    `/api/naturalPersonInformationValidation/${naturalPersonId}`,
    patchObject
  );
  return response.data;
};

export const resetPostponementsNaturalPersonInformationValidation = async (naturalPersonId) => {
  const response = await axiosClient.post(
    `/api/naturalPersonInformationValidation/${naturalPersonId}/postponements/reset`
  );
  return response.data;
}

export const getEstoxLink = (naturalPersonId, isAdmin) => {
  let estoxEnvironmentType = isAdmin ? "EstoxAdmin" : "Estox";

  const url = `/api/Users/${naturalPersonId}/estoxlink`;
  const params = {
    estoxEnvironmentType: estoxEnvironmentType,
  };

  return axiosClient
    .get(url, { params })
    .then((response) => {
      return response.data;
    });
};

export const getEstoxAdminLink = (naturalPersonId) => {
  const url = `/api/Users/${naturalPersonId}/estoxadminlink`;
 
  return axiosClient
    .get(url)
    .then((response) => {
      return response.data.estoxLink;
    });
}

export const getInisLink = (naturalPersonId) => {
  const url = `/api/Users/${naturalPersonId}/inislink`;
  return axiosClient
    .get(url)
    .then((response) => {
      return response.data;
    });
};

export const getCompanyWebLink = (naturalPersonId) => {
    const url = `/api/Users/${naturalPersonId}/companyweblink`;
    return axiosClient
        .get(url)
        .then((response) => {
            return response.data;
        });
};