import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { getUserOffices, getMyDetails, patchMyDetails } from '../http/HttpClientHelper';
import AutocompleteResults from '../../Office/MainContact/AutoComplete/AutoComplete';

const ClientelInfoCircle = ({ legalPersonId, userDetail, handleCloseModal }) => {
    const { t } = useTranslation();

    const [myDetails, setMyDetails] = useState({});
    const [selectedName, setSelectedName] = useState('');
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [selectedNaturalPersonId, setSelectedNaturalPersonId] = useState(null);

    useEffect(() => {
        const fetchMyDetails = async () => {
            setLoading(true);
            const clienteleDetail = await getMyDetails(legalPersonId);
            setMyDetails(clienteleDetail);
            setShowAdditionalFields(clienteleDetail.isManagementCompany === true);
            setLoading(false);
        };
        fetchMyDetails();
    }, [legalPersonId]);

    const handleCheckboxChange = (value) => {
        setMyDetails(prevDetails => ({ ...prevDetails, isManagementCompany: value }));
        setShowAdditionalFields(value === true);
    };

    const patchClientelle = async () => {

        setIsConfirmModalVisible(false);
        setLoading(true);
        await patchMyDetails(legalPersonId, {
            legalPersonId: legalPersonId,
            isManagementCompany: myDetails.isManagementCompany,
            mainContactId: selectedNaturalPersonId
        });
        await getUserOffices(userDetail?.id);
        setLoading(false);
        setIsSuccess(true);
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload()
    };

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setIsClickedResult(false);
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedNaturalPersonId(selectedResult.naturalPersonId);
        setSelectedName(selectedResult.fullName);
        setIsClickedResult(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsClickedResult(true);
        }, 100);
    };
    
    const handleConfirmSubmit = async () => {
        await patchClientelle();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsConfirmModalVisible(true);
    };

    return (
        <div>
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton />
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isSuccess} onHide={handleCloseSuccess}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("mandate_application.modal_success_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small>{t("popup.successUpdate")}</small>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button variant="secondary" className="btn-eloket" onClick={handleCloseSuccess}>
                        {t("mandate_application.modal_success_close")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div id="content">
                <form>
                    <div className="form-group">
                        <p dangerouslySetInnerHTML={{ __html: t('clientele.yesText') }}></p>
                        <p dangerouslySetInnerHTML={{ __html: t('clientele.noText') }}></p>
                        <p id='clientele-nb'>{t('clientele.NB')}</p>
                        <div className="radio">
                            <input
                                id="IsManagementCompanyNo"
                                name="IsManagementCompany"
                                type="radio"
                                value="false"
                                checked={myDetails.isManagementCompany === false}
                                onChange={() => handleCheckboxChange(false)}
                            />
                            <label htmlFor="IsManagementCompanyNo">{t('clientele.yes')}</label>
                        </div>
                        <div className="radio">
                            <input
                                id="IsManagementCompanyYes"
                                name="IsManagementCompany"
                                type="radio"
                                value="true"
                                checked={myDetails.isManagementCompany === true}
                                onChange={() => handleCheckboxChange(true)}
                            />
                            <label htmlFor="IsManagementCompanyYes">{t('clientele.no')}</label>
                        </div>

                        {showAdditionalFields && (
                            <div className="form-row">
                                <div className='form-col col-xs-12'>
                                    <p className="text-description">{t('main_contact.description_select')}</p>
                                    <div className='controls'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={selectedName}
                                            autoComplete='off'
                                            placeholder=""
                                            onChange={handleNameChange}
                                            onBlur={handleBlur}
                                        />
                                        <label className='control-label'>{t('network.search')}</label>
                                    </div>
                                    {!isClickedResult && (
                                        <AutocompleteResults
                                            inputValue={selectedName}
                                            handleSelection={handleAutocompleteSelection}
                                            legalPersonId={legalPersonId}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-button-row">
                        <Button className="btn-eloket" variant="secondary" type="submit" onClick={handleSubmit}>
                            {t('clientele.confirm')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ClientelInfoCircle;
