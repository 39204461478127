export default {
    nl: {
        title: "Toegang tools",
        header_name: "Naam",
        header_qualification: "ITAA-Hoedanigheid",
        header_type: "Officieel Type",
        header_tools: "Tools",
        placeholder_tools: "Maak uw keuze",
        amount: "Aantal perso(o)n(en) met toegang tot de tools: ",
        button_add_existing_person: "+ Toegang verlenen tot tools aan bestaande medewerker",
        button_add_new_person: "+ Toegang verlenen tot tools aan nieuwe medewerker",
        title_new_person: 'Nieuwe medewerker',
        button_delete: "Verwijderen",
        modal_title_new_person: "Toegang verlenen tot tools van uw kantoor aan iemand die niet gekend is bij het ITAA",
        modal_title_existing_person: "Toegang verlenen tot tools van uw kantoor aan iemand die gekend is bij het ITAA",
        modal_input_description: "Naam persoon",
        modal_text:
            "U kan enkel de personen toevoegen die gekend zijn in de databank van het ITAA. Indien de medewerker niet in de lijst staat, kan u een aanvraag doen via deze <a href='your_link_here'>link</a>. Voor uw bijkomende vragen kan u steeds bij de service desk terecht: servicedesk@itaa.be Gelieve na te vragen of deze persoon effectief toegang heeft verkregen tot de Tools.",
        modal_cancel: "Annuleren",
        modal_success_title: "Succes",
        modal_success_explanation:
            "De toegang voor deze persoon werd aan de lijst toegevoegd. Het kan enige tijd duren voordat de wijzigingen zichtbaar zijn op het portaal. Laat uw collega alvast weten dat hij/zij voortaan toegang heeft in naam van het kantoor en tot welke tools van het Instituut, zoals eStox en ITAA-opleidingen.",
        modal_success_close: "Sluiten",
        text: "Geef uw medewerkers toegang tot volgende tool: eStox, ITAA opleidingen en mandaten.",
        select_all: "Alle tools selecteren",
        Portal_MandateUser: "Mandaten",
        Portal_EstoxAdmin: "Estox administrator",
        Portal_MandateAdmin: "Mandaten beheerder",
        Portal_Estox: "Estox",
        Portal_CoAdministrator: "Medebeheerder",
        Portal_BeExcellentAdmin: "BeExcellent administrator",
        Portal_INIS: 'Opleidingen',
        Portal_MandateManager:'Mandaten beheerder'
    },
    fr: {
        title: "Accès aux outils",
        header_name: "Nom",
        header_qualification: "Qualité ITAA",
        header_type: "Type officiel",
        header_tools: "Outils",
        placeholder_tools: "Faites votre choix",
        amount: "Personne(s) ayant accès aux outils : ",
        button_add_existing_person: "+ Accorder l'accès aux outils à un collaborateur existant",
        button_add_new_person: "+ Accorder l'accès aux outils à un nouveau collaborateur",
        title_new_person: 'Nouveau collaborateur',
        button_delete: "Supprimer",
        modal_title_new_person: "Accorder l'accès aux outils de votre cabinet à une personne inconnue à l'ITAA.",
        modal_title_existing_person: "Accorder l'accès aux outils de votre cabinet à une personne connue à l'ITAA",
        modal_input_description: "Nom de la personne",
        modal_text:
            "Vous ne pouvez ajouter que les personnes répertoriées dans la base de données de l'ITAA. Si le collaborateur ne figure pas dans la liste, vous pouvez faire une demande via ce <a href='votre_lien_ici'>lien</a>. Pour toute question supplémentaire, vous pouvez toujours contacter le service desk : servicedesk@itaa.be. Veuillez vérifier si cette personne a effectivement obtenu l'accès aux outils.",
        modal_cancel: "Annuler",
        modal_success_title: "Succès",
        modal_success_explanation:
            "L'accès a été ajouté à la liste pour cette personne. Il peut prendre un certain temps avant que vos modifications soient visibles sur le portail. Informez votre collègue qu'il/elle a désormais accès aux outils de l'institut au nom du cabinet et à quels outils, tels que eStox et les sessions de l'ITAA.",
        modal_success_close: "Fermer",
        text: "Donnez à vos employés accès aux outils suivants : eStox, formations ITAA et mandats.",
        select_all: "Sélectionnez tous les outils",
        Portal_MandateUser: "Mandats",
        Portal_EstoxAdmin: "Estox administrateur",
        Portal_MandateAdmin: "Gestionnaire de mandat",
        Portal_Estox: "Estox",
        Portal_CoAdministrator: "Co-administrateur",
        Portal_BeExcellentAdmin: "BeExcellent administrateur",
        Portal_INIS: 'Formations',
        Portal_MandateManager:'Gestionnaire de mandat'

    },
};