import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { patchNaturalPersonInformationValidation } from '../http/HttpClientHelper';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../axiosClient';

const LandingPageModal = ({ showModal, handleClose, validationDataToModal, naturalPersonId, canIgnore }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [remainingPostponements, setRemainingPostponements] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleIgnore = async () => {
        await patchNaturalPersonInformationValidation(naturalPersonId, {
            hasIgnored: true,
            hasConfirmed: false,
        })
        handleClose();
    };

    const handleNaturalPersonConfirmClick = async () => {
        setIsLoading(true);
        await patchNaturalPersonInformationValidation(naturalPersonId, {
            hasIgnored: false,
            hasConfirmed: true,
        });

        var response = await axiosClient.get(`/api/Profiles/${naturalPersonId}/itaadetails`);
        const userDetails = await response.data;
        localStorage.setItem("currentUser", JSON.stringify(userDetails));

        setIsLoading(false);
        window.location.reload();
    };

    useEffect(() => {
        const remaining = validationDataToModal.maxPostponements - validationDataToModal.currentPostponements;
        setRemainingPostponements(remaining);

        if (remaining === 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [validationDataToModal]);

    useEffect(() => {
        const fetchUserDetail = async () => {
            const response = await axiosClient.get(`/api/Profiles/${naturalPersonId}/itaadetails`);
            const data = await response.data;
            setEmail(data.email); 
        };

        fetchUserDetail(); 
    }, []);

    return (
        <Modal show={showModal}>
            {isLoading && (
                 <div className="loader-overlay">
                 <div className="loader"></div>
             </div>
            )}
            <Modal.Header>
                <Modal.Title>{t('confirmation.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('confirmation.descriptionPersonal')}</p>
                {canIgnore !== false &&
                    <p>{t('confirmation.descriptionPersonalCanIgnore')}</p>
                }

                <p>{t('confirmation.descriptionCategories')}</p>

                {canIgnore !== false &&
                    <p>{t('confirmation.countDown', { remaining: remainingPostponements })}</p>
                }

                <p>
                    {t('confirmation.labelCategories')} <br />
                    <span className='categoryblock'>Mijn profiel</span>
                    <span className='categoryblock'>Ondernemingen</span>
                    <span className='categoryblock'>Kantoren</span>
                </p>

                <p>
                    {t('confirmation.labelEmail')} <br />
                    <span>{email}</span>
                </p>

                <p>{t('confirmation.descriptionEditEmail')}</p>
                <p>{t('confirmation.descriptionEmailInfo')}</p>

                {showAlert && (
                    <div className='alert alert-danger mt-4 mb-0'>
                        <span>{t('confirmation.alertMaxPostponements')}</span>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                    {canIgnore !== false && (
                        <Button variant="primary" onClick={handleIgnore}>
                            {t('confirmation.cancel')}
                        </Button>
                    )}

                    <button className="btn btn-eloket" onClick={handleNaturalPersonConfirmClick}>
                        <i className="fa fa-calendar-check-o mr-2"></i> {t('confirmation.title')}
                    </button>
                </Modal.Footer>
        </Modal>
    );
};

export default LandingPageModal;
