export default {
  nl: {
    confirm: "Bevestigen",
    cancel: "Uitstellen",
    title: "Gegevens bevestigen",
    title_company: "Onderneming(en)",
    title_office: "Kanto(o)r(en)",
    descriptionPersonal:
      "Conform de wetgeving betreffende het openbaar register dient U éénmaal per jaar uw persoonlijke gegevens in uw e-loket te valideren. Welke gegevens? Professioneel adres, professioneel telefoonnummer en e-mailadres publiek register. U kan dit best onmiddellijk doen door uw gegevens nu te controleren, desgevallend aan te passen en daarna te klikken op “Gegevens bevestigen”. ",
    descriptionPersonalCanIgnore: "U kan dit ook nog op een later tijdstip doen. Indien u uw gegevens niet hebt gevalideerd zal de validatie-pop-up opnieuw verschijnen.",
    descriptionCategories: "Onderaan vindt u de rubrieken die u dient te bevestigen. Indien er meerdere rubrieken staan en u klikt op ‘gegevens bevestigen’, worden alle gegevens in alle rubrieken bevestigd.",
    labelCategories: "De te bevestigen rubriek(en):",
    labelEmail: "Uw e-mailadres dat bekend is bij het ITAA is:",
    descriptionEditEmail: "Indien dit niet correct is, dient u dit aan te passen in de rubriek ‘Mijn profiel’ – ‘Mijn contactgegevens’.",
    descriptionEmailInfo: "Let op, uw e-mail wordt gebruikt om aan te melden op het ITAA portaal en om met u te communiceren.",
    alertMaxPostponements: 'Opgelet. Dit is de laatste keer dat u het bevestigen van de gegevens kan uitstellen.',
    countDown: "Je kan nog maximaal {{remaining}} keer uitstellen.",
    resetPostponements: "Reset uitstel",
  },
  fr: {
    confirm: "Confirmer",
    cancel: "Reporter",
    title: "Confirmer les données",
    title_company: "Entreprise(s)",
    title_office: "Cabinet(s)",
    descriptionPersonal:
      "Conformément à la législation concernant le registre public, vous devez valider au moins une fois par an, vos données personnelles dans votre e-guichet. Quelles données? Adresse professionnelle, numéro de téléphone professionnel et adresse e-mail registre public. Il est préférable de le faire immédiatement en vérifiant vos données maintenant, en les modifiant si nécessaire, puis en cliquant sur “Confirmer les données”. Vous pouvez également le faire ultérieurement. Si vous n’avez pas validé vos données, la fenêtre de validation réapparaîtra.",
    descriptionPersonalCanIgnore: "Vous pouvez également le faire plus tard. Si vous n'avez pas validé vos données, la pop-up de validation réapparaîtra.",
    descriptionCategories: "En bas, vous trouverez les rubriques que vous devez confirmer. S’il y a plusieurs rubriques et que vous cliquez sur ‘confirmer les données’, toutes les données de toutes les rubriques seront confirmées.",
    labelCategories: "La ou les rubrique(s) à confirmer :",
    labelEmail: "Votre adresse e-mail connue par l’ITAA est :",
    descriptionEditEmail: "Si cela n’est pas correct, vous devez le modifier dans la rubrique ‘Mon profil’ – ‘Mes coordonnées’.",
    descriptionEmailInfo: "Attention, votre e-mail est utilisé pour vous connecter au portail de l’ITAA et pour communiquer avec vous.",
    alertMaxPostponements: 'Attention. C´est la dernière fois que vous pouvez reporter la confirmation des données.',
    countDown: "Vous pouvez reporter un maximum de {{remaining}} fois.",
    resetPostponements: "Réinitialiser le report",
  },
};
