import axiosClient from "../../../../../../axiosClient.js";

export const getProfileGroups = (natPersonId) => {
    return axiosClient
      .get(`/api/profiles/${natPersonId}/itaagroups`)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
};