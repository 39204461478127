export default {
  nl: {
    startDate:"Inschrijvingsdatum",
    since:"Sinds",
    title: "ITAA-gegevens",
    title_general: "Algemeen",
    salutation: "Aanhef",
    firstName: "Voornaam",
    lastName: "Naam",
    email: "E-mailadres",
    gender: "Geslacht",
    itaaNumber: "ITAA-nummer",
    language: "Taalrol",
    birthPlace: "Geboorteplaats",
    bithDate: "Geboortedatum",
    birthDatePlaceholder: "dd/mm/jjjj",
    nationality: "Nationaliteit",
    type: "Type",
    nationalRegistrationNumber: "Rijksregisternummer",
    taxNumber: "BTW nummer als natuurlijke persoon",
    textMyProfile:
      "Raadpleeg uw:\n-Erkenningsdatum\n-Rijksregisternummer\n-Stagiairs\n-Mandaten",
    textCompany:
      "Raadpleeg uw: Erkenningsdatum, Ondernemingsnummer, Oprichtingsdatum.",
    accreditationDate: "Erkenningsdatum",
    inaugurationDate: "Eedaflegging",
    no_edit_text: 'Onderstaande gegevens kunnen niet online aangepast worden. Neem contact op met het ITAA (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>) voor rechtzettingen.',
    selectTrack: {
      title: 'Selecteer een traject',
      text: 'Selecteer hieronder een traject waarvoor u een certificaat wil genereren',
      cancel: 'Annuleren',
      download: 'Downloaden',
    },
    certificate: {
      title: 'Download of druk uw ITAA-certificaat af',
      download: "Downloaden / Afdrukken"
    },
    salutations: {
      Male: "De heer",
      Female: "Mevrouw",
    },
    genders: {
      Select: "Selecteer een geslacht",
      Male: "Man",
      Female: "Vrouw",
      Unknown: "Onbekend",
      X: "Onbekend",
    },
    languages: {
      Select: "Selecteer een taal",
      Dutch: "Nederlands",
      French: "Frans",
    },
    nationalities: {
      Select: "Selecteer een nationaliteit",
      Uzbek: "Oezbeeks",
      Ukrainian: "Oekraïens",
      Turkish: "Turks",
      Tunisian: "Tunesisch",
      Togolese: "Togolees",
      Tajikistani: "Tadzjieks",
      Syrian: "Syrisch",
      Swiss: "Zwitsers",
      Spanish: "Spaans",
      "South African": "Zuid-Afrikaans",
      Singaporean: "Singaporees",
      Serbian: "Servisch",
      Senegalese: "Senegalees",
      Rwandan: "Rwandees",
      Russian: "Russisch",
      Romanian: "Roemeens",
      Portuguese: "Portugees",
      Polish: "Pools",
      Peruvian: "Peruviaans",
      Pakistani: "Pakistaans",
      "North American": "Noord-Amerikaans",
      Nigerian: "Nigeriaans",
      Moroccan: "Marokkaans",
      Moldovan: "Moldavisch",
      Malian: "Maliaans",
      Malaysian: "Maleisisch",
      Madagascan: "Malagasi",
      Luxembourger: "Luxemburgs",
      Lithuanian: "Litouws",
      Lebanese: "Libanees",
      Latvian: "Lets",
      Kosovan: "Kosovaars",
      Korean: "Koreaans",
      Japanese: "Japans",
      Ivorian: "Ivoriaans",
      Italian: "Italiaans",
      Irish: "Iers",
      Iranian: "Iraans",
      Hungarian: "Hongaars",
      Guinean: "Guinees",
      Greek: "Grieks",
      Ghanaian: "Ghanees",
      German: "Duits",
      French: "Frans",
      Finnish: "Fins",
      English: "Engels",
      Egyptian: "Egyptisch",
      Ecuadorian: "Ecuadoraans",
      Dutch: "Nederlands",
      Djibouti: "Djiboutiaans",
      Congolese: "Congolees",
      Colombian: "Colombiaans",
      Chinese: "Chinees",
      Cameroonian: "Kameroens",
      Burkinabè: "Burkinees",
      Bulgarian: "Bulgaars",
      Brazilian: "Braziliaans",
      Beninese: "Benins",
      Belorussian: "Wit-Russisch",
      Belizean: "Belizaans",
      Belgian: "Belg",
      BelgianAustralian: "Belgisch-Australisch",
      Armenian: "Armeens",
      Argentinian: "Argentijns",
      Angolan: "Angolees",
      Algerian: "Algerijns",
      Albanian: "Albanees",
      Afgan: "Afghaans",
      Afghan: "Afghaans",
      Australian: "Australiër",
      Austrian: "Oostenrijks",
      Bangladeshi: "Bengalees",
      Batswana: "Botswaan",
      Belizian: "Belizaan",
      Bolivian: "Boliviaan",
      British: "Brit",
      Burkinab: "Burkinabees",
      Burundian: "Burundees",
      Cambodian: "Cambodjaan",
      Canadian: "Canadees",
      Chilean: "Chileen",
      CostaRican: "Costa Ricaan",
      Croatian: "Kroaat",
      Cuban: "Cubaan",
      Czech: "Tsjech",
      Danish: "Deen",
      Dominican: "Dominicaan",
      Estonian: "Est",
      Ethiopian: "Ethiopiër",
      Fijian: "Fijiaan",
      Gabonese: "Gabonees",
      Guatemalan: "Guatemalteek",
      Haitian: "Haïtiaan",
      Honduran: "Hondurees",
      Icelandic: "IJslander",
      Indian: "Indiër",
      Indonesian: "Indonesiër",
      Iraqi: "Irakees",
      Israeli: "Israëliër",
      Jamaican: "Jamaicaan",
      Jordanian: "Jordaniër",
      Kenyan: "Keniaan",
      Kuwaiti: "Koeweiti",
      Lao: "Laotiaan",
      Libyan: "Libiër",
      Malagasy: "Malagasiër",
      Maltese: "Maltees",
      Mauritian: "Mauritiaan",
      Mexican: "Mexicaan",
      Mongolian: "Mongoliër",
      Mozambican: "Mozambikaan",
      Namibian: "Namibiër",
      Nepalese: "Nepalees",
      NewZealand: "Nieuw-Zeelander",
      Nicaraguan: "Nicaraguaan",
      NorthAmerican: "Noord-Amerikaan",
      Norwegian: "Noor",
      Panamanian: "Panamees",
      Paraguayan: "Paraguayaan",
      Philippine: "Filipijn",
      Salvadorian: "Salvadoraan",
      Saudi: "Saoediër",
      Scottish: "Schot",
      Slovak: "Slovaak",
      Somali: "Soomaaliër",
      SouthAfrican: "Zuid-Afrikaan",
      SriLankan: "Sri Lankaan",
      Sudanese: "Soedanees",
      Swedish: "Zweeds",
      Taiwanese: "Taiwanees",
      Taijikistani: "Tadzjieks",
      Tanzanian: "Tanzaniaan",
      Thai: "Thai",
      Tongan: "Tongaan",
      Ukranian: "Oekraïener",
      Emiratie: "Emiratiër",
      Uruguayan: "Uruguayaan",
      Venezuelan: "Venezolaan",
      Vietnamese: "Vietnamees",
      Welsh: "Welshman",
      Zambian: "Zambiaan",
      Zimbabwean: "Zimbabwaan",
    }
  },
  fr: {
    startDate:"Date d’inscription",
    since:"depuis",
    title: "Données ITAA",
    title_general: "Géneral",
    salutation: "Salutation",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Adresse e-mail",
    gender: "Sexe",
    itaaNumber: "Numéro ITAA",
    language: "Rôle linguistique",
    birthPlace: "Lieu de naissance",
    bithDate: "Date de naissance",
    birthDatePlaceholder: "jj/mm/aaaa",
    nationality: "Nationalité",
    type: "Type",
    nationalRegistrationNumber: "Registre national",
    taxNumber: "Numéro de TVA comme personne physique",
    textMyProfile:
      "Consulter votre:\n-Date d'admission\n-Registre national\n-Stagiaires\n-Mandats",
    textCompany:
      "Consultez votre : date d'admission, numéro d'entreprise, date de constitution.",
    accreditationDate: "Date d'admission",
    inaugurationDate: "Prestation de serment",
    no_edit_text: 'Ces informations ne peuvent pas être modifiées en ligne. Contactez l\'ITAA (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>) pour y apporter des modifications..',
    selectTrack: {
      title: 'Sélectionnez un traject',
      text: 'Sélectionnez ci-dessous un traject pour lequel vous souhaitez générer un certificat',
      cancel: 'Annuler',
      download: 'Download',
    },
    certificate: {
      title: 'Téléchargez ou imprimez votre certificat ITAA',
      download: "Télécharger / Imprimer"
    },
    salutations: {
      Male: "Monsieur",
      Female: "Madame",
    },
    genders: {
      Select: "Sélectionnez un sexe",
      Male: "Homme",
      Female: "Femme",
      Unknown: "Inconnu",
      X: "Inconnu",
    },
    languages: {
      Select: "Sélectionnez une langue",
      Dutch: "Néerlandais",
      French: "Français",
    },
    nationalities: {
      Select: "Sélectionnez une nationalité",
      Uzbek: "Ouzbek",
      Ukrainian: "Ukrainien",
      Turkish: "Turc",
      Tunisian: "Tunisien",
      Togolese: "Togolais",
      Tajikistani: "Tadjik",
      Syrian: "Syrien",
      Swiss: "Suisse",
      Spanish: "Espagnol",
      "South African": "Sud-Africain",
      Singaporean: "Singapourien",
      Serbian: "Serbe",
      Senegalese: "Sénégalais",
      Rwandan: "Rwandais",
      Russian: "Russe",
      Romanian: "Roumain",
      Portuguese: "Portugais",
      Polish: "Polonais",
      Peruvian: "Péruvien",
      Pakistani: "Pakistanais",
      "North American": "Américain du Nord",
      Nigerian: "Nigérian",
      Moroccan: "Marocain",
      Moldovan: "Moldave",
      Malian: "Malien",
      Malaysian: "Malaisien",
      Madagascan: "Malgache",
      Luxembourger: "Luxembourgeois",
      Lithuanian: "Lituanien",
      Lebanese: "Libanais",
      Latvian: "Letton",
      Kosovan: "Kosovar",
      Korean: "Coréen",
      Japanese: "Japonais",
      Ivorian: "Ivoirien",
      Italian: "Italien",
      Irish: "Irlandais",
      Iranian: "Iranien",
      Hungarian: "Hongrois",
      Guinean: "Guinéen",
      Greek: "Grec",
      Ghanaian: "Ghanéen",
      German: "Allemand",
      French: "Français",
      Finnish: "Finlandais",
      English: "Anglais",
      Egyptian: "Égyptien",
      Ecuadorian: "Équatorien",
      Dutch: "Néerlandais",
      Djibouti: "Djiboutien",
      Congolese: "Congolais",
      Colombian: "Colombien",
      Chinese: "Chinois",
      Cameroonian: "Camerounais",
      Burkinabè: "Burkinabè",
      Bulgarian: "Bulgare",
      Brazilian: "Brésilien",
      Beninese: "Béninois",
      Belorussian: "Biélorusse",
      Belizean: "Bélizien",
      Belgian: "Belge",
      BelgianAustralian: "Belgo-Australien",
      Armenian: "Arménien",
      Argentinian: "Argentin",
      Angolan: "Angolais",
      Algerian: "Algérien",
      Albanian: "Albanais",
      Afgan: "Afghan",
      Afghan: "Afghan",
      Austrian: "Austrian",
      Australian: "Australien",
      Bangladeshi: "Bangladais",
      Batswana: "Botswanais",
      Belizian: "Bélizien",
      Bolivian: "Bolivien",
      British: "Britannique",
      Burkinab: "Burkinabé",
      Burundian: "Burundais",
      Cambodian: "Cambodgien",
      Canadian: "Canadien",
      Chilean: "Chilien",
      CostaRican: "Costaricain",
      Croatian: "Croate",
      Cuban: "Cubain",
      Czech: "Tchèque",
      Danish: "Danois",
      Dominican: "Dominicain",
      Estonian: "Estonien",
      Ethiopian: "Éthiopien",
      Fijian: "Fidjien",
      Gabonese: "Gabonais",
      Guatemalan: "Guatémaltèque",
      Haitian: "Haïtien",
      Honduran: "Hondurien",
      Icelandic: "Islandais",
      Indian: "Indien",
      Indonesian: "Indonésien",
      Iraqi: "Irakien",
      Israeli: "Israélien",
      Jamaican: "Jamaïcain",
      Jordanian: "Jordanien",
      Kenyan: "Kényan",
      Kuwaiti: "Koweïtien",
      Lao: "Laotien",
      Libyan: "Libyen",
      Malagasy: "Malgache",
      Maltese: "Maltais",
      Mauritian: "Mauricien",
      Mexican: "Mexicain",
      Mongolian: "Mongol",
      Mozambican: "Mozambicain",
      Namibian: "Namibien",
      Nepalese: "Népalais",
      NewZealand: "Néo-Zélandais",
      Nicaraguan: "Nicaraguayen",
      NorthAmerican: "Américain du Nord",
      Norwegian: "Norvégien",
      Panamanian: "Panaméen",
      Paraguayan: "Paraguayen",
      Philippine: "Philippin",
      Salvadorian: "Salvadorien",
      Saudi: "Saoudien",
      Scottish: "Écossais",
      Slovak: "Slovaque",
      Somali: "Somalien",
      SouthAfrican: "Sud-Africain",
      SriLankan: "Sri-Lankais",
      Sudanese: "Soudanais",
      Swedish: "Suédois",
      Taiwanese: "Taïwanais",
      Taijikistani: "Tadjik",
      Tanzanian: "Tanzanien",
      Thai: "Thaïlandais",
      Tongan: "Tongan",
      Ukranian: "Ukrainien",
      Emiratie: "Émirati",
      Uruguayan: "Uruguayen",
      Venezuelan: "Vénézuélien",
      Vietnamese: "Vietnamien",
      Welsh: "Gallois",
      Zambian: "Zambien",
      Zimbabwean: "Zimbabwéen",
    },
  },
};
